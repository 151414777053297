import React, {FC} from 'react';
import ViewLoanDetailsTop, {DetailsProps} from "./ViewLoanDetailsTop";
import utils from "../../../../utils/utils";

type DisplayDetailsProps = {
    details: DetailsProps['details'];
    detailsKeys: string[];
    detailsValues: string[];
    id: string;
    topIsNotRequired?: boolean;
    lineWidth?:string;
};


const DisplayDetails : FC<DisplayDetailsProps> = (
    { id, details, detailsKeys, detailsValues, topIsNotRequired, lineWidth }
)=> {
    const HorizontalRule: React.FC<{ count: number; keyValue: string }> =({count, keyValue})=> {
        const isLast = detailsKeys.length - 1 === count
        return<>
            {isLast ? <div id={`${keyValue}${id}wbfure`} className={`mb-3`}></div> :
                <div id={`${keyValue}bceoad`} className={`h-[1.7px] ${lineWidth? lineWidth :'w-[43rem]'} mt-5 mb-8 mr-7 bg-[#eaedf0]`}></div>}
        </>
    }
    return <div id={`${id}apwibue`} className={'bg-[#FFFFFF] border border-none rounded-2xl mb-1 pb-2'}>
        {topIsNotRequired?<div className={'pt-4'}></div>:<ViewLoanDetailsTop id={'aovnidhbf'} details={details}/>}
        <div id={`${id}wqidb`} className={'mx-5 mt-5 pl-4'}>
            {detailsKeys.map((keyValue, index) => (
                <div  id={`cnbaid${keyValue}${id}`} key={index}>
                    <div id={`ajjo${keyValue}${id}`} key={index} className={'flex justify-between'}>
                        <p data-testid={`${keyValue}`} id={`idjn${keyValue}${id}`} className={'text-[#72757A]'}>{keyValue}</p>
                        <p id={`${keyValue}sfhuhfdkj${id}`} className={'col-end text-[#101828] text-right font-[600] pr-7'}>{detailsValues[index]}</p>
                    </div>
                    <HorizontalRule keyValue={keyValue} count={index}/>
                </div>
            ))}
        </div>
    </div>;
}
export default DisplayDetails;