import  React from 'react';
import traineeImage from "./Ellipse 262.png";

import image from "../../assets/images/loan_product_icon.svg";


export const dummyTraineeTopDetail ={
    traineeImage: traineeImage,
    traineeName: 'Vanessa Chiamaka Oluchukwu',
    schoolName: 'Learn High International',
    schoolImage: image,
}