
import React, {useEffect, useMemo, useState} from "react"
import styles from "./viewLoanReequestDetails.module.css";
import {GrClose} from "react-icons/gr";
import {getInstituteId, getLoanRequestId, instituteId} from "../../../../utils";
import loanService from "../../../../services/loan.service";
import utils from "../../../../utils/utils";
import CreateLoanOfferModal from "../loanOffer/allLoanOffer/CreateLoanOfferModal";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";
import Layout from "../../../../components/reusables/layout/Layout";
import {store} from "../../../../redux/store";
import {loanProductActions} from "../../../../redux/slices/loanProductSlice";
import {loanActions} from "../../../../redux/slices/loanSlice";
import TopBar from "../../../../components/reusables/layout/TopBar";
import {useAppSelector} from "../../../../redux/hooks/hooks";


interface ILoanRequestProps {

}

function ViewLoanRequestIndex() {
    const [openModal, setOpenModal] = useState(false);
    const [loanRequestData, setLoanRequestData] = useState<any>({});
    const storedLoanRequestId = useAppSelector((state) => state.loan.loanRequestId);
    // const trainee = loanRequestData?.loanRequest?.trainee;
    // const user = loanRequestData?.loanRequest?.trainee?.user;
    // const cohort = loanRequestData?.loanRequest?.trainee?.cohort;
    // const program = loanRequestData?.loanRequest?.trainee?.cohort?.program;
    // const institute = loanRequestData?.loanRequest?.trainee?.cohort?.program.institute;

    const closeModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const data = {loanRequestId: storedLoanRequestId};


    const fetchLoanRequestDetails = async () => {
        try {
            const loanRequest = await loanService.viewLoanRequest(data);
            setLoanRequestData(loanRequest);
            store.dispatch(loanActions.setTraineeLoanRequest(loanRequest));
        } catch (error) {
            utils.handleRequestError(error)
        }
    };

    useEffect(() => {
        fetchLoanRequestDetails().then();
    }, []);

    const nextOfKinName = (`${loanRequestData?.trainee?.nextOfKinFirstName} ${loanRequestData?.trainee?.nextOfKinLastName}`);

    return (

        <>
            <TopBar/>
            <div id={'ofpejip'} className={"ml-48 my-4"}>
                <BreadCrumbs/>
            </div>

            <div id={'aheiue'} className={`${styles.wrapper} mx-auto`}>
                <div id={'aihejd'} className={styles.top}>
                    <h1 id={`aklflk`} className={styles.label2}>TRAINEE DETAILS</h1>
                </div>
                <div id={'aeuihdj'} className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div id={'iweuhsk'} className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div id={`hjslhkjdf`}>
                            <h2 id={`jalfj`} className="text-base font-normal text-[#72757A]">
                                Trainee Name
                            </h2>
                            <p id={`ajkkfah`}
                               className="font-bold text-md">{`${loanRequestData?.firstName}  ${loanRequestData?.lastName}`}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`jhskhkjfd`} className="text-base font-normal text-[#72757A]">
                                Email
                            </h2>
                            <p id={`sknvskj`} className="font-bold text-md">{loanRequestData?.email}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`kjsdhslhdkjk`} className="text-base font-normal text-[#72757A]">
                                Phone Number
                            </h2>
                            <p id={`cnjnkajkjnknjanckjs`}
                               className="font-bold text-md">{loanRequestData?.trainee?.phoneNumber}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`jdfjkshksj`} className="text-base font-normal text-[#72757A]">
                                Gender
                            </h2>
                            <p id={`skkdjljlk`} className="font-bold text-md">{loanRequestData?.trainee?.gender}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`jhskjslkjl`} className="text-base font-normal text-[#72757A]">
                                Date of Birth
                            </h2>
                            <p id={`auihciduhs`}
                               className="font-bold text-md">{utils.formatDateFromArray(loanRequestData?.trainee?.dateOfBirth)}</p>
                        </div>
                        <div id={`hjslhkjdf`}>
                            <h2 id={`khfahijsknikwe`} className="text-base font-normal text-[#72757A]">Martial
                                Status</h2>
                            <p id={`jaojaconenj`} className="font-bold text-md">{loanRequestData?.trainee?.maritalStatus}</p>
                        </div>
                        <div id={`hjslhkjdf`}>
                            <h2 id={`sjkjhksahfkhkajlhksa`} className="text-base font-normal text-[#72757A]">
                                Nationality
                            </h2>
                            <p id={`hkjasdjkhskdll`} className="font-bold text-md">{loanRequestData?.trainee?.nationality} </p>
                        </div>
                        <div id={`hjslhkjdf`}>
                            <h2 id={`jkdjhvksj`} className="text-base font-normal text-[#72757A]">
                                State of Origin
                            </h2>
                            <p id={`jsjjojoijsoj`} className="font-bold text-md">{loanRequestData?.trainee?.stateOfOrigin} </p>
                        </div>
                        <div id={`kjskdkljfawes`}>
                            <h2 id={`akjkjlsjlsd`} className="text-base font-normal text-[#72757A]">
                                State of Residence
                            </h2>
                            <p id={`alkjjdihifheuijak`}
                               className="font-bold text-md">{loanRequestData?.trainee?.stateOfResidence}</p>
                        </div>
                        <div id={`klajanaucndjkn`}>
                            <h2 id={`ajfjanidfjnjd`} className="text-base font-normal text-[#72757A]">Residential
                                Address</h2>
                            <p id={`kansjiefjnaidja`} className="font-bold text-md">{loanRequestData?.trainee?.address}</p>
                        </div>

                    </div>
                </div>

                <div id={`afyfhbfkfujk`} className={styles.top}>
                    <h1 id={`bbdsiierujf`} className={styles.label2}>TRAINEE ADDITIONAL DETAILS</h1>
                </div>
                <div className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div>
                            <h2 className="text-base font-normal text-[#72757A]">
                                Alternate Phone Number
                            </h2>
                            <p id={`sakjjskd`} className="font-bold text-md">{loanRequestData?.trainee?.alternatePhoneNumber}</p>
                        </div>
                        <div id={`lknajnijn`}>
                            <h2 id={`ncajsklad`} className="text-base font-normal text-[#72757A]">
                                Alternate Email Address
                            </h2>
                            <p id={`cjabcahbuhuce`}
                               className="font-bold text-md">{loanRequestData?.trainee?.alternateEmailAddress}</p>
                        </div>

                        {/*<div id={`cajniwje`}>*/}
                        {/*    <h2 id={`cyasuabsuyuehd`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Alternate Contact*/}
                        {/*    </h2>*/}
                        {/*    <p id={`caucsbubdjshbchuhebuhd`}*/}
                        {/*       className="font-bold text-md">{loanRequestData?.trainee?.alternateContactAddress}</p>*/}
                        {/*</div>*/}

                        {/*<div id={`jjcajnnicsjkk`}>*/}
                        {/*    <h2 id={`najcbhbcujsbahds`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next Of Kin Name*/}
                        {/*    </h2>*/}
                        {/*    <p id={`canciaonioajfnw9e`}*/}
                        {/*       className="font-bold text-md">{ nextOfKinName ?? "N/A"}</p>*/}
                        {/*</div>*/}

                        {/*<div id={`uiwaejno93fiwjen93w`}>*/}
                        {/*    <h2 id={`djiahijaikanek4e`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next of Kin Phone Number*/}
                        {/*    </h2>*/}
                        {/*    <p id={`cuabdc8euiwwo8ei`}*/}
                        {/*       className="font-bold text-md">{loanRequestData?.trainee?.nextOfKinPhoneNumber}</p>*/}
                        {/*</div>*/}
                        {/*<div id={`caniucinjekm`}>*/}
                        {/*    <h2 id={`kacnewuouanniockew`} className="text-base font-normal text-[#72757A]">Next of Kin*/}
                        {/*        Address</h2>*/}
                        {/*    <p id={`faujfnojeiwknfifwek`}*/}
                        {/*       className="font-bold text-md">{loanRequestData?.trainee?.nextOfKinAddress}</p>*/}
                        {/*</div>*/}
                        {/*<div id={`uacjnciunjdncaiek`}>*/}
                        {/*    <h2 id={`candsijkcajiksdk`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next of Kin Email Address*/}
                        {/*    </h2>*/}
                        {/*    <p id={`cjbaiuhjdvbhduibhjhjks`}*/}
                        {/*       className="font-bold text-md">{loanRequestData?.trainee?.nextOfKinEmailAddress} </p>*/}
                        {/*</div>*/}
                        {/*<div id={`ahbjcheawusuhjdasd`}>*/}
                        {/*    <h2 id={`cakjdncuwebcebjhbfhaj`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next of Kin Relationship*/}
                        {/*    </h2>*/}
                        {/*    <p id={`aufuybahbuhfbuhej`}*/}
                        {/*       className="font-bold text-md">{loanRequestData?.trainee?.nextOfKinRelationship} </p>*/}
                        {/*</div>*/}

                    </div>
                </div>
                <div id={`cabuyuebhjdja`} className={styles.top}>
                    <h1 id={`abyebcweuhjds`} className={styles.label2}>TRAINING DETAILS</h1>
                </div>
                <div className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div>
                            <h2 className="text-base font-normal text-[#72757A]">
                                Institute Name
                            </h2>
                            <p id={`cabhbjhsdabkjabs`} className="font-bold text-md">{loanRequestData?.trainee?.cohort?.program?.institute?.instituteName}</p>
                        </div>
                        <div id={`acybcuybhbjsbhj`}>
                            <h2 id={`ajniucancjiuiewnjk`} className="text-base font-normal text-[#72757A]">
                                Program Name
                            </h2>
                            <p id={`jcadsncijniajsinckds`}
                               className="font-bold text-md">{loanRequestData?.trainee?.cohort?.program?.name}</p>
                        </div>

                        <div id={`uaudsbhbuyhjd`}>
                            <h2 id={`jajscini`} className="text-base font-normal text-[#72757A]">
                                Cohort Name
                            </h2>
                            <p id={`ishdviuishd`} className="font-bold text-md">{loanRequestData?.trainee?.cohort?.name}</p>
                        </div>
                    </div>
                </div>
                <div id={`nfjdsinids`} className={styles.top}>
                    <h1 id={`sivhiisho`} className={styles.label2}>LOAN DETAILS</h1>
                </div>

                <div id={'ewhijkds'} className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div id={'oeuihjs'} className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div id={'piquek'}>
                            <h2 id={'pwjefdj'} className="text-base font-normal text-[#72757A]">
                                Loan Amount Requested
                            </h2>
                            <p id={`caniidsjk`}
                               className="font-bold text-md">{utils.formatAmount(loanRequestData?.trainee?.cohort?.totalAmountRequested)}</p>
                        </div>
                        <div id={`cundsincidjdc`}>
                            <h2 id={`cidusduhdkl`} className="text-base font-normal text-[#72757A]">
                                Tuition Amount
                            </h2>
                            <p id={`cninaonos`}
                               className="font-bold text-md">{utils.formatAmount(loanRequestData?.trainee?.cohort?.tuition)}</p>
                        </div>

                        <div id={`cnanjnioa`}>
                            <h2 id={'piwehfj'} className="text-base font-normal text-[#72757A]">
                                Initial Deposit
                            </h2>
                            <p id={`sfsjla`}
                               className="font-bold text-md">{utils.formatAmount(loanRequestData?.trainee?.initialDeposit)}</p>
                        </div>
                    </div>
                </div>

                <div id={"rightGridContainer"} className={` " w-full flex justify-end  py-4 pr-[28px] "`}>
                    <button
                        id={`titleIsButtonNeededButton`}
                        className={`text-white text bg-[#0D9B48] w-[220px] h-[30px] rounded-[4px] cursor-pointer border-0 px-10  pb-10`}
                        onClick={handleOpenModal} // Call buttonClick directly here
                        type={`button`}
                    >
                        <p id={`titlePtag`} className='w-[150px] h-[20px] mt-2'>Create Loan Offer</p>
                    </button>
                </div>
            </div>

            <CreateLoanOfferModal
                openModal={openModal}
                onClose={closeModal}
                buttonText={"Create"}
            />
        </>


    )

}

export default ViewLoanRequestIndex;
