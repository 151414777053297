import React from 'react';
import {Outlet} from 'react-router-dom';
import NavigationSidebar from './NavigationSidebar';
import DashboardHeader from './DashboardHeader';

const AcceptedTraineeDashboard = () => {
    return (
        <main data-testid="AcceptedTraineeDashboardMain" id="AccepTrMain" className="flex h-screen bg-LearnSpaceAthensGray">
            <NavigationSidebar/>
            <div data-testid={"AcceptedTraineeDashboardContainer"} id={"AcceptTrContainer"} className="flex flex-col flex-grow">
                <DashboardHeader/>
                <div data-testid="AcceptedTraineeDashboardContent" id="AcceptTrContent" className="px-5 py-8 h-full">
                    <Outlet/>
                </div>
            </div>
        </main>
    );
}

export default AcceptedTraineeDashboard;