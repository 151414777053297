import React from 'react';
import Select, { StylesConfig, GroupBase, components  } from 'react-select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; 



interface OptionType {
  value: string ;
  label: string;
}

interface CustomDropdownProps {
  options: OptionType[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  customStyle?: string;
  name?:string
}

const customStyles: StylesConfig<OptionType, false, GroupBase<OptionType>> = {

option: (provided, state) => ({
  ...provided,
  backgroundColor: '#fff',
  color: '#000',
  //     backgroundColor: state.isSelected ? '#fff' : state.isFocused ? '#e8f5e9' : '#fff',
  '&:hover': {
    backgroundColor: '#E7F7ED',
    color: '#0EAA4F',
  },
}),
control: (provided) => ({
  ...provided,
  border: '1px solid #D0D5DD',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  height: '2.5rem', 
  '&:hover': {
    borderColor: '#4CAF50',
  },
  width: 'auto', 
}),
valueContainer: (provided) => ({
  ...provided,
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center', 
  height: '100%',
   overflow: 'hidden',
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
  // zIndex: 100
}),
placeholder: (provided,state) => ({
  ...provided,
  display: 'flex',
  alignItems: 'center', 
  height: '100%',
  color: '#72757A',
  marginBottom: state.isFocused ? '0' : '8px',
  // height: '2.5rem', 
  
}),
indicatorSeparator: () => ({
  display: 'none',
}),
singleValue: (provided) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
  }),
indicatorsContainer: (provided) => ({
  ...provided,
  height: '100%',
}),
};

const DropdownIndicator = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? (
        <ExpandLessIcon /> 
      ) : (
        <ExpandMoreIcon /> 
      )}
    </components.DropdownIndicator>
  );
};

const CustomSelectedInput: React.FC<CustomDropdownProps> = ({ options, value, onChange, placeholder,customStyle,name }) => {
  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  return (
    <Select
      options={ options}
      value={options.find(option => option.value === value) || null}
      onChange={handleChange}
      styles={customStyles}
      placeholder={placeholder}
      className={`focus:outline-none ${customStyle}`}
      classNamePrefix="custom-select"
      components={{ DropdownIndicator }}
      name={name}
    />
  );
};

export default CustomSelectedInput;

