import React from 'react';

interface MenuIconProps {
    className?: string
    onClick?: () => void
}

const MenuIcon = ({className, onClick}: MenuIconProps) => {
    return (<svg onClick={onClick} className={`${className}`} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 14H17.5" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 9.5H17.5" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 5H17.5" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default MenuIcon;