import {twMerge} from "tailwind-merge";
import {ClassValue, clsx} from "clsx";
import {store} from "../redux/store";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleTitle = () => {
  // @ts-ignore
  const programName = store.getState().program.programName;
  // @ts-ignore
  const instituteName = store.getState().institute.instituteName;

  const pathToTitleMap: Record<string, string> = {
    '/loan': 'Loan',
    '/institute': 'Institutes',
    '/trainees': 'Trainees',
    '/fund': 'Fund',
    '/investor': 'Investor',
    '/view_loan_product_detail': 'Loan Product',
    '/investor/biodata': 'Bio Data',
    '/cohort': 'Incoming Cohorts',
    '/program/view': programName,
    '/institute/view': instituteName,
    '/cohort/cohort-details': 'Cohort Summary',
    // '/institute/view': 'Institute name',
  };

  const pathName = window.location.pathname;
  return pathToTitleMap[pathName];
};


export const assignPathName = (paths: string[]) =>  {
    const pathName = window.location.pathname ;
    if(paths.includes(pathName)) {
        return pathName ;
    } else return "" ;
}


export const assignPathNameForCohort = () => {
  const cohortPaths = ["/cohort/cohort-details", "/cohort/cohort-trainees"];
  return assignPathName(cohortPaths);
}

export const assignPathNameForPrograms = () => {
  const programPaths = ["/program/view", "/program/view/cohorts"];
  return assignPathName(programPaths);
}

export const assignPathNameForInvestor = () => {
  const investorPaths = ["/investor/biodata", "/investor/investments", "/investor/bank-details"]
  return assignPathName(investorPaths);
}

// @ts-ignore
export const instituteId = store.getState().auth.associatedData?.institute?.instituteId;
// @ts-ignore
export const loanRequestId = store.getState().auth.associatedData?.loan?.loanRequestId;
// @ts-ignore
export const loanOfferId = store.getState().auth.associatedData?.loan?.loanOfferId;
export const getInstituteId =() =>{
  return getUserAssociatedData()?.institute?.instituteId;
}
export const getLoanRequestId =() =>{
  return getUserAssociatedData()?.loan?.loanRequestId;
}

export const getLoanOfferId =() =>{
  return getUserAssociatedData()?.loan?.loanOfferId;
}

export const getSelectedCohort = () => {
  // @ts-ignore
  const {cohortId, programId, cohortName, programName, ...others} = store.getState().cohort.cohortDetails;
  return [cohortId, programId, cohortName, programName]
}

export const getProgramId = () => {
  const [_, programId] = getSelectedCohort();
  return programId;
}
export const getUserToken = () =>{
  return sessionStorage.getItem("token");
}

export const getUserAssociatedData = ()=> {
  return JSON.parse(sessionStorage.getItem("userAssociatedData")!);
}

export const getUserRole = ()=> {
  // @ts-ignore
  const user = store.getState().auth.user
  const patten = /^[A-Z_]+$/
  //@ts-ignore
  const filteredRoles = user?.realm_access?.roles?.filter(v => patten.test(v))
  return filteredRoles?.length ? filteredRoles[0] : undefined;
}
export const phoneRegExp = /^(0[789][01]\d{8}|[789][01]\d{8})$/;
export const nameRegExp = /^[a-zA-Z' -]+([\-][a-zA-Z' -]+)?(\s*[a-zA-Z' -]+)?$/

export const nameValidationMessage = "Special characters except hyphen and inverted comma are not accepted"






