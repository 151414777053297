import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";


interface SnackBar {

    message: string,
    success: boolean,
    display: boolean,
    type: string

}

const initialState : SnackBar ={

    message: '',
    success: true,
    display: false,
    type: ''

}


const snackBarSlice = createSlice({
    name: 'snackBar',
    initialState,
    reducers: {

        updateSnackBar : (state, action: PayloadAction<any>) => {
            switch(action.payload.type){
                case "OPEN" :{
                    return {
                        ...state,
                        display: true,
                        success: action.payload.success,
                        message: action.payload.message,
                    }
                }
                case "CLOSE": {
                    return {
                        ...state,
                        display: false,
                    }
                }
                default: {
                    return state;
                }

            }

        }

    },

    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            // state.me
        })
    }
})


export const snackBarActions = snackBarSlice.actions;
export default snackBarSlice.reducer;