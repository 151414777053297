import React, {lazy, Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import CohortsInProgram from "../features/dashboard/cohort/cohortsInProgram";
import InstituteSideBar from "../features/dashboard/institute/instituteSideBar/index";
import ViewProgram from "../features/dashboard/programs/ViewProgram";
import LoanRequest from "../features/dashboard/trainee/loanRequest";

const SelectedCohort = lazy(() => import("../features/dashboard/cohort/selectCohort"));
const CohortTrainees = lazy(() => import("../features/dashboard/trainee/cohortTrainees"));
const ProgramDashboard = lazy(() => import("../features/dashboard/programs/programDashboard"));
const TraineeDetails = lazy(() => import("../features/dashboard/trainee/traineeDetails/TraineeDetails"));
const ChangePassword = lazy(() => import("../features/userManagement/auth/changePassword"));
const ViewTrainingInstitute = lazy(() => import("../features/dashboard/institute/ViewTrainingInstitute"));
const CohortDetail = lazy(() => import("../features/dashboard/cohort/cohort/CohortDetail"));
const MidSection = lazy(() => import("../features/dashboard/institute/ViewAllTrainingInstitute/midSection"))
const InviteColleague = lazy(() => import("../features/dashboard/portfolioManager/inviteColleague/index"));
const SelectedLoan = lazy(() => import("../features/dashboard/loan/selectLoan/index"));

function AdminRoutes() {


    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route index element={<Navigate to="/program" replace/>}/>
                    <Route path="/program" element={<ProgramDashboard/>}/>
                    <Route path="/cohort/cohort-trainees/trainee-details" element={<TraineeDetails/>}/>
                    <Route path="/change-password" element={<ChangePassword/>}/>
                    <Route path="/view" element={<ViewTrainingInstitute/>}/>
                    <Route path="/cohort/cohort-details" element={<CohortDetail/>}/>
                    <Route path="/institute" element={<MidSection/>}/>
                    <Route path="/invite-colleague" element={<InviteColleague/>}/>
                    <Route path="institute/view" element={<InstituteSideBar/>}/>
                    <Route path="/program/view" element={<ViewProgram/>}/>
                    <Route path="/cohort" element={<SelectedCohort/>}/>
                    <Route path="/loan" element={<SelectedLoan/>}/>
                    <Route path={`/cohort/cohort-trainees`} element={<CohortTrainees/>}/>
                    <Route path={`/program/view/cohorts`} element={<CohortsInProgram/>}/>
                    <Route path="/trainees" element={<LoanRequest/>}/>
                </Routes>
            </Suspense>
        </>);

        

}

export default AdminRoutes;