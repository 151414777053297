
export enum names {
    FUNDS = "Funds",
    INVESTORS = "Investors",
    LOANS = "Loans",
    INSTITUTES = "Institutes",
    PROGRAMS = "Programs",
    COHORTS = "Cohort",
    TRAINEES = "Trainees",
    LOANPRODUCT = "Loan Product"
}

export enum pathnames {
    FUNDS = "/fund",
    INVESTORS = "/investor",
    LOANS = "/loan",
    INSTITUTES = "/institute",
    PROGRAMS = "/program",
    COHORTS = "/cohort",
    TRAINEES = "/trainees",
    COHORT_DETAILS = "/cohort/cohort-details",
    INSTITUTE_DETAILS = "/institute/view",
    PROGRAM_DETAILS = "/program/view",
    // PROGRAMS_IN_INSTITUTE = "/institute/program"
}

export enum roles {
    PORTFOLIO_MANAGER = "PORTFOLIO_MANAGER",
    INSTITUTE_ADMIN = "INSTITUTE_ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN",
    TRAINEE = "TRAINEE",
}

export enum buttonNames {
    PROGRAMS = "program",
    ALL_COHORT = "all cohort",
    INCOMING_COHORT = "incoming cohort",
    GRADUATED_COHORT = "graduated cohort",
    CURRENT_COHORT = "current cohort",
    INSTITUTES = "institutes",
    INSTITUTES_DETAILS = "institute Name",
    INVITED_INSTITUTES = "invited institutes",
    DEACTIVATED_INSTITUTES = "deactivated institutes",
    AWAITING_DISBURSAL = "awaiting disbursal",
    DISBURSAL = "disbursal",
    LOAN_REQUEST = "loan request",
    LOAN_OFFER = "loan offer",
    DECLINED_REQUEST = "declined request",
    LOAN_PRODUCT = "loan product",
    TRAINEES = "Trainees",
    ADMINS = "Admins"
}

export enum notes{
    TWO_TRIALS = "The number of tries cannot exceed 2",
    ONE_TRIAL = "You have one trail left",
    NO_TRIAL = "You have no trial left",
}