import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import React from "react";
import {useAppDispatch} from "../../../../redux/hooks/hooks";
import styles from "../../../dashboard/loan/reuseables/reuseable.module.css"
import {useLocation} from "react-router-dom";
import {loanProductActions} from "../../../../redux/slices/loanProductSlice";


interface SideSliderProps {
    slideValue: string[];
    selected: string;
    value?: number;
    id?: string;
}

const   SideSlider: React.FC<SideSliderProps> =({slideValue, selected, value, id})=> {

    const height = value === 2? 'h-[4.15rem]' :
                    value === 3? 'h-[6rem]' :
                    value === 4? 'h-[8rem]' :''
                    // 'h-[10.5rem]'
    const dispatch = useAppDispatch();
    const active = 'text-[#0D9B48]'
    const inActive = 'text-[#72757A]'
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const productDetailsPage = queryParams.get("view");

    const handleClick = (item: string)=>{
        if(productDetailsPage){
            dispatch(loanProductActions.setLoanProductDetailsCurrentState(item))
            dispatch(pageAction.loanRequestDetailSideBarButton(item))
        }
        dispatch(pageAction.loanRequestDetailSideBarButton(item))

    }

    return (
        <div id={`${id}njdjvfo`} className={'text-[#94979D]'}>
            <div id={`${id}eoirhrnf`} className={`border rounded-xl ${height} w-1 bg-gray-200 absolute`}></div>
            {
                slideValue.map((item:string) => (
                    <div
                        id={`${item}lsdk`}
                        className={`${selected === item ? active : inActive} flex cursor-pointer justify-items-start `}
                        onClick={() => {handleClick(item)}}
                    >
                        <div id={`${id}${item}idnfjn`}
                             className={`border rounded-xl h-9 w-1 mr-4 z-40 ${selected === item ? 'bg-[#0D9B48]' : 'bg-gray-200'}`}></div>
                        <span id={`${id}zjfdkj${item}`} className={`pt-2 ${styles.item} `}>{item}</span>
                    </div>
                ))
            }
        </div>)
}



export default SideSlider;