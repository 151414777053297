import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from "../reuseables/CustomButton";

const LoanOfferTerms = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/loan');
    };

    return (
        <div id={'dmoeew'} className={'bg-[#FAFBFC] w-[53vw] px-5 pt-4'}>
            <h2 id={'ewppdm'} className={'font-semibold text-[18px] text-[#101828] mb-4'}>Loan offer terms</h2>
            <div id={'wiuefd'} className={'bg-white pb-5 pt-3.5 pr-10 pl-7'}>
                <h3 id={'awheufabds'} className={"text-[16px] font-semibold font-['IBM_Plex_Sans'] text-[#101828] mb-3 "}>Default terms</h3>
                <div className={'ml-4'}>
                    <li id={'ewiurycf'}>If the loanee fails to make payments for three consecutive months, the lender
                        reserves the right
                        to demand immediate repayment of the outstanding balance.
                    </li>
                    <li id={'oaehuhafed'} className={' mt-1'}>The loanee will be responsible for any legal fees incurred
                        by the lender to recover the loan
                        amount.
                    </li>
                </div>
                <h3 id={'aohefuhef'} className={'text-[16px] font-semibold text-[#101828] mb-3 mt-3'}>Loanee’s
                    responsibility</h3>
                <div className={'ml-4'}>
                    <li id={'caheagwuygc'}>The loanee agrees to provide accurate information and promptly inform the
                        lender
                        of any changes
                        to their financial situation.
                    </li>
                    <li id={'pyoiuyausd'} className={' mt-1'}>The loanee must maintain a valid bank account for the
                        duration of the loan.
                    </li>
                </div>
                <h3 id={'oytrytrtrd'} className={'text-[16px] font-semibold text-[#101828] mb-3 mt-3'}>Governing law</h3>
                <p id={'apwyeuiefn'} className={'ml-2'}>This loan agreement shall be governed by and construed in accordance with the laws
                    of the Federal Republic of Nigeria.</p>
            </div>

            <CustomButton
                id={'eirhuenfjnki'}
                back={'Trainee additional details'}
                buttonName={'Go to dashboard'}
                customStyle={'border rounded border-[#0D9B48] ml-[36rem] mt-2 mb-1 w-48 px-7 py-2 bg-[#ffffff] text-[#0D9B48] font-semibold'}
                onClick={handleButtonClick}
            />
        </div>
    );
};

export default LoanOfferTerms;