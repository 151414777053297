import React from 'react';

const EmptyStateBlock = () => {
    const blocks = [
        { borderColor: '#D0D5DD' },
        { borderColor: '#0D9B48' },
        { borderColor: '#D0D5DD' }
    ];
    return (
        <article id="emptyStateBlocks" className="grid gap-4 place-content-center">
            {blocks.map((block, index) => (
                <div
                    id={`emptyStateBlock${index}`}
                    key={index}
                    className={`flex gap-2 place-content-center rounded h-[36px] w-[230px] md:w-[18vw] md:h-[7vh] border-2`}
                    style={{
                        borderColor: block.borderColor,
                        boxShadow: index === 1 ? '0px 2px 8px 0px rgba(99, 99, 99, 0.12)' : 'none'
                    }}
                >
                    <div id={`emptyStateBlockContent${index}`} className="flex h-full w-full gap-2 p-4 items-center">
                        <div id={`emptyStateBlockIcon${index}`} className="md:w-8 md:h-8 h-5 w-5  bg-[#F0F2F4] rounded-full"></div>
                        <div id={`emptyStateBlockText${index}`} className="grid md:h-6 h-3 gap-2">
                            <div id={`emptyStateBlockLine1${index}`}
                                 className="md:w-[10.42vw] md:h-2 w-[162px] h-1 bg-[#f0f2f4] rounded-[32px]"></div>
                            <div id={`emptyStateBlockLine2${index}`}
                                 className="md:w-[7.40vw] md:h-2 w-[112px] h-1 bg-[#f0f2f4] rounded-[32px]"></div>
                        </div>
                    </div>
                </div>
            ))}
        </article>

    );
};

export default EmptyStateBlock;