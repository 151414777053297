import React, {Dispatch, SetStateAction, useState} from "react"
import {useAppSelector} from "../../../redux/hooks/hooks";
import styles from "./styles/TopBar.module.css";
import {FiBell} from "react-icons/fi";
import dummyProfileImage from "../../../features/dashboard/assets/images/dummyProfileImage.svg";
import Notifications from "./Notifications";
import Badge from '@mui/material/Badge'
import {GiHamburgerMenu} from "react-icons/gi";
import SideNavButton from "./SideNavButton";
import utils from "../../../utils/utils";
import {IoIosArrowDown} from "react-icons/io";
import ProfileDropdown from "./ProfileDropdown";
import {capitalizeFirstLetters} from "../GlobalMethod";
import {getFirstLetterOfWord} from "../GlobalMethod";
import {NotificationsNoneSharp} from "@mui/icons-material";
import NotificationBar from "../../../features/dashboard/traineeFlow/dashboard/NotificationBar";
import TraineeProfile from "../../../features/dashboard/traineeFlow/dashboard/TraineeProfile";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IoMdMenu } from "react-icons/io";
import {store} from "../../../redux/store";
import {layOutActions} from "../../../redux/slices/layOutSlice";


interface Props {
    // setSelectedName?: Dispatch<SetStateAction<string | null>>;
    selectedName: string | null;

}


function TopBarr (props:Props) {

    const {selectedName} = props;


    const { user } = useAppSelector((state) => state.auth);


    const fullName = user?.name;
    const patten = /^[A-Z_]+$/
    //@ts-ignore
    const filteredRoles = user?.realm_access?.roles?.filter(v => patten.test(v))

    const userRole = filteredRoles?.length ? filteredRoles[0] : undefined;


    const [arrowToggled, setArrowToggled] = useState(false);
    const [bellClicked, setBellClicked] = useState(false);
    const [hamburger, setHamburger] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const toggleArrow = () => {
        setArrowToggled(!arrowToggled);
    };

    const clickBell = () => {
        setBellClicked(!bellClicked);
    };

    const clickHamburger = () => {
        setHamburger(!hamburger);
        store.dispatch(layOutActions.setHamburger(true))
    };

    const handleLogout = () => {
        setOpenModal(true);
        setArrowToggled(false);
    };


    let className = ` flex place-content-center text-[]  bg-[#E0FDEB] rounded-full  w-[40px] h-[40px] `;
    return(
        <>
            <header
                className="bg-white flex items-center h-[8vh] w-full md:h-[10vh] md:w-[84vw] absolute md:static right-0 justify-between border-b border-gray-300"
            >
                <div className={'flex  w-[96%]  mr-auto ml-auto  place-content-between'}>
                    <div className={`flex gap-2 h-[1rem] mt-auto mb-auto place-content-center `}>
                        <div id={'buttonsDiv'} className={`relative flex place-items-center md:hidden`}>
                            <IoMdMenu  color='#667085' style={{height: '1rem', width: '1rem'}}  onClick={clickHamburger} id={'LayOutHamburger'} />

                        </div>
                        <div className={` relative flex place-items-center `}>
                            <div className={` font-medium    `}>{selectedName}</div>
                            <div className={` hidden`}></div>
                        </div>
                    </div>

                    <div id="LayOutProfileAndNotification" className="flex items-center gap-5 md:gap-10">
                        {/*<div id={'bellDiv'} className={` flex place-content-center object-fit h-[2.6rem]  w-[2.6rem] rounded-md mr-[1.7rem] `}>*/}
                        {/*  {bellClicked && <Notifications close={clickBell}/>}*/}
                        {/*      <Badge*/}
                        {/*         badgeContent={'33'}*/}
                        {/*         color="success" sx={{marginTop: 'auto', marginBottom: 'auto', height: '70%', width: '70%'}} >*/}
                        {/*                <FiBell className={` w-[100%] h-[100%] object-cover `}/>*/}
                        {/*      </Badge>*/}
                        {/*</div>*/}
                        <div id={'fullNameDiv'} className="flex gap-2  justify-between items-center w-[fit-content]">
                            <div
                                className={` flex place-content-center  object-fit  bg-[#E0FDEB]  mt-auto mb-auto rounded-full w-[30px] h-[30px]  md:w-[40px] md:h-[40px] `}>
                                <div
                                    className={` grid place-content-center  mt-auto mb-auto  ${styles.avatarText}  w-[70%] h-[70%]   `}>
                                    {getFirstLetterOfWord(fullName)}
                                </div>
                            </div>
                            <div className={` hidden md:grid md:gap-1  `}>
                                <p id={'fullNamePTag'}
                                   className={styles.fullName}>{capitalizeFirstLetters(fullName)}</p>
                                <p id={'formatRoleUserRole'}
                                   className={` flex ${styles.userRole} `}>{utils.formatRole(userRole)}</p>
                            </div>
                            <div id={'toggleArrowDiv'} className={``}>
                                {arrowToggled ?
                                    <ExpandLessIcon sx={{color: '#667085'}} onClick={toggleArrow}/> :
                                    <ExpandMoreIcon sx={{color: '#667085'}} onClick={toggleArrow}/>
                                }
                                {arrowToggled &&
                                    <ProfileDropdown onLogoutClick={handleLogout} close={toggleArrow}/>}
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        </>
    )
}


export default TopBarr