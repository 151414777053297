import React from 'react'
import styles from "../loanRequest/loanRequest.module.css";
import Avatar from "@mui/material/Avatar";
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import Button from "@mui/material/Button";
import {store} from "../../../../redux/store";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";




function Details( ){

    function handleContinue() {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Trainee additional details'))
    }

    const details = [
        {
            id: '1',
            field: 'Email address',
            value: 'vanessa1333@gmail.com'
        },
        {
            id: '2',
            field: 'Phone number',
            value: '+2349039402842'
        },
        {
            id: '3',
            field: 'Gender',
            value: 'Female'
        },
        {
            id: '4',
            field: 'Date of Birth',
            value: '191 March, 1999'
        },
        {
            id: '5',
            field: 'Marital status',
            value: 'Single'
        },
        {
            id: '6',
            field: 'Nationality',
            value: 'Nigeria'
        },
        {
            id: '7',
            field: 'State of Birth',
            value: 'Imo'
        },

        {
            id: '8',
            field: 'Residential address',
            value: '372, Herbert Macaulay Way, Alagomeji, Sabo, Yaba'
        },
        {
            id: '9',
            field: 'Program name',
            value: 'Product design'
        },
        {
            id: '10',
            field: 'Loan amount requested',
            value: '₦3,000,000.00'
        },
        {
            id: '11',
            field: 'Cohort name',
            value: 'Gems'
        },

    ]

    return (
        <div className={`rounded-md bg-[#fafbfc] grid static  h-[100%]  w-[100%]`}>
            <div className={` h-[5vh] ml-[1rem] mt-[1rem]   ${styles.loanDetail}`}> Trainee details</div>
            <div className={`${styles.traineeDetails}  h-[auto] ml-[1rem] w-[] bg-white `}>
                <div className={`mt-[1rem]  w-[97%] mr-[1rem] ml-[1rem] h-auto bg-white`}>
                    <div className={`flex place-content-between `}>
                        <div className={`flex gap-3 mt-[10px]`}>
                            <Avatar id={'userPicture'}
                                    src={`https://i.pinimg.com/236x/1b/59/df/1b59df5b8985aa6bf8c4b814afd3a222.jpg`}
                                    sx={{width: 45, height: 45}}/>
                            <div className={`grid gap-2`}>
                                <span className={`${styles.traineeName}`}>Vanessa Chiamaka Oluchukwa</span>
                                <div className={`flex gap-2  `}>
                                    <Avatar
                                        id={'schoolPicture'}
                                        src={`https://i.pinimg.com/236x/aa/8c/30/aa8c30a21e970beb28c2f64b3c3c30a8.jpg`}
                                        sx={{width: 24, height: 24}}/>
                                    <span
                                        className={` mt-auto mb-auto ${styles.school}`}>School of Product, Yaba</span>
                                </div>
                            </div>
                        </div>
                        <div className={` mt-[10px] ${styles.creditScore} flex `}>
                            <span className={`${styles.creditScoreText}`}>Credit score</span>
                            <div className={`${styles.score}`}>
                                <div className={`grid place-self-center pr-1 pl-1 ${styles.scoreText}`}>123</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={` w-[93%] mt-[2rem]  mr-auto ml-auto object-fit `}>
                    {details?.map((detail, index) => (
                        <DetailsFields field={detail?.field} value={detail?.value} showTopDivider={false} showBottomDivider={true} marginBottom={'2rem'}/>
                    ))
                    }
                </div>
                <div className={` flex  place-content-end gap-3 pb-[1rem] mt-[2rem]  w-[93%] mr-auto ml-auto`}>
                    <Button
                        id={'continueToTraineeDetails'}
                        variant={'contained'}
                        onClick={handleContinue}
                        sx={{
                            boxShadow: 0,
                            textTransform: 'none',
                            backgroundColor: '#0D9B48',
                            color: 'white',
                            borderColor: '#0D9B48',
                            "&:hover": {backgroundColor: '#0D9B48', border: '#0D9B48', boxShadow: 0}
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Details;