import React, {useState} from 'react';
import styles from "../styles/CreateLoanProduct.module.css";
import CustomButton from "../../reuseables/CustomButton";
import { InitialFormValueProps } from '../../../../../types/loan/loanProduct.type';
import {Formik, Form, Field} from 'formik';
import CustomSelectedInput from '../../../../userManagement/reuseables/input/SelectInput/CustomSelectInput';

type InsuranceType = 'creditLife' | 'health' | 'accommodation' | 'device';

const insuranceOptions: { label: string; value: InsuranceType }[] = [
  { label: 'Credit life insurance provider', value: 'creditLife' },
  { label: 'Health insurance provider', value: 'health' },
  { label: 'Accommodation provider', value: 'accommodation' },
  { label: 'Device provider', value: 'device' },
];

const providerOptions: Record<InsuranceType, { value: string; label: string }[]> = {
  creditLife: [
    { value: 'creditLifeProvider1', label: 'Credit Life Provider 1' },
    { value: 'creditLifeProvider2', label: 'Credit Life Provider 2' },
  ],
  health: [
    { value: 'healthProvider1', label: 'Health Provider 1' },
    { value: 'healthProvider2', label: 'Health Provider 2' },
  ],
  accommodation: [
    { value: 'accommodationProvider1', label: 'Accommodation Provider 1' },
    { value: 'accommodationProvider2', label: 'Accommodation Provider 2' },
  ],
  device: [
    { value: 'deviceProvider1', label: 'Device Provider 1' },
    { value: 'deviceProvider2', label: 'Device Provider 2' },
  ],
};

const termsOptions: Record<InsuranceType, { value: string; label: string }[]> = {
  creditLife: [
    { value: 'creditLifeTerm1', label: 'Credit Life Term 1' },
    { value: 'creditLifeTerm2', label: 'Credit Life Term 2' },
  ],
  health: [
    { value: 'healthTerm1', label: 'Health Term 1' },
    { value: 'healthTerm2', label: 'Health Term 2' },
  ],
  accommodation: [
    { value: 'accommodationTerm1', label: 'Accommodation Term 1' },
    { value: 'accommodationTerm2', label: 'Accommodation Term 2' },
  ],
  device: [
    { value: 'deviceTerm1', label: 'Device Term 1' },
    { value: 'deviceTerm2', label: 'Device Term 2' },
  ],
};


const SecondModal = ({handleBack,handleContinue,formValues,setFieldValue}: InitialFormValueProps) => {
    const [isCreditLife, setCreditLife]= useState(false);
    const [isHealthInsurance, setHealthInsurance]= useState(false);
    const [isAccommodation, setAccommodation]= useState(false);
    const [isDeviceProvider, setAccommodationProvider]= useState(false);
    const [bankPartner, setBankPartner] = useState('')

    const handleLoanProductSponsor = (bankPartnerType: string) => {
      setBankPartner(bankPartnerType);
  }

    
    const handleCurrencyChange = (e:any)=>{

    }
    let disabled=true;
    return (
        <div 
        className={`hidden md:block min-w-[42rem] p-[10px_35px_15px_35px] max-h-[80vh] overflow-y-auto w-[100%]`}
        >

            <label id={'sponsorndks'}>Bank partners</label>
            <div
                id={'dsnkjskdjsk;'}
                className={'mt-3 '}
                style={{
                    // borderColor: errors.loanProductSize1 && touched.loanProductSize1 ? "red" : "inherit",
                }}>
               
                 <Field
                as="select"
                name='bankPartner'
                className={`bg-white w-full pl-2 pr-[400px] h-10 border-[#D0D5DD] border rounded ${!formValues.bankPartner ? 'text-[#72757A]' : "text-black"} focus:outline-none sm:hidden`}
                >
                   <option  value="" selected disabled className=''>Select Bank partner </option> 
                   {["FCMB", "GTB"].map((sponsor, index) => (
                        <option id={`${sponsor}option`} key={index}
                                value={sponsor}>
                            {sponsor}
                        </option>
                    ))}
                </Field> 
                <CustomSelectedInput
            options={["FCMB", "GTB"].map(sponsor => ({ value: sponsor, label: sponsor }))}
            value={bankPartner}
            onChange={(value) => {
              handleLoanProductSponsor(value)
              setFieldValue("bankPartner", value);
            }}
            placeholder="Select Bank partner"
            customStyle="w-[100%]"
                                        />

            </div>

            <div id={'sponsorndks'} className={`mb-4 mt-5`}>Select loan insurance provider</div>
            {insuranceOptions.map((option,index) => (
              <div key={option.value}  className={' w-full pl-8 py-3 border-[#D0D5DD] border rounded mt-2 '}>
                <div className='flex '>
                <Field
                type="checkbox"
                 name="insuranceProviders"
                 value={option.value}
                className="w-4 mr-4 accent-green-700 focus:acce-pink-500"
                />
                <label className={'mt-2 ml-4'}>{option.label}</label>
                </div>
                {formValues.insuranceProviders?.includes(option.value) && (
                  <>
                  <div>
                  {/* <Field
                  as="select"
                  name={`providerDetails.${option.value}.provider`}
                  className={` bg-white w-full pl-2 pr-3 h-10 border-[#D0D5DD] border rounded  mt-4 md:w-[92%] ${!formValues.providerDetails?.creditLife.provider ? 'text-[#72757A]' : "text-black"}`}
                  >
                   <option
                   value=''
                   label={`Select ${option.label.toLowerCase()}`}
                   selected disabled

                   />
                    {providerOptions[option.value].map( (providerOption: { value: string; label: string }) => (
                       <option key={providerOption.value} value={providerOption.value}>
                       {providerOption.label}
                     </option>
                    ))}

                 
                  </Field> */}
                  <CustomSelectedInput
                   options={providerOptions[option.value]}
                   name={`providerDetails.${option.value}.provider`}
                   placeholder={`Select ${option.label.toLowerCase()}`}
                   onChange={(value) => {
                    setFieldValue(`providerDetails.${option.value}.provider`, value);  
                  }}
                  value={formValues.providerDetails?.[option.value]?.provider || ''}
                  customStyle=' bg-white w-full h-10 border rounded  mt-4 md:w-[92%] ml-1'

                  />
                  </div>
                  <div>
                    {/* <Field
                    as="select"
                    name={`providerDetails.${option.value}.terms`}
                    className={`bg-white w-full pl-2 pr-3 h-10 border-[#D0D5DD] border rounded mt-4 md:w-[92%] ${!formValues.providerDetails?.creditLife.terms ? 'text-[#72757A]' : "text-black"} focus:outline-none`}
                    >
                    <option
                    value=''
                    label={`Select ${option.label.toLowerCase()}`}
                    selected disabled
                    />
                     {termsOptions[option.value].map( (termOption: { value: string; label: string }) => (
                        <option key={termOption.value} value={termOption.value}>
                        {termOption.label}
                      </option>
                     ))}

                   
                    </Field> */}
                    <CustomSelectedInput
                   options={termsOptions[option.value]}
                   name={`providerDetails.${option.value}.terms`}
                   placeholder={`Select ${option.label.toLowerCase()}`}
                   onChange={(value) => {
                    setFieldValue(`providerDetails.${option.value}.terms`, value);  
                  }}
                  value={formValues.providerDetails?.[option.value]?.terms || ''}
                  customStyle=' bg-white w-full h-10  border rounded  mt-4 md:w-[92%] ml-1'

                  />
                  </div>
                  </>
                )}

                
              </div>
              
                
            ))}
            

            <div className={`w-full mt-6`}>
                <label id={'gdjftsy'}>Loan disbursement Terms</label>

               
              <Field
              as="textarea"
              name='loanDisbursementTerms'
              className="h-32 p-2 my-4 w-full fon-[16px] border border-[#D0D5DD] rounded focus:outline-none resize-none"
              placeholder="Enter disbursement terms"
              />
            </div>
            <div className={`grid grid-cols-2`}>
                {/* <CustomButton
                id={'eirhuenfjnki'}
                back={'Trainee additional details'}
                buttonName={'Back'}
                customStyle={'border rounded border-[#0D9B48] mt-3 py-3 px-6 justify-self-start bg-[#ffffff] text-[#0D9B48] font-semibold'}/> */}
                <button
                className='border rounded border-[#0D9B48] mt-3 py-3 px-6 justify-self-start bg-[#ffffff] text-[#0D9B48] font-semibold'
                onClick={handleBack}
                >
                    Back
                </button>
                {/* <CustomButton
                    id={'eirhuenfjnki'}
                    back={'Trainee additional details'}
                    buttonName={'Create loan product'}
                    
                    // disabled={disabled}
                    // customDisabledStyle={'border rounded mt-3 py-3 px-6 justify-self-end bg-[#D0D5DD] text-white'}
                    customStyle={'border rounded bg-[#0D9B48] mt-3 py-3 px-3 justify-self-end text-white'}/>
            </div> */}
            <button
            className='border rounded bg-[#0D9B48] mt-3 py-3 px-3 justify-self-end text-white'
            type='submit'
            >
                   Create loan product
            </button>
            </div>
        </div>
    );
};

export default SecondModal;