import React from "react";
import {persistor, store} from "./redux/store";
import RootNavigation from "./navigation/RootNavigation";
import {Provider} from "react-redux";
import {Toaster} from "react-hot-toast";
import {PersistGate} from "redux-persist/integration/react";
import "./index.css";
import authService from "./services/auth.service";
import SnackBar from "./features/userManagement/reuseables/modal/SnackBar";

function App() {
    return (
            <Provider store={store}>
                <PersistGate
                    onBeforeLift={async () => await authService.revalidate()}
                    // @ts-ignore
                    persistor={persistor}
                >
                    <SnackBar/>
                    <RootNavigation/>
                    {/*<Toaster toastOptions={{*/}
                    {/*    position: "bottom-left",*/}
                    {/*    duration: 10_000,*/}
                    {/*}}/>*/}
                </PersistGate>
            </Provider>

    );
}

export default App;