import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { nameRegExp, nameValidationMessage } from "../../../../../utils";
import utils from "../../../../../utils/utils";
import { Field, Form, Formik } from "formik";
import styles from "../styles/CreateLoanProduct.module.css";
import { Icon } from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import { snackBarActions } from "../../../../../redux/slices/snackbar";
import { store } from "../../../../../redux/store";
import { useAppSelector } from "../../../../../redux/hooks/hooks";
import CustomSelectedInput from "../../../../userManagement/reuseables/input/SelectInput/CustomSelectInput";
import "../styles/InitialModal.css";
import Select from "react-select";
import SecondModalMobile from "./SecondModalMobile";

function convertToDays(value: number, unit: any) {
  switch (unit) {
    case "Years":
      return value * 365;
    case "Months":
      return value * 30;
    case "Days":
      return value;
    default:
      return 0;
  }
}

interface OptionType {
  value: string;
  label: string;
}

interface formProps {
  showStepNumber?: boolean;
  handleModalClose: () => void;
}

const initialFormValue = {
  loanProductName: "",
  loanProductSize1: "",
  interestRate: "",
  tenor: "",
  moratorium: "",
  termsAndCondition: "",
  costOfFund: "",
  minimumRepaymentAmount: "",
  obligorLimit: "",
  loanProductMandate: "",
  fundProduct: "",
  loanProductSponsor: "",
  bankPartner: "",
  loanProductPartners: "",
  insuranceProviders: [],
  providerDetails: {
    creditLife: { provider: "", terms: "" },
    health: { provider: "", terms: "" },
    accommodation: { provider: "", terms: "" },
    device: { provider: "", terms: "" },
  },
  loanDisbursementTerms: "",
  selectedTenor: "",
};

const stepsArray = ["A", "B"];

const sponsors = [
  { id: 1, name: "FCMB", balance: 20000000},
  { id: 2, name: "GTB", balance: 150000000 }
];

const InitialModalMobile: React.FC<formProps> = ({
  showStepNumber,
  handleModalClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("NGN");
  const [selectCurrency, setSelectCurrency] = useState("NGN");
  const currencyOptions = ["NGN", "USD", "GBP"].map((currency) => ({
    value: currency,
    label: currency,
  }));
  const [loanProductSponsor, setLoanProductSponsor] = useState("");
  const [fundProduct, setFundProduct] = useState("");
  const [selectedTenor, setSelectedTenor] = useState("");
  const tenorOptions = ["Years", "Months", "Days"];
  const [selectedMoratorium, setMoratorium] = useState("");
  const [mouseOver, setMouseOver] = useState(false);
  const showSnackbar = useAppSelector((state) => state.snackbar.display);

  const handleLoanProductSponsor = (loanSponsorType: string) => {
    setLoanProductSponsor(loanSponsorType);
  };

  const handleFundProductChange = (fundProductType: string) => {
    setFundProduct(fundProductType);
  };

  const handleObligorLimitChange = (e: any, setFieldValue: any) => {
    const rawValue = e.target.value.replace(/,/g, "");
    setFieldValue("obligorLimit", rawValue);
  };

  const formatNumberWithCommas = (value: string) => {
    if (!value) return "";
    const cleanedValue = value.replace(/,/g, "");
    return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const validationSchema = Yup.object().shape({
    loanProductName: Yup.string()
      .required("Loan Product Name is required")
      .matches(nameRegExp, nameValidationMessage),
    loanProductSponsor: Yup.string().required("Loan product  Type is required"),
    loanProductSize1: Yup.string()
      .transform((originalValue) => originalValue?.replace(/,/g, "")?.trim())
      .test(
        "is-positive-number",
        "Loan product size must be a positive number",
        (value) =>
          typeof value === "string" &&
          !isNaN(parseFloat(value)) &&
          parseFloat(value) > 0 &&
          !value.startsWith("0")
      )
      .required("Loan Product Size is required"),
    obligorLimit: Yup.string()
      .transform((originalValue) => originalValue?.replace(/,/g, "")?.trim())
      .test(
        "is-positive-number",
        "Loan product size must be a positive number",
        (value) =>
          typeof value === "string" &&
          !isNaN(parseFloat(value)) &&
          parseFloat(value) > 0 &&
          !value.startsWith("0")
      )
      .required("Obligor loan limit is required")
      .test(
        "obligorLoanLimit-vs-loanProductSize",
        "Obligor Limit should be less than Loan Product Size",
        function (value) {
          const loanProductSizeValue = parseFloat(this.parent.loanProductSize1);
          const obligorLoanLimitValue = parseFloat(value);
          return (
            !isNaN(loanProductSizeValue) &&
            !isNaN(obligorLoanLimitValue) &&
            obligorLoanLimitValue < loanProductSizeValue
          );
        }
      )
      .min(0, "Obligor Loan Limit must not be less than 0"),
    interestRate: Yup.number()
      .min(1, "Interest rate must not be less than 1")
      .max(100, "Cost of Interest rate must be greater than 100")
      .positive("Interest rate must be greater than 0")
      .required("Interest Rate is required")
      .test(
        "maxDigitsAfterDecimal",
        "Interest can take just 2 digits after decimal or less",
        (value) => {
          if (value === null || value === undefined) return true;
          const decimalPattern = /^\d*(\.\d{0,2})?$/;
          return decimalPattern.test(value.toString());
        }
      ),
    minimumRepaymentAmount: Yup.string()
      .min(1, "Cost of fund must not be less than 1")
      .transform((originalValue) => originalValue?.replace(/,/g, "")?.trim())
      .test(
        "is-positive-number",
        "Loan product size must be a positive number",
        (value) =>
          typeof value === "string" &&
          !isNaN(parseFloat(value)) &&
          parseFloat(value) > 0 &&
          !value.startsWith("0")
      )
      .required("Minimum Repayment Amount is required")
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),

    costOfFund: Yup.number()
      .min(1, "Cost of fund must not be greater than 0")
      .max(100, "Cost of fund must be less or equal than 100")
      .positive("cost of fund must be greater than 0")
      .required("cost of fund is required")
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),
    loanProductMandate: Yup.string().required(
      "Loan Product Mandate is required"
    ),
    tenor: Yup.number()
      .required("Tenor is required")
      .min(0, "Tenor must not be less than 0")
      .positive("Tenor must be greater than 0")
      .when("selectedTenor", {
        is: (selectedTenor: string) =>
          selectedTenor === "Years" ||
          selectedTenor === "Months" ||
          selectedTenor === "Days",
        then: (schema) => {
          if (selectedTenor === "Years") {
            return schema
              .min(1, "Minimum is 1 year")
              .max(10, "Maximum is 10 years");
          } else if (selectedTenor === "Months") {
            return schema
              .min(12, "Minimum is 12 months")
              .max(120, "Maximum is 120 months");
          } else if (selectedTenor === "Days") {
            return schema
              .min(365, "Minimum is 365 days")
              .max(3650, "Maximum is 3650 days");
          }
          return schema;
        },
      })
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),

    moratorium: Yup.number()
      .min(1, "Moratorium must not be greater than 0")
      .max(24, "Cost of fund must be less than 24")
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .positive("Moratorium must be greater than 0")
      .required("Moratorium is required"),

    termsAndCondition: Yup.string()
      .required("Terms and condition must be set")
      .max(2500, "Maximum amount of characters cannot exceed 2,500"),
  });

  const handleCurrencyChange = (option: any) => {
    setSelectedCurrency(option.value);
  };

  const handleCurrency = (option: any) => {
    setSelectCurrency(option.value);
  };

  const handleTenorChange = (tenor: string) => {
    setSelectedTenor(tenor);
  };

  const handleMoratoriumChange = (moratorium: string) => {
    setMoratorium(moratorium);
  };

  const selectedFundBalance = sponsors.find(product => product.name === fundProduct)?.balance
  

  const [step, setSteps] = useState("A");

  const handleContinue = () => {
    if (step === "A") setSteps("B");
  };

  const handleBack = () => {
    if (step === "B") setSteps("A");
  };

  const renderTopStepNumbers = () => {
    if (!showStepNumber || step === "Final") {
      return null;
    }

    
    return (
      <section className="mt-2 mb-4 flex justify-between">
        {stepsArray.map((item) => (
          <div
            key={item}
            className={`w-8  h-8 justify-center items-center border-2 border-gray-600 rounded-full cursor-pointer flex ${
              item === step ? "bg-blue-500" : ""
            } `}
            onClick={() => setSteps(item)}
          >
            {item}
          </div>
        ))}
      </section>
    );
  };

  let timeOut: any;

  function handleTimeOut() {
    timeOut = setTimeout(() => {
      store.dispatch(
        snackBarActions.updateSnackBar({
          type: "OPEN",
          message: "You have successfully created a loan product",
          success: true,
        })
      );
    }, 500);
  }

  useEffect(() => {
    if (showSnackbar) {
      handleTimeOut();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [showSnackbar, timeOut]);

  const handleSubmit = async (values: any) => {
    const tenorInDays = convertToDays(values.tenor, selectedTenor);
    setSteps("Final");
    handleModalClose();
    console.log("Submit Data", values, tenorInDays);
    handleTimeOut();
  };

  return (
    <div id={`mobileModal`}>
      <Formik
        initialValues={{ ...initialFormValue, selectedTenor }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ values, errors, isValid, touched, setFieldValue }) => (
          <Form>
            {renderTopStepNumbers()}

            {step === "A" && (
              <div
                className={`p-[10px_25px_15px_25px]   md:hidden 
                        h-[calc(100vh-100px)] max-h-[70vh] overflow-y-auto 
                         md:h-auto md:max-h-none md:overflow-visible    
                  `}
              >
                <div id={"outerDivMobile"} className={`grid grid-cols-1`}>
                  <div id={"first"} className={" h-[60px]"}>
                    <div className="mb-3">
                      <label id={"loanProductMobile"}>Loan Product Name</label>
                    </div>

                    <Field
                      type="text"
                      name="loanProductName"
                      placeholder="Enter Product Name"
                      style={{
                        borderColor:
                          errors.loanProductName && touched.loanProductName
                            ? "red"
                            : "inherit",
                      }}
                      className="border-2 border-[#d0d5dd] pl-2 w-full rounded-[4px] focus:outline-none"
                    />
                    {typeof errors.loanProductName &&
                      touched.loanProductName && (
                        <div
                          id={"loanProductNameError"}
                          className={styles.error}
                        >
                          {errors.loanProductName}
                        </div>
                      )}
                  </div>

                  <div
                    id={"fieldDivdskjks"}
                    // className={`${styles.field}`}
                    className="mt-10"
                  >
                    <div className="mb-3">
                      <label id={"sponsorndks"}>Loan Product Sponsor</label>
                    </div>
                    <div
                      id={"dsnkjskdjsk;"}
                      className={""}
                      style={{
                        borderColor:
                          errors.loanProductSize1 && touched.loanProductSize1
                            ? "red"
                            : "inherit",
                      }}
                    >
                      <CustomSelectedInput
                        options={["FCMB", "GTB"].map((sponsor) => ({
                          value: sponsor,
                          label: sponsor,
                        }))}
                        value={loanProductSponsor}
                        onChange={(value) => {
                          handleLoanProductSponsor(value);
                          setFieldValue("loanProductSponsor", value);
                        }}
                        placeholder="Select Loan Product Sponsor"
                      />
                    </div>
                    {errors.loanProductSponsor &&
                      touched.loanProductSponsor && (
                        <div id={"ckaskjjkll"} className={styles.error}>
                          {errors.loanProductSponsor}
                        </div>
                      )}
                  </div>
                  <div
                    id={"fieldDivdstrskjks"}
                    className={`mt-6
                            `}
                  >
                    <div className="mb-3">
                      <label id={"sponsosgfrndks"}>Fund Product </label>
                    </div>
                    <div
                      id={"dsnkjskdjtrsk;"}
                      className={""}
                      style={{
                        borderColor:
                          errors.loanProductSize1 && touched.loanProductSize1
                            ? "red"
                            : "inherit",
                      }}
                    >
                      <CustomSelectedInput
                        options={sponsors.map((sponsor) => ({
                          value: sponsor.name,
                          label: sponsor.name,
                        }))}
                        value={fundProduct}
                        onChange={(value) => {
                          handleFundProductChange(value);
                          setFieldValue("fundProduct", value);
                        }}
                        placeholder="Select Fund Product"
                      />
                    </div>
                    <div>
                                    {
                                       fundProduct && (
                                        <div className="flex justify-between">
                                            <div className="text-[#72757A]">Remaing balance: </div>
                                            <div className="text-[#72757A]">
                                          {selectedFundBalance?.toLocaleString()} 
                                            </div>
                                           
                                        </div>
                                       ) 
                                    }
                                 </div>
                    {errors.fundProduct && touched.fundProduct && (
                      <div id={"ckaskjjkltrl"} className={styles.error}>
                        {errors.loanProductSize1}
                      </div>
                    )}
                  </div>
                  <div className={"mt-6"}>
                    <div className="mb-3">
                      <label id={"MoratoriumLabel"} className={``}>
                        Cost of funds (%)
                      </label>
                    </div>

                    <div
                      id={"ternorFieldDiv"}
                      //  className={'border border-[#D0D5DD] rounded w-36 '}
                    >
                      <Field
                        className={
                          "w-full pl-2 bg-white focus:outline-none border border-[#D0D5DD] rounded "
                        }
                        type="number"
                        name="costOfFund"
                        placeholder="0.00"
                        min={0}
                        max={25}
                        // disabled={!selectedMoratorium}
                      />
                    </div>
                    {errors.costOfFund && touched.costOfFund && (
                      <div
                        id={"costOfFundError"}
                        className={"text-red-500 text-[0.8rem] w-36"}
                      >
                        {errors.costOfFund}
                      </div>
                    )}
                  </div>
                  <div id={"ternorFieldDiv"} className="mt-6 ">
                    <label id={"MoratoriumLabel"}>Tenor</label>
                  </div>
                  <div className="flex gap-3 mt-3 ">
                    <div className={""}>
                      <Field
                        className={
                          "w-full pl-2 bg-white border-[#D0D5DD] border rounded focus:outline-none "
                        }
                        type="number"
                        name="tenor"
                        placeholder="0.00"
                        disabled={!selectedTenor}
                      />
                      {errors.tenor && touched.tenor && (
                        <div
                          id={"ckaskjjkltrl"}
                          className={`${styles.error} flex `}
                        >
                          {errors.tenor}
                        </div>
                      )}
                    </div>
                    <div id={"currencyDropDownSelectDiv"}>
                      <CustomSelectedInput
                        options={tenorOptions.map((sponsor) => ({
                          value: sponsor,
                          label: sponsor,
                        }))}
                        value={selectedTenor}
                        onChange={(value) => {
                          handleTenorChange(value);
                          setFieldValue("selectedTenor", value);
                        }}
                        placeholder="Select.."
                        customStyle="w-40"
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <label id={"sponsosgfrndks"}>Loan product size </label>
                  </div>
                  <div className="flex gap-3 mt-3 ">
                    <div
                      id={"fieldDivdstrskjks"}
                      className={`${styles.field} col-span-1`}
                    >
                      <div
                        id={"dsnkjskdjtrsk;"}
                        className={""}
                        style={{
                          borderColor:
                            errors.loanProductSize1 && touched.loanProductSize1
                              ? "red"
                              : "inherit",
                        }}
                      >
                        <Select
                          options={currencyOptions}
                          value={currencyOptions.find(
                            (option) => option.value === selectCurrency
                          )}
                          onChange={handleCurrency}
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#fff",
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#E7F7ED",
                                color: "#0EAA4F",
                              },
                            }),
                            indicatorSeparator: () => ({
                              display: "none",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0 8px",
                              height: "2.5rem",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              padding: "0 3px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0",
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              border: "1px solid #D0D5DD",
                              borderRadius: "4px",
                              height: "2.5rem",
                              width: "90px",
                              boxShadow: state.isFocused
                                ? "none"
                                : provided.boxShadow,
                              borderColor: state.isFocused
                                ? "#D0D5DD"
                                : provided.borderColor,
                              "&:hover": {
                                borderColor: "#D0D5DD",
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div id={"just added"} className={" w-full col-span-4 "}>
                      <div
                        id={"ternorFieldDiv"}
                        className={"border border-none rounded "}
                      >
                        <Field
                          className={`w-full pl-2 bg-white border border-[#D0D5DD] rounded ${
                            !values.loanProductSize1
                              ? "text-[#72757A]"
                              : "text-black"
                          } focus:outline-none`}
                          type="text"
                          name={`loanProductSize1`}
                          placeholder="0.00"
                          onChange={(e: any) => {
                            setFieldValue(
                              "loanProductSize1",
                              utils.formatValueWithComma(e)
                            );
                          }}
                        />
                        {errors.loanProductSize1 &&
                          touched.loanProductSize1 && (
                            <div id={"ckaskjjkltrl"} className={styles.error}>
                              {errors.loanProductSize1}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 mt-6 ">
                    <div className="w-full">
                      <div className="mb-3">
                        <label id={"MoratoriumLabel"} className={``}>
                          Interest (%)
                        </label>
                      </div>
                      <div
                        id={"ternorFieldDiv"}
                        className={"border border-none rounded "}
                      >
                        <Field
                          className={
                            "w-full pl-2 pb-0.5 bg-white border border-[#D0D5DD] rounded focus:outline-none"
                          }
                          type="number"
                          name="interestRate"
                          placeholder="0.00"
                          min={0}
                          max={25}
                          // disabled={!selectedMoratorium}
                        />
                      </div>
                      {errors.interestRate && touched.interestRate && (
                        <div id={"interestError"} className={styles.error}>
                          {errors.interestRate}
                        </div>
                      )}
                    </div>
                    <div className={" w-full"}>
                      <div className="mb-3">
                        <label id={"MoratoriumLabel"} className={``}>
                          Moratorium (month){" "}
                        </label>
                      </div>
                      <div
                        id={"ternorFieldDiv"}
                        className={"border border-none rounded"}
                      >
                        <Field
                          className={
                            "w-full pl-2 pb-0.5 bg-white border border-[#D0D5DD] rounded focus:outline-none"
                          }
                          type="number"
                          name="moratorium"
                          placeholder="0.00"
                          min={0}
                          max={25}
                          // disabled={!selectedMoratorium}
                        />
                      </div>
                      {errors.moratorium && touched.moratorium && (
                        <div id={"ckaskjjkll"} className={styles.error}>
                          {errors.moratorium}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-6">
                    <label id={"sponsosgfrndks"}>
                      Minimum repayment amount{" "}
                    </label>
                  </div>
                  <div className="flex gap-3 mt-3 ">
                    <div
                      id={"fieldDivdstrskjks"}
                      className={`${styles.field} col-span-1`}
                    >
                      <div
                        id={"dsnkjskdjtrsk;"}
                        className={""}
                        style={{
                          borderColor:
                            errors.minimumRepaymentAmount &&
                            touched.minimumRepaymentAmount
                              ? "red"
                              : "inherit",
                        }}
                      >
                        <Select
                          options={currencyOptions}
                          value={currencyOptions.find(
                            (option) => option.value === selectCurrency
                          )}
                          onChange={handleCurrency}
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#fff",
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#E7F7ED",
                                color: "#0EAA4F",
                              },
                            }),
                            indicatorSeparator: () => ({
                              display: "none",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0 8px",
                              height: "2.5rem",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              padding: "0 3px",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0",
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              border: "1px solid #D0D5DD",
                              borderRadius: "4px",
                              height: "2.5rem",
                              width: "90px",
                              boxShadow: state.isFocused
                                ? "none"
                                : provided.boxShadow,
                              borderColor: state.isFocused
                                ? "#D0D5DD"
                                : provided.borderColor,
                              "&:hover": {
                                borderColor: "#D0D5DD",
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div id={"just added"} className={" w-full col-span-4 "}>
                      <div
                        id={"ternorFieldDiv"}
                        className={"border border-none rounded "}
                      >
                        <Field
                          className={`w-full pl-2 bg-white border border-[#D0D5DD] rounded ${
                            !values.minimumRepaymentAmount
                              ? "text-[#72757A]"
                              : "text-black"
                          } focus:outline-none`}
                          type="text"
                          name="minimumRepaymentAmount"
                          placeholder="0.00"
                          onChange={(e: any) => {
                            setFieldValue(
                              "minimumRepaymentAmount",
                              utils.formatValueWithComma(e)
                            );
                          }}
                        />
                        {errors.minimumRepaymentAmount &&
                          touched.minimumRepaymentAmount && (
                            <div id={"ckaskjjkltrl"} className={styles.error}>
                              {errors.minimumRepaymentAmount}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div id={"fieldDivdskjktrs"} className={`mt-6`}>
                    <div className="mb-3">
                      <label id={"MoratoriumLabel"} className={``}>
                        Obligor limit
                      </label>
                    </div>
                    <div
                      id={"ternorFieldDiv"}
                      className={"border border-none rounded "}
                    >
                      <Field
                        className={
                          "w-full pl-2 pb-0.5 bg-white border border-[#D0D5DD] rounded focus:outline-none"
                        }
                        type="text"
                        name="obligorLimit"
                        placeholder="0.00"
                        onChange={(e: any) => {
                          setFieldValue(
                            "obligorLimit",
                            utils.formatValueWithComma(e)
                          );
                        }}
                      />
                    </div>
                    {errors?.obligorLimit && touched?.obligorLimit && (
                      <div id={"obligorLimit"} className={styles.error}>
                        {errors.obligorLimit}
                      </div>
                    )}
                  </div>
                  <div className={`mt-6`}>
                    <div className="mb-3">
                      <label id={"MoratoriumLabel"} className={``}>
                        Loan product mandate
                      </label>
                    </div>
                    <Field
                      as="textarea"
                      name={`loanProductMandate`}
                      id="loanProductMandate"
                      className="h-32 p-2 w-full fon-[16px] border border-[#D0D5DD] rounded focus:outline-none resize-none"
                      placeholder="Enter product mandate"
                    />
                    {errors?.loanProductMandate &&
                      touched?.loanProductMandate && (
                        <div id={"obligorLimit"} className={styles.error}>
                          {errors.loanProductMandate}
                        </div>
                      )}
                  </div>
                  <div className={`mt-6`}>
                    <div className="mb-3">
                      <label id={"loanTermAndConditionLabel"} className={``}>
                        Loan terms and conditions
                      </label>
                    </div>
                    <Field
                      as="textarea"
                      name={`termsAndCondition`}
                      className="h-32 p-2 w-full fon-[16px] border border-[#D0D5DD] rounded focus:outline-none resize-none "
                      placeholder="Enter terms and conditions"
                    />
                    {errors?.termsAndCondition &&
                      touched?.termsAndCondition && (
                        <div id={"obligorLimit"} className={styles.error}>
                          {errors.termsAndCondition}
                        </div>
                      )}
                  </div>
                  <div>
                    <button
                      id={"submitButton"}
                      className={`${
                        !isValid
                          ? "bg-[#D0D5DD]  cursor-not-allowed"
                          : "bg-green-600  "
                      } w-full block text-white rounded-md mt-8 h-[41px]`}
                      disabled={!isValid}
                      onClick={handleContinue}
                    >
                      {isLoading ? (
                        <div
                          id={"loadingLoopIconDiv"}
                          className="flex items-center justify-center"
                        >
                          <Icon
                            id={"Icon"}
                            icon={loadingLoop}
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 'B' && <SecondModalMobile formValues={values} handleBack={handleBack} setFieldValue={setFieldValue}/>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InitialModalMobile;
