import React from 'react'
import styles from "./styles/SideBar.module.css";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {Link} from "react-router-dom";
import learnSpaceLogo from "../../../features/dashboard/assets/images/learnspaceLogo.svg";
import learnSpaceLetters from "../../../features/dashboard/assets/images/learnspaceLetters.svg";
import SideNavButton from "./SideNavButton";
import SideBarBottomLayer from "./SideBarBottomLayer";
import {useAppSelector} from "../../../redux/hooks/hooks";
import {store} from "../../../redux/store";
import {layOutActions} from "../../../redux/slices/layOutSlice";

interface Prop {
    barCollapse: boolean;
    selectedName: string | null;

}

function SideBarr ({barCollapse, selectedName}: Prop){


    const height = window.innerHeight;
    const showSide = useAppSelector((state) => state.layOut.hamburger)


    return(
        <>
            {showSide &&
                <aside
                    id={'mobileSideBar'}
                    className={` w-[100vw] h-[100vh]  flex z-10 md:hidden`}
                >
                    <div
                        className={` w-[70vw]  py-8 px-5 border border-r-[#E0E3E8] z-10 h-[100%] bg-learnSpaceWhite `}
                    >
                        <div className={`h-[100%] w-[96%] `}>
                            <Link id={'fund'} to="" className={``}>
                                <div id={'imagesDiv'}
                                     className=" w-[60%] flex items-center mb-12 gap-2 object-fit ">
                                    <img id={'learnSpaceLogo'} src={learnSpaceLogo} className=" w-[20%]"
                                         alt="learn-space-logo"/>
                                    <img id={'learnSpaceLetters'} src={learnSpaceLetters} className={` w-[78%]`}
                                         alt="learn-space-logo"/>
                                </div>
                            </Link>
                            <div id={'SideNavButtonDiv'} className={`w-full`}>
                                <SideNavButton
                                    selectedName={selectedName}
                                    barCollapse={barCollapse}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={` h-[100vh] w-[40vw] backdrop-blur-sm bg-[grey/30] `}
                         onClick={() => { store.dispatch(layOutActions.setHamburger(false))}}
                    ></div>

                </aside>

            }
            <aside
                id={'barCollapse'}
                className={`  hidden md:flex  md:w-[16vw] py-8 px-5 border border-r-[#E0E3E8] z-10 h-[100%] bg-learnSpaceWhite  `}
            >

                <div className={`h-[100%] w-[90%] mr-auto ml-auto`}>
                    <Link id={'fund'} to="" className={``}>
                        <div id={'imagesDiv'} className=" w-[60%] flex items-center mb-12 gap-2 object-fit  ml-4">
                            <img id={'learnSpaceLogo'} src={learnSpaceLogo} className=" w-[20%]"
                                 alt="learn-space-logo"/>
                            <img id={'learnSpaceLetters'} src={learnSpaceLetters} className={` w-[78%]`}
                                 alt="learn-space-logo"/>
                        </div>
                    </Link>
                    <div id={'SideNavButtonDiv'} className={`w-full`}>
                        <SideNavButton
                            selectedName={selectedName}
                            barCollapse={barCollapse}
                            />
                        </div>
                        <div className={`hidden  md:grid md:w-[78%] md:mr-auto md:ml-auto `}>
                            <SideBarBottomLayer/>
                        </div>
                    </div>
            </aside>
        </>

    )
}

export default SideBarr;