import React from 'react';

const HelpIcon = () => {
    return (
        <svg id={'HelpIcon'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_23518_10590)">
                <path
                    d="M10 18.3334C14.6024 18.3334 18.3333 14.6025 18.3333 10.0001C18.3333 5.39771 14.6024 1.66675 10 1.66675C5.39763 1.66675 1.66667 5.39771 1.66667 10.0001C1.66667 14.6025 5.39763 18.3334 10 18.3334Z"
                    stroke="#72757A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 14.1667H10.0083" stroke="#72757A" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M7.575 7.49999C7.77092 6.94304 8.15762 6.47341 8.66663 6.17426C9.17563 5.87512 9.77408 5.76577 10.356 5.86558C10.9379 5.96539 11.4657 6.26792 11.8459 6.71959C12.2261 7.17126 12.4342 7.74292 12.4333 8.33332C12.4333 9.99999 9.93333 10.8333 9.93333 10.8333"
                    stroke="#72757A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_23518_10590">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default HelpIcon;