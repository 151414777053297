import styles from "../loanRequest/loanRequest.module.css";
import Avatar from "@mui/material/Avatar";
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import Button from "@mui/material/Button";
import React from "react";
import {store} from "../../../../redux/store";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import Divider from "@mui/material/Divider";




function LoanDetails() : JSX.Element{
    function DisburseLoan() {

    }

    function handleBack() {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Trainee additional details'))

    }


    return (
        <>
            <div className={`rounded-md bg-[#fafbfc] fixed grid  h-[40%] w-[48%]   `}>
                <div className={` w-[95%] grid content-end h-[2.5rem]  ml-[1rem] `}>
                    <div className={` mt-auto mb-auto ${styles.loanDetail}`}> Loan details</div>
                </div>
                <div className={`w-[95%] h-auto mt-[1rem] mr-auto ml-auto bg-[#FFFFFF] rounded-md`}>
                    <div className={`w-[97%] h-auto  mr-auto ml-auto `}>
                        <div className={`flex place-content-between mb-[1rem] `}>
                            <div className={`flex gap-3 mt-[10px]`}>
                                <Avatar src={`https://i.pinimg.com/474x/d8/7e/f8/d87ef8cc1f1fb646bb2f7c8fc5b763c0.jpg`}
                                        sx={{width: 45, height: 45, backgoundColor: 'red'}}/>
                                <div className={`grid gap-2`}>
                                    <span className={`${styles.traineeName}`}>Vanessa Chiamaka Oluchukwa</span>
                                    <div className={`flex gap-2  `}>
                                        <Avatar
                                            src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxGM9vEmIwhmh_n8-8yP4ACNtma2a44rqycA&s`}
                                            sx={{width: 24, height: 24}}/>
                                        <span className={` mt-auto mb-auto ${styles.school}`}>School of Product, Yaba</span>
                                    </div>
                                </div>
                            </div>
                            <div className={` mt-[10px] ${styles.creditScore} flex `}>
                                <span className={`${styles.creditScoreText}`}>Credit score</span>
                                <div className={`${styles.score}`}>
                                    <div className={`pr-1 pl-1 ${styles.scoreText}`}>123</div>
                                </div>
                            </div>
                        </div>
                        <div className={` `}>
                            <DetailsFields field={'Loan amount requested'} value={'₦30,000,000.00'} showTopDivider={false} showBottomDivider={true} marginBottom={'1rem'}/>
                            <DetailsFields field={'Tuition amount'} value={'₦50,000,000.00'} showTopDivider={false} showBottomDivider={true} marginBottom={'1rem'}/>
                            <DetailsFields field={'Initial deposit'} value={'₦20,000,000.00'} showTopDivider={false} showBottomDivider={false} marginBottom={'1rem'}/>
                        </div>
                        <div className={`w-auto mt-[2rem]`}>
                            <div className={`${styles.tuition}`} >
                                <div>view tuition breakdown
                                    <hr className={`${styles.line}`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`grid mt-[1rem] mb-[1rem] mr-auto ml-auto h-[auto] bg-[#fafbfc] w-[93%]  gap-5 place-content-end`}>
                        <div className={` flex gap-3 mb-[2rem]`}>
                            <Button
                                onClick={handleBack}
                                id="backToAdditionalDetails"
                                variant='outlined'
                                sx={{boxShadow: 0,width: 'auto',paddingRight: '8px',paddingLeft: '8px',textTransform: 'none','&:hover' : {borderColor:'#0D9B48' }, color:  '#0D9B48', backgroundColor: 'white', borderColor: '#0D9B48' }}>
                                Back</Button>
                            <Button
                                onClick={DisburseLoan}
                                id="disburseLoan"
                                variant='contained'
                                sx={{
                                    boxShadow: 0,width: 'auto',paddingRight: '8px',paddingLeft: '8px',textTransform: 'none','&:hover' : {borderColor:'#0D9B48', backgroundColor: '#0D9B48' }, color:  'white', backgroundColor: '#0D9B48', borderColor: '#0D9B48' }}
                            >
                                Disburse loan
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoanDetails