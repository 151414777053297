import {FormikHelpers} from "formik";
import toast from "react-hot-toast";
import {IInstitute} from "../types/institute.type";
import image1 from "../assets/mailenvelope.png";
import image2 from "../assets/image/LearnspaceSeed.svg";
import image3 from "../assets/image/authBg.svg";
import image4 from "../assets/image/LoanRequestInstituteImageExample.png";
import image5 from "../features/dashboard/assets/Cohorts.svg";
import image6 from "../assets/dashBoardLogo.svg";

class Utils {
    public handleRequestError(err: any, helpers?: FormikHelpers<any>) {
        helpers && helpers.setSubmitting(false);
        if (err.response?.data.Error) {
            toast.error(err.response.data.Error)
        } else if (err.response?.data.data) {
            helpers && helpers.setErrors(err.response.data.data)
            toast.error(err.response.data.message)
        } else if (err.response?.data.message) {
            toast.error(err.response?.data.message)
        } else {
            toast.error(err.message)
        }
    }


    public formatDateAndTime(createdAt: string): string {
        const formattedDate = this.formatDate(createdAt);
        const formattedTime = this.formatTime(createdAt);
        return `${formattedDate} at ${formattedTime}`;
    }

    public formatDate(createdAt: string): string {
        const date = new Date(createdAt);
        const day = date.getDate();
        const month = this.getMonthAbbreviation(date.getMonth());
        const year = date.getFullYear();
        return `${day}${this.getDaySuffix(day)} ${month}, ${year}`;
    }

    private formatTime(createdAt: string): string {
        const date = new Date(createdAt);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const period = hours >= 12 ? "pm" : "am";
        const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    private getMonthAbbreviation(month: number): string {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return months[month];
    }


    public sortInstituteByDateTime(data: IInstitute[]): IInstitute[] {
        const sortedData = [...data];

        sortedData.sort((a, b) => {
            const dateTimeA = new Date(a.createdDate);
            const dateTimeB = new Date(b.createdDate);

            if (dateTimeA.toDateString() > dateTimeB.toDateString()) {
                return -1;
            } else if (dateTimeA.toDateString() < dateTimeB.toDateString()) {
                return 1;
            }

            if (dateTimeA.getHours() > dateTimeB.getHours()) {
                return -1;
            } else if (dateTimeA.getHours() < dateTimeB.getHours()) {
                return 1;
            }

            if (dateTimeA.getMinutes() > dateTimeB.getMinutes()) {
                return -1;
            } else if (dateTimeA.getMinutes() < dateTimeB.getMinutes()) {
                return 1;
            }

            return 0;
        });
        return sortedData;
    }

    public formatRole(role: string | undefined): string {
        if (role) {
            let formattedRole = role.replace(/_/g, " ");
            formattedRole = formattedRole.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
            return formattedRole;
        } else return "Undefined Role";
    }

    public formatAmount(amount: number): string {
        return new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: 'NGN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    }


    public toCamelCase = (label: string): string => {
        return label.toLowerCase().replace(/\s+(.)/g, (_, match) => match.toUpperCase());
    };

    public toSentenceCase = (label: string): string => {
        return label?.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    };


    public formatValueWithComma = (e: any) => {
        const numericValue = e?.target?.value?.replace(/\D+/g, '');
        return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    public formatValueWithTwoDecimalPlaces = (e: any) => {
       
        const numericValue = e?.target?.value?.replace(/\D+/g, '');
        if (!numericValue) return "";
    
        const fixedValue = parseFloat(numericValue).toFixed(2);
    
        return fixedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    public formatNumberWithCommas = (value: any) => {
        if (!value) return '';
        const cleanedValue = value?.target?.value?.replace(/,/g, ''); 
        return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    


    // public formatDateFromArray(dateArray: any): string {
    //     if (!dateArray) {
    //         return 'N/A';
    //     }
    //     if (dateArray.length < 3) {
    //         return "Invalid Date"
    //     }
    //     const formattedDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    //     const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return formattedDate.toLocaleDateString('en-US', options);
    // }

    public formatColleaguesDate(dateArray: any[]): string {
        if (!dateArray) return "N/A";
        if (dateArray.length < 7) return 'Invalid Date';

        const [year, month, day, hour, minute, second] = dateArray.slice(0, 6);
        const formattedDate = new Date(year, month - 1, day, hour, minute, second);

        const daySuffix = this.getDaySuffix(day);
        const monthName = formattedDate.toLocaleDateString('en-US', {month: 'short'});
        const formattedHour = hour < 10 ? `0${hour}` : hour;
        const formattedMinute = minute < 10 ? `0${minute}` : minute;
        const formattedSecond = second < 10 ? `0${second}` : second;

        return `${day}${daySuffix} ${monthName}, ${year} | ${formattedHour}:${formattedMinute}:${formattedSecond}`;
    }


    public formatDateFromArray(dateArray: any): string {
        if (!dateArray) {
            return 'N/A';
        }
        if (dateArray.length < 3) {
            return "Invalid Date"
        }

        const [year, month, day] = dateArray;
        const formattedDate = new Date(year, month - 1, day);

        const daySuffix = this.getDaySuffix(day);
        const monthName = formattedDate.toLocaleDateString('en-US', {month: 'short'});
        return `${day}${daySuffix} ${monthName}, ${year}`;
    }

    private getDaySuffix(day: number): string {
        if (day >= 11 && day <= 13) {
            return 'th';
        }
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    public formatInstituesData(data: { content: any[] | null | undefined }) {

        const cardImages = [image1, image2, image3, image4, image5, image6]
        const randomizeImage = () => {
            const randomIndex = Math.floor(Math.random() * cardImages.length);
            // Return the element at the randomly selected index
            return cardImages[randomIndex];
        }
        return {
            ...data,
            content: Array.isArray(data?.content) ? data.content.map(item => ({
                ...item,
                image: randomizeImage(),
            })) : ''
        };
    }

}


const utils = new Utils();
export default utils;
