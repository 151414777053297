import React from 'react';
import styles from "./styles/LoanProductModal.module.css";
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';


const  PartnershipDetail = () => {

    const router = useNavigate()

    const goToDashboard = () => {
        router('/view/loanees')
    }


    const details = [
        {
            field: 'Bank partner',
            value: 'Stanbic IBTC bank'
        },
        {
            field: 'Credit life insurance provider',
            value: 'Stanbic IBTC Credit Life Assurance'
        }, {
            field: 'Health insurance provider',
            value: 'AXA Mansard'
        }, {
            field: 'Accommodation provider',
            value: 'Eden Homes'
        }
    ]
    return (
        <div id={`Loan`} className={' rounded-md bg-[#FAFBFC] w-[150%] md:w-[53vw] h-[80%]'}>
            <div className={`w-[96%] pt-[1rem] md:mb-[1rem] mr-auto ml-auto ${styles.title} `}>Partnership details</div>
            <div className={` w-[99%] h-[75%]  mr-auto ml-auto ${styles.detailsScrollBar} `}>
                <div className={` md:w-[96%] h-[auto] mt-[1rem] mr-auto ml-auto pt-3 pr-2 pl-2 bg-white rounded-md    `}>
                    {
                        details?.map((detail) => (
                            <DetailsFields field={detail.field} value={detail.value} marginBottom={'4rem'}
                                           showTopDivider={false} showBottomDivider={true}/>
                        ))
                    }
                    <div className={` w-[fit-content] flex gap-3  mt-[2rem] pb-[1rem]`}>
                        <div className={`${styles.lineWord}`}>
                            View loan disbursement terms
                            <hr className={`${styles.line}`}/>
                        </div>
                    </div>
                </div>
                <div className={`hidden  md:flex place-content-end pr-4 pb-2  mt-[1rem]  `}>
                    <Button
                        onClick={goToDashboard}
                        id={"continueToLoanPartnershipFromProductDetails"}
                        variant={'contained'}
                        sx={{
                            color: 'white',
                            backgroundColor: '#0D9B48',
                            "&:hover": {backgroundColor: '#0D9B48', boxShadow: 0},
                            textTransform: 'none',
                            boxShadow: 0
                        }}
                    >Go to dashboard</Button>
                </div>
                <div className={`md:hidden mt-[1rem] px-2 `}>
                    <Button
                        fullWidth
                        onClick={goToDashboard}
                        id={"continueToLoanPartnershipFromProductDetails"}
                        variant={'contained'}
                        sx={{
                            color: 'white',
                            backgroundColor: '#0D9B48',
                            "&:hover": {backgroundColor: '#0D9B48', boxShadow: 0},
                            textTransform: 'none',
                            boxShadow: 0
                        }}
                    >Go to dashboard</Button>
                </div>
            </div>
        </div>
    );
};

export default PartnershipDetail;