import LayOut2 from "../../../../components/reusables/layout/LayOut2";
import styles from "../loanRequest/loanRequest.module.css";
import Divider from "@mui/material/Divider";
import SideSlider from "../reuseables/SideSlider";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import TraineeDetails from "../loanRequest/TraineeDetails";
import TraineeAdditionalDetails from "./TraineeAddtionalDetails";
import LoanDetails from "./LoanDetails";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import Details from "./Details";


type JsonValueKeys = 'Loan details' | 'Trainee details' | 'Trainee additional details' |string;

type JsonValueType = {
    [key in JsonValueKeys]: React.JSX.Element;
};


function DisbursalDetails () {

    const dispatch = useAppDispatch();
    const selected = useAppSelector((state)=> state.page.buttonName)
    const [jsonValue, setJsonValue] = useState<JsonValueType>({
        'Trainee details':<Details/>,
        'Trainee additional details': <TraineeAdditionalDetails/>,
        'Loan details': <LoanDetails/>,

    })
    const [slideValue, setSlideValue] = useState(Object.keys(jsonValue))

    useEffect(() => {
        dispatch(pageAction.loanRequestDetailSideBarButton( slideValue[0] as JsonValueKeys))
    }, []);


    return(
        <LayOut2>
            <div id={'mainDIv'} className={` grid grid-cols-1  place-items-center  h-full `}>
                <div id={'only'} className={`grid rounded bg-white w-[97%] h-[85vh]`}>
                    <div className={`w-[97%] h-[3rem]  mt-[1rem] mr-auto ml-auto grid mb-[2rem] gap-1`}>
                        <span className={`${styles.name} mt-auto mb-auto`}>Vanessa Oluchukwu</span>
                        <Divider orientation='horizontal' sx={{height: '30px', width: '99%'}}/>
                    </div>
                    <div className={`grid md:flex mt-auto mb-auto h-[70vh] w-[97%]  mr-auto ml-auto`}>
                        <div className={`w-[30%] `}>
                            <SideSlider id={'slider'} selected={selected} slideValue={slideValue}
                                        value={Object.keys(jsonValue).length}/>
                        </div>

                        <div id={'currentValueDisplayed'}
                             data-testid='currentValueDisplayed'
                             className={'w-[70%] h-[100%]  static  ml-44 '}>
                            {
                                jsonValue[selected]
                            }
                        </div>
                    </div>
                </div>
            </div>
        </LayOut2>
    )
}

export default DisbursalDetails