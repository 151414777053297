import React, {useState} from "react";
import Modal from "../../../../../components/Modal";
import {GrClose} from "react-icons/gr";
import {ILoanProductDetails} from "../../../../../types/loan/loanProduct.type";
import SecondModal from "./SecondModal";
import {Formik, Form} from 'formik';
import * as Yup from "yup";
import {nameRegExp, nameValidationMessage} from "../../../../../utils";
import InitialModal from "./InitialModal";
import InitialModalMobile from "./InitialModalMobile";
// import './CreateLoanProduct.css'


  



function CreateLoanProduct(props: ILoanProductDetails) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const currencyOptions = ['NGN', 'USD', 'GBP'];
  const [loanProductSponsor, setLoanProductSponsor] = useState('')
  const [selectedTenor, setSelectedTenor] = useState('');
  const tenorOptions = ['Years', 'Months', 'Days'];
  const [selectedMoratorium, setMoratorium] = useState('');

  const handleLoanProductSponsor = (loanSponsorType: string) => {
      setLoanProductSponsor(loanSponsorType);
  }


  


  
    const handleModalClose = () => {
        props.setOpenModal(false);
        resetDropdownStates();
    };
    const resetDropdownStates = () => {
    };
   
    

    return (
        <Modal
            isOpen={props.openModal}
            closeModal={() => props.setOpenModal(false)}
            
            >

            <div id={'wrappergjg'} 
            className={`   font-normal`}
            >
                <div id={'stylesTopjh'} className={`flex border border-b-[#91959c] mb-[20px] justify-between px-[25px] pt-[25px] pb-[45px] h-[60px]  md:w-full`}
               
                >
                    <h1 id={'headingWriteUpjhj'} className={`text-[20px] font-[#101828] font-medium`}>Create Loan Product</h1>
                    <div id={'CreateProductkjh'} onClick={props.closeModal} className={`cursor-pointer mt-2`}>
                        <GrClose id={'GrClosekgk'} onClick={handleModalClose}/>
                    </div>
                </div>
             
                        <InitialModal 
                        //  showStepNumber={true}
                        handleModalClose={handleModalClose}
                        />

                         <InitialModalMobile 
                        handleModalClose={handleModalClose}
                        />

                     
                
            </div>

        </Modal>
    );
}

export default CreateLoanProduct;
