import React, {useState, useEffect} from "react";
import * as Yup from "yup";
import {nameRegExp, nameValidationMessage} from "../../../../../utils";
import {ILoanProductRequest} from "../../../../../types/loan/loanProduct.type";
import loanProductService from "../../../../../services/loanProduct.service";
import {toast} from "react-toastify";
import utils from "../../../../../utils/utils";
import {Field, Form, Formik} from "formik";
import styles from "../styles/CreateLoanProduct.module.css";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import SecondModal from "./SecondModal";
import ViewAllLoanProductRequestDashBoard from "../ViewAllLoanProductRequestDashBoard";
import { snackBarActions } from "../../../../../redux/slices/snackbar";
import { store } from "../../../../../redux/store";
import { useAppSelector } from "../../../../../redux/hooks/hooks";
import CustomSelectedInput from "../../../../userManagement/reuseables/input/SelectInput/CustomSelectInput";
import "../styles/InitialModal.css"
import Select from 'react-select';

function convertToDays(value: number, unit: any) {
    switch (unit) {
        case 'Years':
            return value * 365; 
        case 'Months':
            return value * 30; 
        case 'Days':
            return value;
        default:
            return 0;
    }
}

 interface OptionType {
  value: string;
  label: string;
}

interface formProps {
    showStepNumber?: boolean;
    handleModalClose: () => void;
  }

const initialFormValue = {
    loanProductName: "",
    loanProductSize1: "",
    interestRate: "",
    tenor: "",
    moratorium: "",
    termsAndCondition: "",
    costOfFund: "",
    minimumRepaymentAmount:"",
    obligorLimit: "",
    loanProductMandate: "",
    fundProduct: "",
    loanProductSponsor: "",
    bankPartner: "",
  loanProductPartners: "",
  insuranceProviders: [],
  providerDetails: {
    creditLife: { provider: '', terms: '' },
    health: { provider: '', terms: '' },
    accommodation: { provider: '', terms: '' },
    device: { provider: '', terms: '' },
  },
  loanDisbursementTerms: '',
  selectedTenor: "",
 
  };

  const stepsArray = ["A", "B"];
  

  const sponsors = [
    { id: 1, name: "FCMB", balance: 20000000},
    { id: 2, name: "GTB", balance: 150000000 }
  ];
  


const InitialModal: React.FC<formProps> =({showStepNumber,handleModalClose})=>{
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('NGN');
    const [selectCurrency, setSelectCurrency] = useState('NGN');
    const currencyOptions = ['NGN', 'USD', 'GBP'].map(currency => ({ value: currency, label: currency }));
    const [loanProductSponsor, setLoanProductSponsor] = useState('')
    const [fundProduct, setFundProduct] = useState('')
    const [selectedTenor, setSelectedTenor] = useState('');
    const tenorOptions = ['Years', 'Months', 'Days'];
    const [selectedMoratorium, setMoratorium] = useState('');
    const [mouseOver,setMouseOver] = useState(false);
    const showSnackbar = useAppSelector((state) => state.snackbar.display);

    const handleLoanProductSponsor = (loanSponsorType: string) => {
        setLoanProductSponsor(loanSponsorType);
    }

    const handleFundProductChange = (fundProductType: string) => {
        setFundProduct(fundProductType);
    }

    const handleObligorLimitChange = (e: any, setFieldValue: any) => {
        const rawValue = e.target.value.replace(/,/g, '');
        setFieldValue('obligorLimit', rawValue);
    };
    
    const formatNumberWithCommas = (value: string) => {
        if (!value) return '';
        const cleanedValue = value.replace(/,/g, ''); 
        return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    
    const selectedFundBalance = sponsors.find(product => product.name === fundProduct)?.balance

    const validationSchema = Yup.object().shape({
            loanProductName: Yup.string().required("Loan Product Name is required").matches(nameRegExp, nameValidationMessage),
            loanProductSponsor: Yup.string().required("Loan product  Type is required"),
            loanProductSize1: Yup.string()
                .transform((originalValue) =>
                    originalValue?.replace(/,/g, '')?.trim())
                .test(
                    'is-positive-number',
                    'Loan product size must be a positive number',
                    (value) =>
                        typeof value === 'string' &&
                        !isNaN(parseFloat(value)) &&
                        parseFloat(value) > 0 &&
                        !value.startsWith('0'))
                .required("Loan Product Size is required"),
            obligorLimit: Yup.string()
                .transform((originalValue) =>
                    originalValue?.replace(/,/g, '')?.trim())
                .test(
                    'is-positive-number',
                    'Loan product size must be a positive number',
                    (value) =>
                        typeof value === 'string' &&
                        !isNaN(parseFloat(value)) &&
                        parseFloat(value) > 0 &&
                        !value.startsWith('0'))
                .required("Obligor loan limit is required")
                .test(
                    "obligorLoanLimit-vs-loanProductSize",
                    "Obligor Limit should be less than Loan Product Size",
                    function (value) {
                        const loanProductSizeValue = parseFloat(this.parent.loanProductSize1);
                        const obligorLoanLimitValue = parseFloat(value);
                        return (
                            !isNaN(loanProductSizeValue) &&
                            !isNaN(obligorLoanLimitValue) &&
                            obligorLoanLimitValue < loanProductSizeValue
                        );
                    }
                )
                .min(0, "Obligor Loan Limit must not be less than 0"),
            interestRate: Yup.number()
                .min(1, "Interest rate must not be less than 1")
                .max(100, "Cost of Interest rate must be greater than 100")
                .positive("Interest rate must be greater than 0")
                .required("Interest Rate is required")
                .test(
                    "maxDigitsAfterDecimal",
                    "Interest can take just 2 digits after decimal or less",
                    (value) => {
                        if (value === null || value === undefined) return true;
                        const decimalPattern = /^\d*(\.\d{0,2})?$/;
                        return decimalPattern.test(value.toString());
                    }
                ),
            minimumRepaymentAmount: Yup.string()
                .min(1, "Cost of fund must not be less than 1")
                .transform((originalValue) =>
                    originalValue?.replace(/,/g, '')?.trim())
                .test(
                    'is-positive-number',
                    'Loan product size must be a positive number',
                    (value) =>
                        typeof value === 'string' &&
                        !isNaN(parseFloat(value)) &&
                        parseFloat(value) > 0 &&
                        !value.startsWith('0'))
                .required("Minimum Repayment Amount is required")
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
        
            costOfFund: Yup.number()
                .min(1, "Cost of fund must not be less than 1")
                .max(100, "Cost of fund must be greater than 100")
                .positive("cost of fund must be greater than 0")
                .required("cost of fund is required")
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
              ,  
              loanProductMandate: Yup.string().required("Loan Product Mandate is required"),
            tenor: Yup.number()
                .required("Tenor is required")
                .min(0, "Tenor must not be less than 0")
                .positive("Tenor must be greater than 0")
                .when('selectedTenor', {
                    is: (selectedTenor: string) => selectedTenor === 'Years' || selectedTenor === 'Months' || selectedTenor === 'Days',
                    then: schema => {
                        if (selectedTenor === 'Years') {
                            return schema.min(1, "Minimum is 1 year").max(10, "Maximum is 10 years");
                        } else if (selectedTenor === 'Months') {
                            return schema.min(12, "Minimum is 12 months").max(120, "Maximum is 120 months");
                        } else if (selectedTenor === 'Days') {
                            return schema.min(365, "Minimum is 365 days").max(3650, "Maximum is 3650 days");
                        }
                        return schema;
                    }
                })
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
                
                moratorium: Yup.number()
                .min(1, "Moratorium must not be greater than 0")
                .max(24,"Cost of fund must be less than 24")
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
                .positive("Moratorium must be greater than 0")
                .required("Moratorium is required"),
                // .test(
                //     "tenor-vs-moratorium",
                //     "Moratorium should be less than Tenor",
                //     function (value) {
                //         const tenorValue = convertToDays(this.parent.tenor, selectedTenor);
                //         const moratoriumValue = convertToDays(value, selectedMoratorium);
                //         console.log("Tenor Value:", tenorValue);
                //         return (
                //             !isNaN(tenorValue) &&
                //             !isNaN(moratoriumValue) &&
                //             tenorValue >= moratoriumValue
                //         );
                //     }
                // ),

            termsAndCondition: Yup.string()
                .required("Terms and condition must be set")
                .max(2500, "Maximum amount of characters cannot exceed 2,500")
        }
    )
     
    

    const handleCurrencyChange = (option: any) => {
        setSelectedCurrency(option.value);
       
        
      };

    const handleCurrency = (option: any) => {
        setSelectCurrency(option.value);
      };
    

    const handleTenorChange = (tenor: string) => {
        setSelectedTenor(tenor);
    };

    const handleMoratoriumChange = (moratorium: string) => {
        setMoratorium(moratorium);
    };


    const [step, setSteps] = useState("A");

    const handleContinue = () => {
        if (step === "A") setSteps("B");
        
      };
    
      const handleBack = () => {
        if (step === "B") setSteps("A");
       
      };
       
      const renderTopStepNumbers = () => {
        if (!showStepNumber || step === "Final") {
          return null;
        }
        return (
          <section className="mt-2 mb-4 flex justify-between">
            {stepsArray.map((item) => (
              <div
                key={item}
                className={`w-8  h-8 justify-center items-center border-2 border-gray-600 rounded-full cursor-pointer flex ${
                  item === step ? "bg-blue-500" : ""
                } `}
                onClick={() => setSteps(item)}
              >
                {item}
              </div>
            ))}
          </section>
        );
      };

      let timeOut: any;

      function handleTimeOut () {
        timeOut = setTimeout(() => {
           store.dispatch(snackBarActions.updateSnackBar({type: 'OPEN', message: 'You have successfully created a loan product', success: true}))
        },500)
      }
      
      useEffect(() => {
        if(showSnackbar) {
            handleTimeOut();
        }
        return () => {
            clearTimeout(timeOut);
        };
      },[showSnackbar, timeOut])

    const handleSubmit = async (values: any) => {
       
        const tenorInDays = convertToDays(values.tenor, selectedTenor);
        setSteps("Final");
        handleModalClose()
      console.log("Submit Data",values, tenorInDays);
      handleTimeOut()



    };

   
    return (  <div id={'sdn'} className="">
        <Formik
            initialValues={{...initialFormValue, selectedTenor}}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount={true}
        >
            {({values, errors, isValid, touched, setFieldValue}) => (
                <Form>
                    {renderTopStepNumbers()}

                    { step === 'A' && <div className={`p-[10px_25px_15px_25px] hidden  md:block 
                         h-[calc(100vh-100px)] max-h-[70vh] overflow-y-auto 
                          md:h-auto md:max-h-none md:overflow-visible    
                   `}
                   
                   >
                 <div id={'outerDiv'}
                             className={`  md:grid md:grid-cols-2 md:gap-4`}
                        >
                            <div id={'leftSide'} 
                            className={' h-[60px]'}
                            >
                                <label id={'loanProduct'}>Loan Product Name</label>
                                <Field
                                    
                                    type="text"
                                    name="loanProductName"
                                    placeholder="Enter Product Name"
                                    style={{
                                        borderColor:
                                            errors.loanProductName && touched.loanProductName
                                                ? "red"
                                                : "inherit",
                                    }}
                                    className="border-2 border-[#d0d5dd] pl-2 w-full rounded-[4px] focus:outline-none"
                                />
                                {typeof errors.loanProductName && touched.loanProductName && (
                                    <div id={'loanProductNameError'}
                                         className={styles.error}>{errors.loanProductName}</div>
                                )}

                            </div>

                            <div id={'fieldDivdskjks'} className={`${styles.field}`}>
                                <label id={'sponsorndks'}>Loan Product Sponsor</label>
                                <div
                                    id={'dsnkjskdjsk;'}
                                    className={''}
                                    style={{
                                        borderColor: errors.loanProductSize1 && touched.loanProductSize1 ? "red" : "inherit",
                                    }}>
                                    <CustomSelectedInput
                                        options={["FCMB", "GTB"].map(sponsor => ({ value: sponsor, label: sponsor }))}
                                        value={loanProductSponsor}
                                        onChange={(value) => {
                                            handleLoanProductSponsor(value);
                                            setFieldValue("loanProductSponsor", value);
                                        }}
                                        placeholder="Select Loan Product Sponsor"
                                        />
                                </div>
                                 
                                {errors.loanProductSponsor && touched.loanProductSponsor && (
                                    <div id={"ckaskjjkll"}
                                         className={styles.error}>{errors.loanProductSponsor}</div>
                                )}
                            </div>

                            <div id={'fieldDivdstrskjks'} className={`${styles.field}`}>
                                <label id={'sponsosgfrndks'}>Fund Product </label>
                                <div
                                    id={'dsnkjskdjtrsk;'}
                                    className={''}
                                    style={{
                                        borderColor: errors.loanProductSize1 && touched.loanProductSize1 ? "red" : "inherit",
                                    }}>
                                   
                                     <CustomSelectedInput
                                        options={sponsors.map(sponsor => ({ value: sponsor.name, label: sponsor.name }))}
                                        value={fundProduct}
                                        onChange={(value) => {
                                            handleFundProductChange(value);
                                            setFieldValue("fundProduct", value);
                                        }}
                                        placeholder="Select Fund Product"
                                        />

                                </div>
                                <div>
                                    {
                                       fundProduct && (
                                        <div className="flex justify-between">
                                            <div className="text-[#72757A]">Remaing balance: </div>
                                            <div className="text-[#72757A]">
                                          {selectedFundBalance?.toLocaleString()} 
                                            </div>
                                           
                                        </div>
                                       ) 
                                    }
                                 </div>
                                {errors.fundProduct && touched.fundProduct && (
                                    <div id={"ckaskjjkltrl"}
                                         className={styles.error}>{errors.loanProductSize1}</div>
                                )}
                            </div>

                            <div id={'secondFirst'} className={''}>
                                <div id={'labelBottomDiv'} className={`${styles.tenorInput1}`}>
                                    <div className={'flex gap-3 items-center'}>
                                        <div className={''}>
                                            <label id={'MoratoriumLabel'} className={``}>Cost of funds
                                                (%)</label>
                                            <div id={'ternorFieldDiv'}
                                                //  className={'border border-[#D0D5DD] rounded w-36 '}
                                                >
                                                <Field
                                                    className={'w-[13.5rem] pl-2 bg-white focus:outline-none border border-[#D0D5DD] rounded '}
                                                    type="number"
                                                    name="costOfFund"
                                                    placeholder="0.00"
                                                    min={0}
                                                    max={25}
                                                    // disabled={!selectedMoratorium}
                                                />
                                            </div>
                                           
                                        </div>
                                         
                                        <div className={'ml-4'}>
                                            <label id={'MoratoriumLabel'}>Tenor</label>
                                            <div id={'ternorFieldDiv'} >
                                                <Field
                                                    className={'w-16 pl-2 bg-white border-[#D0D5DD] border rounded focus:outline-none '}
                                                    type="number"
                                                    name="tenor"
                                                    placeholder="0"
                                                    disabled={!selectedTenor}
                                                   
                                                />
                                            </div>
                                           
                                             
                                        </div>


                                        <div id={'currencyDropDownSelectDiv'}
                                             className={'mt-6 '}
                                             >
                                             <CustomSelectedInput
                                        options={tenorOptions.map(sponsor => ({ value: sponsor, label: sponsor }))}
                                        value={selectedTenor}
                                        onChange={(value) => {
                                            handleTenorChange(value);
                                            setFieldValue("selectedTenor", value);
                                        }}
                                        placeholder="Select.."
                                        customStyle=""
                                        />
                                        </div>
                                        
                                    </div>
                                    

                                </div>
                                <div className="flex gap-5 relative ">
                                {errors.costOfFund && touched.costOfFund && (
                                                <div id={'costOfFundError'}
                                                         className={'text-red-500 text-[0.8rem] w-36'}>{errors.costOfFund}</div>
                                            )}
                                {errors.tenor && touched.tenor && (
                                            <div id={"ckaskjjkltrl"}
                                                 className={`${styles.error} absolute right-28`}>{errors.tenor}</div>
                                        )}
                               
                                </div>
                               
                            </div>

                            <div className={'w-full'}>
                                <label id={'sponsosgfrndks'}>Loan product size </label>
                                <div className={`grid grid-cols-5 gap-2`}>
                                    <div id={'fieldDivdstrskjks'} className={`${styles.field} col-span-1`}>
                                        <div
                                            id={'dsnkjskdjtrsk;'}
                                            className={''}
                                            style={{
                                                borderColor: errors.loanProductSize1 && touched.loanProductSize1 ? "red" : "inherit",
                                            }}>
                                           
                                         <Select
                                            options={currencyOptions}
                                            value={currencyOptions.find(option => option.value === selectCurrency)}
                                            onChange={handleCurrency}
                                            styles={{
                                                option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor:'#fff',
                                                color: '#000',
                                                '&:hover': {
                                                    backgroundColor: '#E7F7ED',
                                                    color: '#0EAA4F',
                                                },
                                                }),
                                                indicatorSeparator: () => ({
                                                    display: 'none',
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    padding: '0 8px',
                                                    height: '2.5rem',
                                                    
                                                
                                                }),
                                                singleValue: (provided) => ({
                                            ...provided,
                                            padding: '0 3px', 
                                            }),
                                            dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: '0', 
                                            }),
                                                control: (provided,state) => ({
                                                ...provided,
                                                border: '1px solid #D0D5DD',
                                                borderRadius: '4px',
                                                height: '2.5rem',
                                                boxShadow: state.isFocused ? 'none' : provided.boxShadow, 
                                            borderColor: state.isFocused ? '#D0D5DD' : provided.borderColor, 
                                            '&:hover': {
                                                borderColor: '#D0D5DD', 
                                            },
                                                
                                                }),
                                            }}
                                        />
                         

                                       
                                        </div>
                                        
                                    </div>
                                    <div id={'just added'} className={'mr-9 w-full col-span-4 '}>
                                        <div id={'ternorFieldDiv'}
                                             className={'border border-none rounded '}>
                                            <Field
                                                className={`w-full pl-2 bg-white border border-[#D0D5DD] rounded ${!values.loanProductSize1 ? 'text-[#72757A]' : "text-black"} focus:outline-none`}
                                                type="text"
                                                name={`loanProductSize1`}
                                                placeholder="0.00"
                                                onChange={(e: any) => {
                    
                                                    setFieldValue("loanProductSize1",utils.formatValueWithComma(e));
                                                }}
                                                
                                            />
                                             {errors.loanProductSize1 && touched.loanProductSize1 && (
                                            <div id={"ckaskjjkltrl"}
                                                 className={styles.error}>{errors.loanProductSize1}</div>
                                        )}
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>


                            <div id={'fieldDivdskjktrs'} className={``}>
                                <div className={'flex'}>
                                    <div className={'mr-3 w-full '}>
                                        <label htmlFor="interestRate" id={'MoratoriumLabel'} className={``}>Interest (%)</label>
                                        <div id={'ternorFieldDiv'}
                                             className={'border border-none rounded '}>
                                            <Field
                                                className={'w-full pl-2 pb-0.5 bg-white border border-[#D0D5DD] rounded focus:outline-none'}
                                                type="number"
                                                name="interestRate"
                                                id='interestRate'
                                                placeholder="0.00"
                                                min={0}
                                                max={25}
                                                // disabled={!selectedMoratorium}
                                            />
                                        </div>
                                        {errors.interestRate && touched.interestRate && (
                                    <div id={"interestError"}
                                         className={styles.error}>{errors.interestRate}</div>
                                )}
                                    </div>

                                    <div className={' w-full'}>
                                        <label htmlFor="moratorium" id={'MoratoriumLabel'} className={``}>Moratorium (month) </label>
                                        <div id={'ternorFieldDiv'}
                                             className={'border border-none rounded'}>
                                            <Field
                                                className={'w-full pl-2 pb-0.5 bg-white border border-[#D0D5DD] rounded focus:outline-none'}
                                                type="number"
                                                name="moratorium"
                                                id='moratorium'
                                                placeholder="0.00"
                                                min={0}
                                                max={25}
                                                // disabled={!selectedMoratorium}
                                            />
                                        </div>
                                         {errors.moratorium && touched.moratorium && (
                                    <div id={"ckaskjjkll"}
                                         className={styles.error}>{errors.moratorium}</div>
                                )}
                                    </div>
                                </div>
                               
                            </div>

                            <div className={'w-full'}>
                                <label htmlFor="minimumRepaymentAmount" id={'sponsosgfrndks'}>Minimumum repayment amount </label>
                                <div className={`grid grid-cols-5 gap-2`}>
                                    <div id={'fieldDivdstrskjks'} className={`${styles.field} col-span-1`}>
                                        <div
                                            id={'dsnkjskdjtrsk;'}
                                            className={''}
                                            style={{
                                                borderColor: errors.minimumRepaymentAmount && touched.minimumRepaymentAmount ? "red" : "inherit",
                                            }}>
                                            {/* <select
                                                id={'currencyDropdownSelect '}
                                                className={" h-10 border-[#D0D5DD] border rounded bg-white w-16 "}
                                                value={selectedCurrency}
                                                aria-placeholder={'Select fund product'}
                                                onChange={(e) => handleCurrencyChange(e.target.value)}>
                                                {["NGN", "USD"].map((sponsor, index) => (
                                                    <option id={`${sponsor}option`} key={index}
                                                            value={sponsor}>
                                                        {sponsor}
                                                    </option>
                                                ))}
                                            </select> */}
                                               <Select
                                        options={currencyOptions}
                                        value={currencyOptions.find(option => option.value === selectedCurrency)}
                                        onChange={handleCurrencyChange}
                                        styles={{
                                            option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor:'#fff',
                                            color: '#000',
                                            '&:hover': {
                                                backgroundColor: '#E7F7ED',
                                                color: '#0EAA4F',
                                            },
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none',
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                padding: '0 8px',
                                                height: '2.5rem',
                                                
                                            
                                            }),
                                            singleValue: (provided) => ({
                                        ...provided,
                                        padding: '0 3px ', 
                                        }),
                                        dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '0 ', 
                                        marginLeft: ""
                                        }),
                                            control: (provided,state) => ({
                                            ...provided,
                                            border: '1px solid #D0D5DD',
                                            borderRadius: '4px',
                                            height: '2.5rem',
                                            boxShadow: state.isFocused ? 'none' : provided.boxShadow, 
                                        borderColor: state.isFocused ? '#D0D5DD' : provided.borderColor, 
                                        '&:hover': {
                                            borderColor: '#D0D5DD', 
                                        },
                                            }),
                                        }}
                                        />
                  
                                        </div>
                                        
                                    </div>
                                    <div id={'just added'} className={'mr-9 w-full col-span-4'}>
                                        <div id={'ternorFieldDiv'}
                                             className={'border border-none rounded '}>
                                            <Field
                                                className={'w-full pl-2 bg-white border border-[#D0D5DD] rounded focus:outline-none'}
                                                type="text"
                                                name="minimumRepaymentAmount"
                                                id="minimumRepaymentAmount"
                                                placeholder="0.00"
                                                onChange={(e: any) => {
                    
                                                    setFieldValue("minimumRepaymentAmount",utils.formatValueWithComma(e));
                                                }}
                                                
                                            />
                                        </div>
                                        {errors.minimumRepaymentAmount && touched.minimumRepaymentAmount && (
                                            <div id={"ckaskjjkltrl"}
                                                 className={styles.error}>{errors.minimumRepaymentAmount}</div>
                                        )}
                                    </div>

                                </div>
                            </div>


                            <div id={'fieldDivdskjktrs'} className={``}>
                                <div className={'flex'}>
                                    <div className={'w-full '}>
                                        <label htmlFor="obligorLimit" id={'MoratoriumLabel'} className={``}>Obligor limit
                                             </label>
                                        <div id={'ternorFieldDiv'}
                                             className={'border border-none rounded '}>
                                            <Field
                                                className={'w-[13.5rem] pl-2 pb-0.5 bg-white border border-[#D0D5DD] rounded focus:outline-none'}
                                                type="text"
                                                name="obligorLimit"
                                                id='obligorLimit'
                                                placeholder="0.00"
                                                onChange={(e: any) => {
                    
                                                    setFieldValue("obligorLimit",utils.formatValueWithComma(e));
                                                }}
                                                
                                            />
                                        </div>
                                    </div>

                                </div>
                                {errors?.obligorLimit && touched?.obligorLimit && (
                                    <div id={"obligorLimit"}
                                         className={styles.error}>{errors.obligorLimit}</div>
                                )}
                            </div>
                        </div>
                        <div className={`w-full`}>
                            <label htmlFor="loanProductMandate" id={'gdjftsy'}>Loan product mandate </label>
                            <Field
                               as="textarea"
                               name={`loanProductMandate`}
                               id="loanProductMandate"
                                className="h-28 p-2 w-full fon-[16px] border border-[#D0D5DD] rounded focus:outline-none resize-none"
                                placeholder="Enter product mandate"
                           />
                            {errors?.loanProductMandate && touched?.loanProductMandate && (
                                <div id={"obligorLimit"}
                                     className={styles.error}>{errors.loanProductMandate}</div>
                            )}
                        </div>
                        <div className={`w-full`}>
                            <label htmlFor="termsAndCondition" id={'loanTermAndConditionLabel'}>Loan terms and conditions</label>
                            <Field
                                as="textarea"
                                id='termsAndCondition'
                                name={`termsAndCondition`}
                                className="h-28 p-2 w-full fon-[16px] border border-[#D0D5DD] rounded focus:outline-none resize-none "
                                placeholder="Enter terms and conditions"
                            /> 
                            {errors?.termsAndCondition && touched?.termsAndCondition && (
                                <div id={"obligorLimit"}
                                     className={styles.error}>{errors.termsAndCondition}</div>
                            )}

                        </div>
                        <button  id={'submitButton'}
                                            // className={!isValid ?'bg-green-600 text-[white] w-[150px] h-[50px] rounded-md mt-4 px-1.5 opacity-40 cursor-not-allowed' :" bg-green-600 text-[white] w-[150px] h-[50px] rounded-md mt-4 px-1.5 "}
                                            // type="submit"
                                            className={`${
                                                !isValid ? 'bg-[#D0D5DD]  cursor-not-allowed' 
                                                        : 'bg-green-600'
                                              } ml-auto  block text-white rounded-md  h-[50px] mt-4 w-[100px] px-1.5 `}
                                            disabled={!isValid}
                                            onClick={handleContinue}
                                        >
                                            {isLoading ? (
                                                <div id={'loadingLoopIconDiv'} className="flex items-center justify-center">
                                                    <Icon id={'Icon'} icon={loadingLoop} width={24} height={24}/>
                                                </div>
                                            ) : (
                                                "Continue"
                                            )}
                                        </button>

                    </div>}
                    {step === 'B' && <SecondModal formValues={values} handleBack={handleBack} setFieldValue={setFieldValue}/>}
                    {/* {step === "Final" && <ViewAllLoanProductRequestDashBoard/>} */}

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Form>
            )}

        </Formik>
    </div>);

}

export default InitialModal

 {/* <select
                                                id={'selectedMoratoriumSelect'}
                                                className={`${styles.currencyDropdown} w-24 h-10 bg-white focus:outline-none  pl-2 border-[#D0D5DD] border rounded ${!values.tenor ? 'text-[#72757A]' : "text-black"}`}
                                                value={selectedTenor}
                                                onChange={(e) => {
                                                    handleTenorChange(e.target.value)
                                                    setFieldValue('tenor', e.target.value)
                                                }
                                                }>
                                                <option value={''} disabled selected>
                                                    Select...
                                                </option>
                                                {tenorOptions.map((tenor, index) => (
                                                    <option id={`${tenor}option`}
                                                            key={index}
                                                            value={tenor}>
                                                        {tenor}
                                                    </option>
                                                ))}
                                            </select> */}
                                             {/* <select
                                        id={'currencyDropdownSelect '}
                                        className={`w-full h-10 pl-2 border-[#D0D5DD] border rounded bg-white ${!values.fundProduct ? 'text-[#72757A]' : "text-black"} focus:outline-none`}
                                        value={fundProduct}
                                        aria-placeholder={'Select fund product'}
                                        onChange={(e) => 
                                            {handleFundProductChange(e.target.value);
                                                setFieldValue("fundProduct",e.target.value );
                                            }
                                        }
                                        >
                                             <option id={'disabledSelectOption'} value={''} disabled selected>
                                                    Select Fund Product
                                                </option>
                                        {["Work", "Launch"].map((sponsor, index) => (
                                            <option id={`${sponsor}option`} key={index}
                                                    value={sponsor}>
                                                {sponsor}
                                            </option>
                                        ))}
                                    </select> */}
                                     {/* <Field
                                       as='select'
                                        name={"loanProductSponsor"}
                                        id={'currencyDropdownSelect '}
                                        className={`w-full pl-2 h-10 border-[#D0D5DD] border rounded bg-white ${!values.loanProductSponsor ? 'text-[#72757A]' : "text-black"} focus:outline-none`}
                                        value={loanProductSponsor}
                                        onChange={(e:any) => 
                                            {handleLoanProductSponsor(e.target.value);
                                                setFieldValue("loanProductSponsor",e.target.value );
                                            }
                                        }
                                        
                                       >
                                        <option id={'disabledSelectOption'} value={''} disabled selected>
                                                    Select Loan Product Sponsor
                                                </option>
                                           
                                        {["FCMB", "GTB"].map((sponsor, index) => (
                                            <option className="hover:bg-black"  id={`${sponsor}option`} key={index}
                                                    value={sponsor}>
                                                {sponsor}
                                            </option>
                                        ))}
                                    </Field> */}
                                     // values.loanProductSize1 = values.loanProductSize1.replace(/,/g, '');
        // values.loanProductSize = values.loanProductSize1;
        // values.tenorStatus = selectedTenor;
        // values.moratoriumStatus = selectedMoratorium;
        // values.obligorLoanLimit1 = values.obligorLoanLimit1.replace(/,/g, '');
        // values.obligorLoanLimit = values.obligorLoanLimit1;

        // delete values.loanProductSize1;
        // delete values.obligorLoanLimit1;
        // const loanProductRequest: ILoanProductRequest = values;

        // setIsLoading(true);
        // try {
        //     const response = await loanProductService.createLoanProduct(loanProductRequest);
        //     if (response) {
        //         // handleModalClose();
        //         const data = {};
        //         toast.success("Loan Product Created Successfully!", {
        //             position: "top-right",
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //         try {
        //             await loanProductService.viewLoanProduct(data)
        //         } catch (error) {
        //             utils.handleRequestError(error)
        //         }
        //     }
        // } catch (error) {
        //     utils.handleRequestError(error)
        //     toast.error("Error Creating Loan Product!", {
        //         position: "top-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // } finally {
        //     setIsLoading(false);
        //     // handleModalClose();
        // // } {/* <select
        //                                         id={'currencyDropdownSelect '}
        //                                         className={" h-10 border-[#D0D5DD] border rounded focus:outline-none bg-white w-16"}
        //                                         value={selectedCurrency}
        //                                         // aria-placeholder={'Select fund product'}
        //                                         onChange={(e) => handleCurrencyChange(e.target.value)}>
        //                                         {["NGN", "USD"].map((sponsor, index) => (
        //                                             <option id={`${sponsor}option`} key={index}
        //                                                     value={sponsor}
        //                                                     className="customOption"
        //                                                     // style={{ backgroundColor: '#f5f5f5', color: 'black' }}
                                                            
        //                                                 //    onMouseOver={{ backgroundColor: '#e0e0e0', color: 'red' }}
        //                                                     // style={{ backgroundColor: "#f5f5f5", hover: { backgroundColor: "#e0e0e0" } }}
        //                                                     >
        //                                                 {sponsor}
        //                                             </option>
        //                                         ))}
        //                                     </select> */}
                                              {/* <CustomSelectedInput
                                        options={["NGN", "USD"].map(sponsor => ({ value: sponsor, label: sponsor }))}
                                        value={selectedCurrency}
                                        onChange={(value) => {
                                            handleCurrencyChange(value);
                                            // setFieldValue("loanProductSize1", value);
                                        }}
                                        // placeholder="Select.."
                                        customStyle="w-17"
                                        />  */}