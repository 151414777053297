import React from "react";
import {Link, useLocation} from "react-router-dom";
import styles from "./BreadCrumbs.module.css";


function BreadCrumbs() {
    const location = useLocation();

    let currentLink = "";

    const crumbs = location.pathname.split("/")
        .filter(crumb => crumb !== "")
        .map(crumb => {
            currentLink += `/${crumb}`

            return (
                <div id={`crumbDiv`} className={styles.crumb} key={crumb}>
                    <Link id={`currentLink`} className={`text-[#101828]`} to={currentLink}>{crumb}</Link>
                </div>
            )
        })

    return (
        <div id={`crumbs`} className={styles.breadcrumbs}>
            {crumbs}
        </div>
    );
}

export default BreadCrumbs;