import React from 'react'
import styles from "./loanRequest.module.css";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import {store} from "../../../../redux/store";





function TraineeDetails () {


    const details = [
        {
            id: '1',
            field: 'Email address',
            value: 'vanessa1333@gmail.com'
        },
        {
            id: '2',
            field: 'Phone number',
            value: '+2349039402842'
        },
        {
            id: '3',
            field: 'Gender',
            value: 'Female'
        },
        {
            id: '4',
            field: 'Date of Birth',
            value: '191 March, 1999'
        },
        {
            id: '5',
            field: 'Marital status',
            value: 'Single'
        },
        {
            id: '6',
            field: 'Nationality',
            value: 'Nigeria'
        },
        {
            id: '7',
            field: 'State of Birth',
            value: 'Imo'
        },

        {
            id: '8',
            field: 'Residential address',
            value: '372, Herbert Macaulay Way, Alagomeji, Sabo, Yaba'
        },
        {
            id: '9',
            field: 'Program name',
            value: 'Product design'
        },
        {
            id: '10',
            field: 'Cohort name',
            value: 'Gems'
        },

    ]


    const continues = () => {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Trainee additional details'))
    }

    return (
        <div id={`Loan`} className={' rounded-md bg-[#FAFBFC] w-[53vw] h-[100%]'}>
            <div className={`w-[96%] pt-[1rem] mb-[1rem] mr-auto ml-auto ${styles.loanDetail} `}>Trainee details</div>
            <div className={` w-[99%] h-[85%]  mr-auto ml-auto ${styles.traineeDetails} `}>
                <div className={` w-[96%] h-[auto] pt-2 grid mr-auto ml-auto gap-3 pr-2 pl-2 bg-white rounded-md    `}>
                    <div className={`flex place-content-between mb-[1rem] mt-[0.5rem]`}>
                        <div className={`flex gap-3 mt-[10px]`}>
                            <Avatar src={`https://i.pinimg.com/474x/d8/7e/f8/d87ef8cc1f1fb646bb2f7c8fc5b763c0.jpg`}
                                    sx={{width: 45, height: 45, backgoundColor: 'red'}}/>
                            <div className={`grid gap-2`}>
                                <span className={`${styles.traineeName}`}>Vanessa Chiamaka Oluchukwa</span>
                                <div className={`flex gap-2  `}>
                                    <Avatar
                                        src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxGM9vEmIwhmh_n8-8yP4ACNtma2a44rqycA&s`}
                                        sx={{width: 24, height: 24}}/>
                                    <span className={` mt-auto mb-auto ${styles.school}`}>School of Product, Yaba</span>
                                </div>
                            </div>
                        </div>
                        <div className={` mt-[10px] ${styles.creditScore} flex `}>
                            <span className={`${styles.creditScoreText}`}>Credit score</span>
                            <div className={`${styles.score}`}>
                                <div className={` pr-1 pl-1 pt-1 pb-1 ${styles.scoreText}`}>123</div>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-[1rem]`}>
                        {
                            details?.map((detail) => (
                                <DetailsFields field={detail.field} value={detail.value} marginBottom={'4rem'}
                                               showTopDivider={false} showBottomDivider={true}/>
                            ))
                        }
                    </div>
                </div>
                <div className={`flex place-content-end pr-4 pb-2  mt-[1rem]  `}>
                    <Button
                        onClick={continues}
                        id={"continueToTraineeAdditionalDetailsFromTraineeDetails"}
                        variant={'contained'}
                        sx={{
                            color: 'white',
                            boxShadow: 0,
                            backgroundColor: '#0D9B48',
                            "&:hover": {backgroundColor: '#0D9B48', boxShadow: 0},
                            textTransform: 'none'
                        }}
                    >Continue</Button>
                </div>
            </div>
        </div>

    )
}


export default TraineeDetails