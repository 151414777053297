import React from 'react';

import DisplayDetails from "../reuseables/DisplayDetails";
import CustomButton from "../reuseables/CustomButton";
import {dummyTraineeTopDetail} from "../dummyData/dummyData";
import utils from "../../../../utils/utils";
import CustomLinks from "../reuseables/CustomLinks";

const LoanDetails = () => {

    const loanDetail ={
        'Loan amount requested': utils.formatAmount(50000000),
        'Tuition amount': utils.formatAmount(55000000),
        'Initial deposit': utils.formatAmount(60000000),
    }


    const loanDetailsKeys = Object.keys(loanDetail);
    const loanDetailsValues = Object.values(loanDetail);

    const linkDetails = [
        {
            title: 'View tuition breakdown',
            link: '#',
            id: "dskfkjh",
        },
    ]
    return (
        <div id={'dskajd'} className={'bg-[#FAFBFC] w-[53vw] px-5 pt-4'}>
            <h2 id={'oweund'} className={'font-semibold text-[18px] text-[#101828]'}>Loan details</h2>

            <div id={'oweunc'} className={'bg-[#FFFFFF] mt-4 mb-3 pb-2'}>

                <DisplayDetails
                    id={'iytsedhj'}
                    details={dummyTraineeTopDetail}
                    detailsKeys={loanDetailsKeys}
                    detailsValues={loanDetailsValues}/>
                <CustomLinks linkDetails={linkDetails}/>
            </div>
            <CustomButton
                id={'wctrxwfghj'}
                buttonName={'Continue'}
                next={'Trainee details'}
                customStyle={'border rounded mb-3 px-6 py-3 ml-[41rem] bg-[#0D9B48] text-white'}/>

        </div>
    );
};

export default LoanDetails;