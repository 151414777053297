import React from 'react'
import styles from "../../../features/dashboard/loan/loanRequest/loanRequest.module.css";
import Divider from "@mui/material/Divider";



interface Props {
    field: string,
    value: string,
    showTopDivider: boolean,
    showBottomDivider: boolean,
    marginBottom: string,


}


function DetailsFields(props: Props){

    const {field, showBottomDivider,showTopDivider,value, marginBottom} = props


    return(
            <div id={'field'} className={`grid  w-[100%] gap-5 mb-[1rem]`}>
                {showTopDivider &&
                    <Divider orientation={`horizontal`}/>
                }
                <div className={`md:w-full grid grid-cols-1 gap-3 md:flex md:place-content-between mb-${marginBottom}`}>
                    <div className={`${styles.nameOf}`}>{field}</div>
                    <div className={`${styles.value}`}>{value}</div>
                </div>
                {showBottomDivider &&
                    <Divider orientation={`horizontal`}/>
                }
            </div>

    )
}


export default DetailsFields;

