import React from 'react';

const ArrowRight = () => {
    return (<svg id={'arIcon'} width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 8H19" stroke="#0D9B48" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 3.33325L19 7.99992L12 12.6666" stroke="#0D9B48" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>

    );
};

export default ArrowRight;