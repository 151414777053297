import React from 'react';
import traineeImage from '../dummyData/Ellipse 262.png'
export interface DetailsProps {
    details: {
        traineeImage: string;
        traineeName: string;
        schoolImage: string;
        schoolName: string;
    };
    id: string;
}
const ViewLoanDetailsTop: React.FC<DetailsProps> = ({details, id}) => {
        return(
            <div id={`${id}sonnjfnd`} className={'grid grid-cols-2'}>
                <div id={`${id}omocdsk`} className={'flex text-[#101828] pt-5 px-5 pb-2'}>

                    <img id={`${id}ncidnfd`} data-testid="traineeImage" src={details.traineeImage} alt={''}
                         className={'border border-none rounded-full mr-4 size-16 '}/>

                    <div id={`${id}nisndmk`}>
                        <h3 id={`${id}inidjn`} data-testid= "traineeName" className={'text-[#101828] text-[16px] font-[600] mb-2.5'}>{details.traineeName}</h3>
                        <div id={`${id}niandsd`} className={'flex'}>
                            <img id={`${id}ncisjnj`} data-testid="schoolImage" src={details.schoolImage} alt={''} className={'size-6 mr-1'}/>
                            <p id={`${id}mindjnnjd`} className={'text-[14px]'} data-testid= "schoolName">{details.schoolName}</p>
                        </div>
                    </div>
                </div>
                <div id={`${id}cjdnine`}
                    className={'border border-none rounded-xl flex h-8 ml-40 mt-6 justify-center w-40 py-1 bg-[#FAFBFC]'}>
                <p id={`${id}dclbhdheb`} className={`font-medium`}>Credit score</p>
                    <div className={`border grid items-center content-center rounded border-[#B4E5C8] ml-3`}>
                        <span id={`${id}cnoednj`} className={'bg-[#E7F7ED] border border-none rounded mx-1 my-1 px-1 text-[#0D9B48] font-semibold text-[13px]'}>670</span>
                    </div>
                </div>
            </div>
        )
};

export default ViewLoanDetailsTop;