import React from 'react';

interface WalletIconProps {
    colour: string;
}

const WalletIcon = ({colour}: WalletIconProps) => {
    return (
        <svg id={'WalletIcon'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.625 5.5H2.125M15.625 5.5C15.9234 5.5 16.2095 5.61853 16.4205 5.8295C16.6315 6.04048 16.75 6.32663 16.75 6.625V9.55M15.625 5.5L11.125 1M2.125 5.5C1.82663 5.5 1.54048 5.61853 1.32951 5.8295C1.11853 6.04048 1 6.32663 1 6.625V17.875C1 18.1734 1.11853 18.4595 1.32951 18.6705C1.54048 18.8815 1.82663 19 2.125 19H15.625C15.9234 19 16.2095 18.8815 16.4205 18.6705C16.6315 18.4595 16.75 18.1734 16.75 17.875V14.95M2.125 5.5L6.625 1L11.125 5.5M17.875 10H13.375C12.7783 10 12.206 10.2371 11.784 10.659C11.3621 11.081 11.125 11.6533 11.125 12.25C11.125 12.8467 11.3621 13.419 11.784 13.841C12.206 14.2629 12.7783 14.5 13.375 14.5H17.875C18.1734 14.5 18.4595 14.3815 18.6705 14.1705C18.8815 13.9595 19 13.6734 19 13.375V11.125C19 10.8266 18.8815 10.5405 18.6705 10.3295C18.4595 10.1185 18.1734 10 17.875 10Z"
                stroke={colour} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
}

export default WalletIcon;