import React from 'react';

interface RepaymentIconProps {
    colour: string;
}

const RepaymentIcon = ({colour}: RepaymentIconProps) => {
    return (
        <svg id={'RepaymentIcon'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 11L4.807 7.843C5.18228 7.42068 5.64276 7.08266 6.15812 6.85117C6.67348 6.61969 7.23204 6.50001 7.797 6.5H8M2 19.5H7.5L11.5 16.5C11.5 16.5 12.31 15.953 13.5 15C16 13 13.5 9.834 11 11.5C8.964 12.857 7 14 7 14"
                stroke={colour} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M8 13.5V7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5H20C20.5304 5 21.0391 5.21071 21.4142 5.58579C21.7893 5.96086 22 6.46957 22 7V13C22 13.5304 21.7893 14.0391 21.4142 14.4142C21.0391 14.7893 20.5304 15 20 15H13.5"
                stroke={colour} stroke-width="1.5"/>
            <path
                d="M19.5 10.01L19.51 9.999M10.5 10.01L10.51 9.999M15 12C14.4696 12 13.9609 11.7893 13.5858 11.4142C13.2107 11.0391 13 10.5304 13 10C13 9.46957 13.2107 8.96086 13.5858 8.58579C13.9609 8.21071 14.4696 8 15 8C15.5304 8 16.0391 8.21071 16.4142 8.58579C16.7893 8.96086 17 9.46957 17 10C17 10.5304 16.7893 11.0391 16.4142 11.4142C16.0391 11.7893 15.5304 12 15 12Z"
                stroke={colour} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default RepaymentIcon;
