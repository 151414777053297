import React from 'react'
import {store} from "../../../../redux/store";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import Button from "@mui/material/Button";
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import Avatar from "@mui/material/Avatar";
import styles from '../../loan/loanRequest/loanRequest.module.css'




function TraineeAdditionalDetails (){



    const details = [
        {
            id: '1',
            field: 'Alternative email address',
            value: 'vanessa@gmail.com'
        },
        {
            id: '2',
            field: 'Alternative phone number',
            value: '+2349039402842'
        },{
            id: '3',
            field: 'Alternative residential address',
            value: '372, Herbert Macaulay Way, Alagomeji, Sabo, Yaba'
        },{
            id: '4',
            field: 'Next of ken name',
            value: 'Michael oluchukwu'
        },{
            id: '5',
            field: 'Next of ken email address',
            value: 'michael@gmail.com'
        },{
            id: '6',
            field: 'Next of ken phone number',
            value: '+2347047382919'
        },{
            id: '7',
            field: 'Next of ken relationship',
            value: 'Brother'
        },

    ]

    function handleContinue() {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Loan details'))
    }

    function handleBack() {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Trainee details'))
    }

    return(
        <>
            <div className={`rounded-md bg-[#fafbfc] grid static  h-[100%]  w-[100%]   `}>
                <div className={` h-[5vh] ml-[1rem]   fixed ${styles.loanDetail}`}> Trainee additional
                    details
                </div>
                <div
                    className={` ${styles.traineeDetails}  bottom-6 h-[64%] ml-[1rem] w-[47%] bg-white fixed  mt-[1rem] mr-auto rounded-md `}>
                    <div className={`w-[97%] h-auto  mr-auto ml-auto `}>
                        <div className={`flex place-content-between `}>
                            <div className={`flex gap-3 mt-[10px]`}>
                                <Avatar src={`https://i.pinimg.com/474x/d8/7e/f8/d87ef8cc1f1fb646bb2f7c8fc5b763c0.jpg`}
                                        sx={{width: 45, height: 45, backgoundColor: 'red'}}/>
                                <div className={`grid gap-2`}>
                                    <span className={`${styles.traineeName}`}>Vanessa Chiamaka Oluchukwa</span>
                                    <div className={`flex gap-2  `}>
                                        <Avatar
                                            src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxGM9vEmIwhmh_n8-8yP4ACNtma2a44rqycA&s`}
                                            sx={{width: 24, height: 24}}/>
                                        <span
                                            className={` mt-auto mb-auto ${styles.school}`}>School of Product, Yaba</span>
                                    </div>
                                </div>
                            </div>
                            <div className={` mt-[10px] ${styles.creditScore} flex `}>
                                <span className={`${styles.creditScoreText}`}>Credit score</span>
                                <div className={`${styles.score}`}>
                                    <div className={` pr-1 pl-1 place-self-center ${styles.scoreText}`}>123</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-[2rem]  w-[93%]   mr-auto ml-auto object-fit`}>
                        {details?.map((detail, index) => (
                            <DetailsFields field={detail?.field} value={detail?.value} showTopDivider={false} showBottomDivider={true} marginBottom={'2rem'}/>

                        ))}
                    </div>
                    <div
                        className={`grid  mb-[1rem] mr-auto ml-auto h-[auto]  w-[100%]  mt-[2rem] gap-5 place-content-end`}>
                        <div className={`w-[auto] mr-[1rem] h-[auto] flex gap-3 mb-[2rem]`}>
                            <Button
                                onClick={handleBack}
                                id="back"
                                variant='outlined'
                                sx={{
                                    boxShadow: 0,
                                    width: '3rem',
                                    textTransform: 'none',
                                    '&:hover': {borderColor: '#0D9B48'},
                                    color: '#0D9B48',
                                    backgroundColor: 'white',
                                    borderColor: '#0D9B48'
                                }}>
                                Back</Button>
                            <Button
                                onClick={handleContinue}
                                id="continueToLoanDetails"
                                variant='contained'
                                sx={{
                                    boxShadow: 0,
                                    width: '5rem',
                                    textTransform: 'none',
                                    '&:hover': {borderColor: '#0D9B48', backgroundColor: '#0D9B48', boxShadow: 0},
                                    color: 'white',
                                    backgroundColor: '#0D9B48',
                                    borderColor: '#0D9B48'
                                }}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

export default TraineeAdditionalDetails