import React from 'react';
import TraineeNameAndRoleDisplay from "./TraineeNameAndRoleDisplay";
import {ChevronIcon} from "./icon";

const TraineeProfile = () => {
    return (<main id="traineeProfileMain" className={'flex gap-1 md:w-[13.75rem] justify-between items-center'}>
            <div id="profileContainer" className={'flex gap-[9px]'}>
                <div id="profileIconContainer"
                     className="w-10 h-10 rounded-full bg-LearnSpaceMintGreen flex items-center justify-center">
            <span id="profileInitials" className="text-[#29804B] text-base font-normal leading-[120%]">
                VC
            </span>
                </div>
                <TraineeNameAndRoleDisplay/>
            </div>
            <ChevronIcon/>
        </main>);
};

export default TraineeProfile;