import {useAppSelector} from "../../../../redux/hooks/hooks";
import React, {useEffect} from 'react'
import {snackBarActions} from "../../../../redux/slices/snackbar";
import {store} from "../../../../redux/store";
import {useDispatch} from "react-redux";
import {Alert, Snackbar} from "@mui/joy";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';

export default function SnackBar(){

    const snackBarMessage = useAppSelector((state) => state.snackbar.message);
    const snackBarStatus = useAppSelector((state) => state.snackbar.success);
    const showSnackbar = useAppSelector((state) => state.snackbar.display);
    const dispatch = useDispatch();
    const [breakWord, setBreakWord] = React.useState(false)


    const [isOnline, setIsOnline] = React.useState(navigator.onLine);
    const [status , setStatus] = React.useState('')

    useEffect(() => {
        if(snackBarStatus) {
            setStatus('success')
        }else{setStatus('error')}
    }, [snackBarStatus])
    useEffect(() =>{

        function  deviceConnectionStatus () {
            setIsOnline(navigator.onLine)
        }

        window.addEventListener('online', deviceConnectionStatus)
        window.addEventListener('offline', deviceConnectionStatus)


        return () => {
            window.addEventListener('online', deviceConnectionStatus)
            window.addEventListener('offline', deviceConnectionStatus)
        }
    }, [])
    useEffect(() => {
        if (snackBarMessage.length > 46){
            setBreakWord(true)
        }

    }, [snackBarMessage]);

    useEffect(() => {
        if(!isOnline){
            store.dispatch(snackBarActions.updateSnackBar({type: 'OPEN', message: 'No internet connection', success: false}))
        }else{
            store.dispatch(snackBarActions.updateSnackBar({type: 'OPEN', message: 'You are back online', success: true}))
        }
    }, [isOnline, dispatch]);

    let TIMER : any;

    function handleTimeOut () {
        TIMER = setTimeout(() => {
            store.dispatch(snackBarActions.updateSnackBar({type: "CLOSE"}))
        }, 3000)
    }
    useEffect(() => {
        if (showSnackbar) {
            handleTimeOut();
        }
        return () => {
            clearTimeout(TIMER);
        };
    }, [showSnackbar, TIMER]);


    function handleClose () {
        clearTimeout(TIMER)
        store.dispatch(snackBarActions.updateSnackBar({type: "CLOSE"}))
    }



    return(
        <>
            <Snackbar
                id={`snackbar`}
                anchorOrigin={{vertical:'top', horizontal:'center'}}
                open={showSnackbar}
                autoHideDuration={4000}
                sx={{ display: 'flex ',justifyContent: 'space-between', width: 'auto', gap: 1, paddingTop: 0, paddingBottom : 0,color : `${snackBarStatus ?  '#0D9B48' : '#E80000'}`, borderColor: `${snackBarStatus ?  '#0D9B48' : '#E80000'}`, height: 'auto',  backgroundColor: `${snackBarStatus ? '#E7F7ED' : '#FFE6E6'}`}}
                onClose={handleClose}
            >
                <div className={`flex gap-2 h-[auto] place-content-center `}>
                    {snackBarStatus ?
                        <CheckCircleIcon id={'checkIcon'} sx={{marginTop: 'auto', marginBottom: 'auto', height: 19,width: 19 }}/>
                        :
                        <CancelIcon id={'cancelIcon'} sx={{marginTop: 'auto', marginBottom: 'auto',height: 19,width: 19}}/>
                    }
                    <Alert
                        id={'message'}
                        sx={{ width: `${breakWord ? '18rem' : 'auto'}` , height: 'auto',wordBreak: 'break-word', backgroundColor: `${snackBarStatus ? '#E7F7ED' : '#FFE6E6'}`, borderColor: `${snackBarStatus ?  '#0D9B48' : '#0D9B48'}`, color: `${snackBarStatus ? '#0d9b48' : '#d32f2f'}`,fontSize: '14px', fontFamily:'IBM Plex Sans',}}
                    >
                        {snackBarMessage}
                    </Alert>
                </div>
                <ClearIcon id={'closeSnackbar'}  onClick={handleClose} sx={{height: 19,width: 19}} />
            </Snackbar>
        </>
    )



}