import {string} from "yup";


export  function capitalizeFirstLetters  (word: undefined | string ) {

    if (word) {
        return word
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1) + ' ')
    }

}


export  function  getFirstLetterOfWord (word : undefined | string) {
    let ans = '';

    if(word){
        let capitalizedFirstWords  =
            word
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1) )
        // @ts-ignore
        capitalizedFirstWords.map(letter => {
            Array.from(letter).forEach(elem => {
                if(elem === elem.toUpperCase()){
                   ans = ans.concat(elem)
                }
            })
        })
        return ans;
    }
}

