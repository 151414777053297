import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TableData} from "../../types/commons.type";
import {ILoanProductDetails} from "../../types/loan/loanProduct.type";
import {PURGE} from "redux-persist";

interface LoanProductState {
    loanProduct? : TableData<ILoanProductDetails> | undefined;
    loanProductDetailsCurrentState: string;
}

const initialState: LoanProductState ={
    loanProductDetailsCurrentState: 'Product details',


};
const loanProductSlice = createSlice({
    name: 'loanProduct',
    initialState,
    reducers: {
        setLoanProduct: (state, action: PayloadAction<any>) => {
            state.loanProduct = action.payload;
        },
        setLoanProductDetailsCurrentState : (state, action: PayloadAction<any>) => {
            state.loanProductDetailsCurrentState = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.loanProduct = undefined;
            state.loanProductDetailsCurrentState = '';

        });
    },
});

export const loanProductActions = loanProductSlice.actions;

export default loanProductSlice.reducer;
