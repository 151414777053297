import React, {FC} from "react";
import ReactModal from "react-modal";
import styles from "./styles.module.css";


interface IProps {
    isOpen?: any;
    closeModal?: () => void;
    className?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    closeOnOverlayClick?: boolean;
}

ReactModal.setAppElement("body");

const Modal: FC<IProps> = ({isOpen, children, closeModal, closeOnOverlayClick}) => {
    return (
        <ReactModal
            id={'ReactModalChildren'}
            isOpen={isOpen}
            className={`${styles.body} md:block sm:w-6`}
            overlayClassName={styles.overlay}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            
        >
            {children}
        </ReactModal>
    );
};

export default Modal;
