import React, {Dispatch, SetStateAction, useState} from 'react';
import styles from "./styles/TopBar.module.css";
import {GiHamburgerMenu} from "react-icons/gi";
import {useAppSelector} from "../../../redux/hooks/hooks";
import {IoIosArrowDown} from "react-icons/io";
import utils from "../../../utils/utils";
import ProfileDropdown from "./ProfileDropdown";
import Notifications from "./Notifications";
import SideNavButton from "./SideNavButton";
import Logout from "../../../features/userManagement/auth/logout/Logout";
import BreadCrumbs from "../../breadCrumb/BreadCrumbs";
import {FiBell} from 'react-icons/fi';

interface IProps {
    setMobileOpen?: any;
    setSelectedName?: Dispatch<SetStateAction<string | null>>;
}

function TopBar({setMobileOpen, setSelectedName}: IProps) {
    const {user} = useAppSelector((state) => state.auth);


    const fullName: string | undefined = user?.name;
    const patten = /^[A-Z_]+$/
    //@ts-ignore
    const filteredRoles = user?.realm_access?.roles?.filter(v => patten.test(v))

    const userRole = filteredRoles?.length ? filteredRoles[0] : undefined;


    const [arrowToggled, setArrowToggled] = useState(false);
    const [bellClicked, setBellClicked] = useState(false);
    const [hamburger, setHamburger] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const toggleArrow = () => {
        console.log(arrowToggled + "The arrow is toggled... ")
        setArrowToggled(!arrowToggled);
    };

    // famaxo7454@aersm.com

    const clickBell = () => {
        setBellClicked(!bellClicked);
    };

    const clickHamburger = () => {
        setHamburger(!hamburger);
    };

    const handleLogout = () => {
        setOpenModal(true);
        setArrowToggled(false);
    };

    return (
        <>
            <div className={styles.topNavigation}>
                <div></div>
                <div className={`justify-self-start cursor-pointer  font-medium`}>
                    <BreadCrumbs/>
                </div>
                <div id={'rightTopNav'} className={styles.rightTopNav}>
                    <div id={'bellDiv'}>
                        <button className={`mr-4`}
                            // onClick={clickBell}
                        >
                            <FiBell id={'FiBell'} className={`text-[27px] mt-1 `}/>
                        </button>
                        {bellClicked && <Notifications close={clickBell}/>}
                    </div>

                    <div
                        className={`grid place-items-center cursor-pointer border rounded-full h-12 w-12 bg-[#e0fdeb] m-5`}>
                        <span>{fullName && fullName.charAt(0)}</span></div>
                    <div id={'buttonsDiv'} className={`relative`}>
                        <button id={'clickHamburger'} onClick={clickHamburger} className={styles.hamburger}>
                            <GiHamburgerMenu id={'clickHamburgerGiHamburgerMenu'}/>
                        </button>
                        {hamburger && <div id={'mobileTopNav'} className={styles.mobileTopNav}><SideNavButton/></div>}
                    </div>

                    <div id={'rightTopNavText'} className={styles.rightTopNavText}>
                        <div id={'fullNameDiv'} className="flex justify-between items-center w-[180px]">
                            <div>
                                <p id={'fullNamePTag'} className={styles.fullName}>{fullName}</p>
                                <p id={'formatRoleUserRole'} className={`font-[14px]`}>{utils.formatRole(userRole)}</p>
                            </div>
                            <div id={'toggleArrowDiv'} className={`pt-3 ml-1 cursor-pointer`}>
                                <button id={'toggleArrowButton'} onClick={toggleArrow}>
                                    <IoIosArrowDown id={'IoIosArrowDown'}/>
                                </button>
                                {arrowToggled && <ProfileDropdown onLogoutClick={handleLogout} close={toggleArrow}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`h-[100px] relative ${styles.space}`}/>
            {/*<Logout showOpenModal={openModal} setShowOpenModal={setOpenModal}/>*/}

        </>
    );
}

export default TopBar;
