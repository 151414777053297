import React from 'react';
import { Link } from 'react-router-dom';
import learnSpaceLogo from '../../../../assets/image/learnspaceLogo.svg';
import { HelpIcon, HomeIcon, LogoutIcon, RepaymentIcon, SettingsIcon, WalletIcon } from './icon';
import useActiveIndex from '../../../../hooks/useActiveIndex';

const navItems = [
    { label: 'Overview', href: '/', icon: HomeIcon },
    { label: 'Wallet', href: 'wallet', icon: WalletIcon },
    { label: 'Repayment', href: 'repayment', icon: RepaymentIcon }
];

const navItemsSecondary = [
    { label: 'Settings', href: '#', icon: <SettingsIcon /> },
    { label: 'Help & Support', href: '#', icon: <HelpIcon /> },
    { label: 'Logout', href: '#', icon: <LogoutIcon /> }
];

const NavigationSidebar = () => {
    const { activeIndex, getIconColor } = useActiveIndex();

    return (
        <aside id="navigationSidebar" className="bg-learnSpaceWhite w-[16.625rem] border-r border-gray-300 py-8 px-5 justify-between hidden md:grid">
            <div id="SidebarContainer" className={'flex flex-col h-[15.375rem] flex-grow gap-[60px] w-[14.125rem]'}>
                <img id="navigationSidebarLearnSpaceLogoTr" src={learnSpaceLogo} alt="Learnspace logo" className="w-[145px] h-[30px]" />
                <nav id="mainNavTr">
                    <ul id="navListTr" className="space-y-3">
                        {navItems.map((item, index) => (
                            <li id={`navItemTr${index}`} key={index}>
                                <Link
                                    to={item.href}
                                    className={`flex items-start justify-start h-[2.75rem] text-base leading-[120%] p-3 gap-2 rounded w-full ${activeIndex === index ? 'bg-[#E7F7ED] text-[#0D9B48] font-medium' : 'bg-transparent text-[#3B3B3B] font-normal'}`}
                                >
                                    <item.icon colour={getIconColor(index)} />
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <footer id="footerNavTr" className={'place-content-end'}>
                <ul id="secondaryNavListTr" className="space-y-3">
                    {navItemsSecondary.map((item, index) => (
                        <li id={`secondaryNavItemTr${index}`} key={index}>
                            <a
                                id={`secondaryNavItemTrLink${index}`}
                                href={item.href}
                                className={`flex h-[2.75rem] p-3 gap-2 rounded w-full bg-transparent text-[#72757A] text-base font-normal leading-[120%]`}
                            >
                                {item.icon}
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </footer>
        </aside>
    );
};

export default NavigationSidebar;