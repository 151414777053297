import React from 'react'
import LayOut2 from "../../../../components/reusables/layout/LayOut2";
import {InputAdornment, TextField} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {loanOfferDummyData, loanProductData} from "../../../../components/reusables/table/MockData";
import LoanEmptyState from "../../../../components/reusables/emptyState/LoanEmptyState";
import DynamicTable from "../../../../components/reusables/table/DynamicTable";
import ProductTable from "./Table";
import {useNavigate} from "react-router-dom";
import CreateLoanProduct from './createLoanProduct/CreateLoanProduct';


function ViewAllLoanProduct() {

    const navigate = useNavigate()

    const handleRowClick = () => {
        navigate('/view/loanProductDetail?&view=true')
    }

    const [openModal, setOpenModal] = React.useState(false);
    const skeletonLoader = false;
    const handleCloseCreateModal = () => {
        setOpenModal(false);
    };

    const columns = [
        {
            title: 'Loan product name',
            sortable: true,
            id: 'loanProductName'
        },
        {
            title: 'Loan product sponsor',
            sortable: true,
            id: 'loanProductSponsor'
        },
        {
            title: 'Tenor',
            sortable: true,
            id: 'tenor'
        },
        {
            title: 'Interest rate (%)',
            sortable: true,
            id: "interestRate"
        },{
            title: 'Cost of funds',
            sortable: true,
            id: 'costOfFund'
        },{
            title: 'No. of loanees',
            sortable: true,
            id: 'noOfLoan'
        },{
            title: 'Amount disbursed',
            sortable: true,
            id: 'AmountDisbursed'
        },{
            title: 'Amount repaid',
            sortable: true,
            id: 'AmountRepaid'
        },
        {
            title: 'Amount earned',
            sortable: true,
            id: 'AmountEarned'
        },
    ]


    return(
        <LayOut2>
            <div className={`grid grid-cols-1  place-items-center  h-full  `}>
                <div className={`grid rounded bg-white w-[97%] h-[85vh] `}>
                    <div className={`mr-auto ml-auto w-[96%] place-content-end gap-2   md:flex`}>
                        <div className={`md:w-[30%] mt-[1rem] w-[41%] `}>
                            <TextField
                                size='small'
                                placeholder='Search'
                                tabIndex={2}
                                // onChange={(e) => handleSearch(e.target.value)}
                                id={'searchLoanProduct'}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined fontSize='small' />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                               
                            />
                        </div>
                        <Button
                        className=''
                        onClick={() => setOpenModal(true)}
                            sx={{backgroundColor: '#0D9B48', color: 'white',height: '2.35rem',
                             "&:hover" : { backgroundColor:'#0D9B48', color: 'white' },
                            marginTop: '1rem',
                            textTransform: 'none', 
                            "@media (max-width: 800px)": {
                                width: '41%', 
                              },
                        }}
                        >
                            Create loan product
                        </Button>
                    </div>
                    
                    <div  className={`w-[96%] bg-purple-300 mt-[1rem] h-[82%] mr-auto ml-auto`}>
                        <ProductTable tableData={loanProductData}  handleRowClick={handleRowClick} tableHeader={columns} tableHeight={59} sx='cursor-pointer'/>
                    </div>
                </div>
            </div>
            {
                 <CreateLoanProduct
                 openModal={openModal}
                 setOpenModal={handleCloseCreateModal}
                 title='Create Loan Product'
                //  fetchLoanProductData={fetchLoanProductData}
                 />
            }
        </LayOut2>
    )
}

export default ViewAllLoanProduct
