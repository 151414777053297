import React from 'react';
import HourGlassIcon from "../../../../components/icons/TraineeDashboard/HourGlassIcon";
import TopBar from "../../../../components/reusables/layout/TopBar";
import FAQ from "./FAQ";

function TraineeDashboard() {
    return (
        <div id={`cjsdfkfd`} className={``}>
            <TopBar/>
            <div id={`cdjfdkds`} className={`mx-auto mt-[60px] border-2 rounded-[8px] md:w-[45%] w-[90%] min-h-[294px] bg-[#fff] `}>
                <div id={`cbsjdinjdjkvnire`} className={`flex flex-col items-center pt-[62px] px-[30px] pb-5 gap-4`}>
                    <HourGlassIcon/>
                    <p id={`jdksvnlsdfnjmc`} className={`text-[18px] text-center font-[600]`}>
                        Your loan application is still in review
                    </p>
                    <p id={`dknsdknk`} className={`text-[14px] text-center`}>
                        Your loan is still in review, and we're diligently processing every detail.
                        Hang tight, we're getting closer to making your career goals a reality.
                        We appreciate your patience and will keep you updated every step of the way.
                    </p>
                </div>
            </div>
            <div id={`djksnv`} className={`mx-auto my-[30px] border-2 rounded-[8px] md:w-[45%] w-[90%] min-h-[294px] bg-[#fff]`}>
                <div id={`snvjkdkslkvjf`} className={`px-[28px] py-[50px] bg-[#FAFBFC]`}>
                    <h2 id={`ncjdk;akd`} className={`font-[500] text-[18px]`}>Frequently Asked Questions</h2>
                </div>
                <FAQ/>
            </div>
        </div>
    );
}

export default TraineeDashboard;