import React from 'react';

const TraineeNameAndRoleDisplay = () => {
    return (
        <div id={'TraineeNameAndRoleDisplay'} className="gap-1 hidden md:grid">
            <h1 className="text-[14px] font-medium leading-[120%] text-[var(--Default-colors-Body,#101828)] ]">Vanessa Oluchukwu</h1>
            <p className="text-[12px] font-normal leading-[120%] text-[var(--Foundation-Blue-blue-800,#72757A)]">Trainee</p>
        </div>
    );
};

export default TraineeNameAndRoleDisplay;