import React from 'react'
import LayOut2 from "../../../../components/reusables/layout/LayOut2";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styles from "./styles/LoanProductModal.module.css";
import {LuUsers} from "react-icons/lu";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {InputAdornment, TextField} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";
import { loneesData } from '../../../../components/reusables/table/MockData';
import ProductTable from './Table';

function Loanees () {

    const navigate = useNavigate()

    const handleNavigate = () => {

    }
    const columns = [
        {
            title: 'Trainee',
            sortable: true,
            id: 'trainee'
        },
        {
            title: 'Institution',
            sortable: true,
            id: 'institution'
        },
        {
            title: 'Amount taken',
            sortable: true,
            id: 'amountTaken'
        },
        {
            title: 'Last payment date',
            sortable: true,
            id: "lastPaymentDate"
        },{
            title: 'Outstanding balance',
            sortable: true,
            id: 'outstandingBalance'
        }
    ]


    return(
        <LayOut2>
          <div className={`grid grid-cols-1  place-items-center h-full  `}>
              <div className={`grid gap-1 rounded  w-[97%] bg-white h-[100%] mt-10`}>
                  <div className={`grid gap-3  `}>
                      <div className={` h-[3rem] w-[98%] mr-auto ml-auto `}>
                          <Button
                              onClick={() => {
                                  navigate('/view/loanProductDetail?&view=true')
                              }}
                              id="backToViewloanProductDetailsFromLoanees"
                              startIcon={<ChevronLeftIcon sx={{color: '#94979D'}}/>}
                              sx={{
                                  color: '#94979D',
                                  textTransform: 'none',
                                  marginTop: '1rem',
                                  "&:hover": {backgroundColor: 'white'}
                              }}
                          >Back</Button>
                          <div
                              className={' mt-[0.5rem] gric w-[98%] mr-auto ml-auto'}>
                              <div className={`mt-auto mb-auto ${styles.title}`}>Loanees</div>
                              <Divider
                                  sx={{marginTop: '1rem', width: '100%', marginRight: 'auto ', marginLeft: 'auto'}}/>
                                   <div className={` mt-6`}>
                      <TextField
                          size='small'
                          placeholder='Search'
                          tabIndex={2}
                          // onChange={(e) => handleSearch(e.target.value)}
                          id={'searchInstitution'}
                          className='md:w-[30%] w-full'
                          InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                      <SearchOutlined fontSize='small'/>
                                  </InputAdornment>
                              ),
                          }}
                          variant="outlined"
                      />
                      
                      <div className={`hidden md:block w-[96%] bg-purple-300 mt-[1rem] h-[82%] `}>
                        <ProductTable 
                        tableData={loneesData}
                        tableHeader={columns} 
                        tableHeight={59}
                        handleRowClick={handleNavigate}
                        tableStyle='hover:bg-white'
                        />
                      </div>
                      
                  </div>
                          </div>
                      </div>
                      
                  </div>
                 
                  
                 
              </div>
          </div>
        </LayOut2>
    )
}

export default Loanees