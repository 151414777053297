import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFundProductData} from "../../features/dashboard/fundProduct/fundProductTable";
import {TableData} from "../../types/commons.type";
import {PURGE} from "redux-persist";


interface fundState {
    // fund?: TableData<IFundProductData>;
    fund?: any;
}

const initialState: fundState = {


};

const fundProductSlice = createSlice({
    name: 'fundProduct',
    initialState,
    reducers: {
        setFundProduct: (state, action: PayloadAction<any>) => {
            state.fund = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.fund = undefined;
        });
    },
})

export const fundProductActions = fundProductSlice.actions;

export default fundProductSlice.reducer;






