import React, { useState, useEffect, useRef } from 'react';
import learnSpaceLogo from "../../../../assets/image/learnspaceLogo.svg";
import { Link } from "react-router-dom";
import { HelpIcon, HomeIcon, LogoutIcon, RepaymentIcon, SettingsIcon, WalletIcon } from "./icon";
import useActiveIndex from '../../../../hooks/useActiveIndex';

const navItems = [
    { label: 'Overview', href: '/', icon: HomeIcon },
    { label: 'Wallet', href: 'wallet', icon: WalletIcon },
    { label: 'Repayment', href: 'repayment', icon: RepaymentIcon },
    { label: 'Settings', href: '#', icon: SettingsIcon },
    { label: 'Help & Support', href: '#', icon: HelpIcon },
    { label: 'Logout', href: '#', icon: LogoutIcon }
];

interface MobileNavigationSidebarProps {
    isVisible: boolean;
    onClose: () => void;
}

const MobileNavigationSidebar = ({ isVisible, onClose }:MobileNavigationSidebarProps ) => {
    const { activeIndex, getIconColor } = useActiveIndex();
    const sidebarRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div ref={sidebarRef} className="bg-learnSpaceWhite w-[15rem] h-full shadow-lg p-5">
            <div className={'grid gap-[52px]'}>
                <img id="navigationSidebarLearnSpaceLogoTr" src={learnSpaceLogo} alt="Learnspace logo" className="w-[110px] h-[24px]" />
                <nav id="mainNavTr">
                    <ul id="navListTr" className="space-y-3">
                        {navItems.map((item, index) => (
                            <li id={`navItemTr${index}`} key={index}>
                                <Link
                                    to={item.href}
                                    className={`flex items-start justify-start h-[2.75rem] text-base leading-[120%] p-3 gap-2 rounded w-full ${activeIndex === index ? 'bg-[#E7F7ED] text-[#0D9B48] font-medium' : 'bg-transparent text-[#3B3B3B] font-normal'}`}
                                >
                                    <item.icon colour={getIconColor(index)} />
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default MobileNavigationSidebar;