import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface LayOutState  {
    hamburger: boolean
}


const initialState : LayOutState = {
    hamburger: false
}

const layOutSlice  = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setHamburger: (state, action: PayloadAction<any>) => {
            state.hamburger = action.payload;
        }

    }

})


export const layOutActions = layOutSlice.actions;
export default layOutSlice.reducer;
