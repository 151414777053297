import React from 'react';
import EmptyStateBlock from "./EmptyStateBlock";

const EmptyState = () => {
    return (
        <section id="loanEmptyState" className="flex flex-col justify-center items-center gap-8 h-[40vh]">
            <EmptyStateBlock/>
            <footer id="emptyStateFooter" className="grid place-content-center gap-3 h-[3.1875rem] w-[20.625rem]">
                <h1 id="emptyStateTitle"
                    className="text-[1.125rem] text-center font-['IBM_Plex_Sans'] font-medium leading-[1.2] text-[#101828]">
                    No repayment history yet
                </h1>
                <p id="emptyStateDescription"
                   className="text-[0.875rem] text-center font-['IBM_Plex_Sans'] font-normal leading-[1.2] text-[#57595D]">
                    Once you repay your loan, the information will appear here
                </p>
            </footer>
        </section>
    );
};

export default EmptyState;