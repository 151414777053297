import React from "react";
import { Link } from "react-router-dom";
import ArrowRight from "./icon/ArrowRight";

const cardData = [{
    title: "Wallet balance", amount: "₦0", link: "/wallet", linkText: "Go to wallet"
}, {
    title: "Loan balance", amount: "₦10,000,000.00", link: "/repayment", linkText: "Go to repayment"
}];

const BalanceCard = () => {
    return (<div id={'balanceCard'} className={'flex gap-5'}>
            {cardData.map((card, index) => (
                <main key={index} className="w-full h-[17.5vh] rounded border border-learnSpaceGray">
                    <div id={`balanceCardBlock${index}`} className="p-5 h-[12.96vh] grid gap-2 border-b border-b-learnSpaceGray">
                        <p id={`cardTitle${index}`} className="text-[#475467] text-[14px] font-normal leading-[120%]">{card.title}</p>
                        <h1 id={`cardAmount${index}`} className="text-[var(--Default-colors-Body,#101828)] font-ibm-plex-sans text-[28px] not-italic font-medium leading-[120%]">{card.amount}</h1>
                    </div>
                    <div id={`balanceLinkBlock${index}`} className="grid place-items-end px-3 items-center h-[4.54vh]">
                        <Link to={card.link}>
                            <h2 id={`cardLinkText${index}`} className="text-primary text-[14px] gap-2 flex font-normal leading-[120%]">{card.linkText}<span id={`cardRightIcon${index}`}><ArrowRight/></span>
                            </h2>
                        </Link>
                    </div>
                </main>
            ))}
        </div>
    );
};

export default BalanceCard;