import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks/hooks";
import React, {useEffect, useState} from "react";
import loanService from "../../../../../services/loan.service";
import utils from "../../../../../utils/utils";
import {toast} from "react-toastify";
import LoanDetails from "../../viewLoanDetailsStages/LoanDetails";
import TraineeDetails from "../../viewLoanDetailsStages/TraineeDetails";
import TraineeAdditionalDetails from "../../viewLoanDetailsStages/TraineeAdditionalDetails";
import LoanOfferTerms from "../../viewLoanDetailsStages/LoanOfferTerms";
import {pageAction} from "../../../../../redux/slices/PageToDIsplaySlice";
import {dummyTraineeTopDetail} from "../../dummyData/dummyData";
import SideSlider from "../../reuseables/SideSlider";
import LayOut2 from "../../../../../components/reusables/layout/LayOut2";

type JsonValueKeys = 'Loan details' | 'Trainee details' | 'Trainee additional details' | 'Loan offer terms' | string;

type JsonValueType = {
    [key in JsonValueKeys]: React.JSX.Element;
};

function ViewLoanOfferIndex() {
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loanOfferData, setLoanOfferData] = useState<any>({});
    const storedLoanOfferId = useAppSelector((state) => state.loan.loanId);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoanOfferFailed, setIsLoanOfferFailed] = useState(false);
    const [isLoanOfferError, setIsLoanOfferError] = useState(false);
    const trainee = loanOfferData?.loanRequest?.trainee;
    const user = loanOfferData?.loanRequest?.trainee?.user;
    const cohort = loanOfferData?.loanRequest?.trainee?.cohort;
    const program = loanOfferData?.loanRequest?.trainee?.cohort?.program;
    const institute = loanOfferData?.loanRequest?.trainee?.cohort?.program.institute;
    const dispatch = useAppDispatch();
    const selected = useAppSelector((state) => state.page.buttonName)
    const [jsonValue, setJsonValue] = useState<JsonValueType>({
        'Loan details': <LoanDetails/>,
        'Trainee details': <TraineeDetails/>,
        'Trainee additional details': <TraineeAdditionalDetails/>,
        'Loan offer terms': <LoanOfferTerms/>
    })
    const [slideValue, setSlideValue] = useState(Object.keys(jsonValue))

    const loanId = loanOfferData?.id;
    const traineeId = trainee?.traineeId;
    const closeModal = () => {
        setOpenModal(false);
    };

    const data = {loanOfferId: storedLoanOfferId};

    const fetchLoanOfferDetails = async () => {
        try {
            const loanOffer = await loanService.viewLoanOffer(data);
            setLoanOfferData(loanOffer.data);

        } catch (error) {
            utils.handleRequestError(error)
        }
    };
    useEffect(() => {
        fetchLoanOfferDetails().then();
    }, []);

    const handleSubmit = async () => {

        const data2 = {loanId, traineeId}
        const loanDisbursal: { loanId: any; traineeId: any } = data2;
        try {
            setIsLoading(true);
            const response = await loanService.disburseLoan(loanDisbursal);
            if (response) {
                setIsLoanOfferFailed(false);
                setIsLoanOfferError(false);
                setIsEmpty(true);
                toast.success("Loan Disbursed Successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            setIsLoanOfferError(true);
            toast.error("Error Disbursing Loan!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        dispatch(pageAction.loanRequestDetailSideBarButton(slideValue[0] as JsonValueKeys))
    }, []);

    return (
        <LayOut2>
            <div id={'hien'} className={'bg-[#FFFFFF] my-8 grid gap-10 mx-5 px-5 pt-5 border rounded h-[90%] '}>
                <header
                    className={'flex h-[6.4vh] items-center content-center gap-2.5 w-full border-b border-learnSpaceGray'}>
                    <h1 id={'prkjt'}
                        className={'text-customColor text-[20px] not-italic font-medium leading-[120%]'}>{dummyTraineeTopDetail.traineeName}</h1>
                </header>

                <main id={'ijrjk'} className={'flex justify-between'}>
                    <SideSlider selected={selected} slideValue={slideValue} value={Object.keys(jsonValue).length}/>
                    <div id={'poijn'}
                         data-testid='currentValueDisplayed'
                         className={'bg-[#FAFBFC] '}>
                        {
                            jsonValue[selected]
                        }
                    </div>
                </main>
            </div>
        </LayOut2>
    )

}

export default ViewLoanOfferIndex;
