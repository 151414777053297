import React, {useEffect, useMemo} from "react";
import DataTable, {Selector, TableColumn} from "react-data-table-component";
import styles from "../table/style/Table.module.css";
import {IoIosArrowDown} from "react-icons/io";
import ReactPaginate from "react-paginate";
import {HiArrowLeft, HiArrowRight} from "react-icons/hi";
import {IColumnProps, IEmptyTable} from "../../../types/commons.type";
import {useAppDispatch} from "../../../redux/hooks/hooks";
import {tableActions} from "../../../redux/slices/tableSlice";
import {useLocation} from 'react-router-dom';
import {Tooltip} from "@mui/material";


interface TableProps<T> {
    // tableData: TableData<T>
    tableData: any;
    columns: IColumnProps[];
    emptyTable: IEmptyTable;
    path: string;
    searchString?: string;
    searchColumn?: string;
    handleRowClick: (row: any) => void | any;
    isSelectable?: boolean;
}

function LearnSpaceTable(props: TableProps<any>) {
    const {tableData, columns, emptyTable, handleRowClick, path} = props;
    const dispatch = useAppDispatch();
    const location = useLocation();

    function EmptyTable() {
        return (
            <div id={`emptyCohort`} className={styles.emptyCohort}>
                <img id={`image`} src={emptyTable.image} alt={emptyTable.text}/>
                <p id={`emptyTableTittle`}>{emptyTable.title}</p>
                <p id={`emptyTable-subText`}>{emptyTable.subText}</p>
            </div>
        );
    }

    const handlePageClick = async (event: { selected: number }) => {
        const selectedPage = event.selected;
        dispatch(tableActions.setPageNumber(selectedPage))
    };

    useEffect(() => {
        dispatch(tableActions.setPageNumber(0));
    }, [location.pathname]);

    const breakLastWord = (title: string): JSX.Element => {
        const words = title.split(" ");
        if (words.length < 2) return <>{title}</>;
        const lastWord = words.pop();
        const firstPart = words.join(" ");
        return (
            <>
                <div className={``}>{firstPart}</div>
                <div className={`whitespace-break-spaces`}>{lastWord}</div>
            </>
        );
    };


    const tableColumns: TableColumn<any>[] = useMemo(() =>
            columns.map((column) => ({
                name: <div id={`${column.title}header`}
                           className={`${styles.header}`}>{breakLastWord(column.title)}</div>,
                selector: column.selector as Selector<any>,
                // cell: (row: any) => (
                //     !!column.cell ? column.cell(row) :
                //         <div id={`${column.title}rows`} className={styles.rows}>
                //                 {row[column.selector]}
                //         </div>
                // ),
                cell: (row: any) => (
                    !!column.cell ? column.cell(row) :
                        <div id={`${column.title}rows`} className={styles.rows}>
                            <Tooltip title={row[column.selector]}>
                                <span>{row[column.selector]}</span>
                            </Tooltip>
                        </div>
                ),
                sortable: column.sortable ?? false,
                conditionalCellStyles: column.conditionalCellStyles
            })),
        [columns]
    );

    const search = () => {
        const searchString = props.searchString;
        if (tableData && tableData.content && Array.isArray(tableData.content)) {
            return tableData.content.filter((content: any) => {
                if (props.searchColumn) {
                    return content[props.searchColumn]?.toLowerCase().includes(searchString?.toLowerCase() as string)
                }
            })
        }
    }

    const handleChange = (state: any) => {
        dispatch(tableActions.setTableData(state.selectedRows))
    };

    return (
        <div id={`mainContainer`} className={styles.mainContainer}>
            <div id={`tableWrapper`} className={styles.tableWrapper}>
                <div id={`customTable`} className={styles.customTable}>
                    {/* eslint-disable-next-line eqeqeq */}
                    {tableData && tableData.content &&
                    Array.isArray(tableData.content) &&
                    (tableData.content.length === 0) ? (
                        
                        <EmptyTable/> 
                    ) : (
                        <DataTable
                            title=""
                            columns={tableColumns}
                            data={search()!}
                            className={styles.tableRows}
                            fixedHeader
                            highlightOnHover
                            {...props.isSelectable && {
                                selectableRows: true,
                                onSelectedRowsChange: handleChange,
                            }}
                            pointerOnHover
                            sortFunction={(arrayToSort) => {
                                console.log("sort function called precisely in learn space table:  NOTE Please update the sort function in future sprints... every component that needs the learn space table should pass the sort function as a prop else if you remove this temporary function without a real solution, it will cause the page to break")
                                return arrayToSort.sort((a, b) => a - b)
                            }
                            }
                            sortIcon={<IoIosArrowDown/>}
                            onRowClicked={(row) => {
                                handleRowClick(row);
                            }}
                            customStyles={{
                                headCells: {style: {paddingRight: "0", paddingLeft: "10px"}},
                                headRow: {style: {marginBottom: 10}},
                                table: {style: {backgroundColor: "unset", height: "100%"}},
                                cells: {style: {paddingTop: 5, paddingBottom: 3}},
                                responsiveWrapper: {style: {minHeight: "100%"}},
                                rows: {style: {"&:hover": {backgroundColor: "#0D9B48"}}}
                            }}
                        />
                    )}
                </div>
            </div>

            <div className={styles.paginationContainer}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                        <span id={`Next`}>Next <HiArrowRight id={`rightArrow`} className={styles.rightArrow}/></span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={tableData?.total}
                    previousLabel={<span id={`Previous`}>
                        <HiArrowLeft id={`leftArrow`} className={styles.leftArrow}/> Previous </span>
                    }
                    // renderOnZeroPageCount={null}
                    containerClassName={tableData?.total === 1 ? styles.pagination2 : styles.pagination}
                    pageLinkClassName={styles.pageNumber}
                    previousLinkClassName={tableData?.pageNo === 0 ? styles.prev2 : styles.prev}
                    nextLinkClassName={!tableData?.last ? styles.next : styles.next2}
                    activeLinkClassName={styles.active}
                    nextClassName={styles.nextLi}
                    previousClassName={styles.prevLi}
                />
            </div>
        </div>
    );

}

export default LearnSpaceTable;
