import React from 'react';
import EmptyState from "./EmptyState";

const Wallet = () => {
    return (
        <main id={'walletMainContainer'}
              className={'h-full w-full grid gap-12 bg-learnSpaceWhite rounded-[8px] px-5 pt-10'}>
            <section id={'walletBalanceSection'}
                     className={'flex w-full h-[7.3125rem] py-6 px-5 justify-between items-center rounded border border-b-learnSpaceGray'}>
                <div id={'walletBalanceDetails'} className={'flex flex-col items-start gap-3.5'}>
                    <h1 id={'walletBalanceTitle'}
                        className={'text-[14px] font-normal leading-[120%] text-learnSpaceDarkGray'}>Wallet balance</h1>
                    <h1 id={'walletBalanceAmount'}
                        className={'text-[32px] font-medium leading-[120%] text-customColor'}>₦100,000,000,000.00</h1>
                </div>
                <button id={'walletWithdrawButton'}
                        className={'flex justify-center items-center w-[6.4375rem] bg-primary h-[2.5625rem] rounded'}>
                    <p id={'walletWithdrawButtonText'}
                       className={'text-[14px] font-medium leading-[120%] text-learnSpaceWhite'}>Withdraw</p>
                </button>
            </section>
            <EmptyState/>
        </main>
    );
};

export default Wallet;