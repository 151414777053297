import React from 'react';
import image from "../../assets/images/loan_product_icon.svg";
import DisplayDetails from "../reuseables/DisplayDetails";
import CustomButton from "../reuseables/CustomButton";
import {dummyTraineeTopDetail} from "../dummyData/dummyData";
interface IProps {

}

const TraineeAdditionalDetails = (props: IProps) => {

    const additionalDetails ={
        'Next of kin name': 'Michael Oluchukwu',
        'Next of kin email address': 'michael.oluchukwu@gmail.com',
        'Next of kin phone number': '+234809885884',
        'Next of kin relationship': 'Brother',
    }
    const additionalDetailsKeys = Object.keys(additionalDetails);
    const additionalDetailsValues = Object.values(additionalDetails);

    return (
        <div id={`nsidn`} className={'bg-[#FAFBFC] w-[53vw] px-5 pt-4'}>
                <h2 id={`lksodkn`} className={'font-semibold text-[18px] text-[#101828] mb-2'}>Trainee additional details</h2>
            <DisplayDetails
                id={'gcjckvkgv'}
                details={dummyTraineeTopDetail}
                detailsKeys={additionalDetailsKeys}
                detailsValues={additionalDetailsValues}/>
                <CustomButton
                    id={'poyyffgxsr'}
                    back={'Trainee details'}
                    next={'Loan offer terms'}
                    leftButtonName={'Back'}
                    rightButtonName={'Continue'}
                />
        </div>
    );
};


export default TraineeAdditionalDetails;