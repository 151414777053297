import React from 'react';

const ChevronIcon = () => {
    return (
        <svg id={'ChevronIcon'} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 6.75L10 11.25L15 6.75" stroke="#667085" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>

    );
};

export default ChevronIcon;