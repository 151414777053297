import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useActiveIndex = () => {
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        const path = location.pathname.split('/')[1];
        switch (path) {
            case '':
                setActiveIndex(0);
                break;
            case 'wallet':
                setActiveIndex(1);
                break;
            case 'repayment':
                setActiveIndex(2);
                break;
            default:
                setActiveIndex(null);
        }
    }, [location]);

    const getIconColor = (index: number) => {
        return activeIndex === index ? '#0D9B48' : '#667085';
    };

    return { activeIndex, getIconColor };
};

export default useActiveIndex;