import React, {useState} from "react";
import styles from "./styles/SideBar.module.css";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {Link, useNavigate} from "react-router-dom";
import learnSpaceLogo from "../../../features/dashboard/assets/images/learnspaceLogo.svg";
import learnSpaceLetters from "../../../features/dashboard/assets/images/learnspaceLetters.svg";
import SideNavButton from "./SideNavButton";
import SettingsIcon from "./assets/SettingsIcon";
import SideBarBottomLayer from "./SideBarBottomLayer";

interface IProps {
    className: string;
    mobileOpen: any;
    barCollapse: boolean;
    setBarCollapse: React.Dispatch<React.SetStateAction<boolean>>;
    selectedName: string | null;
}

function SideBar({className, mobileOpen, barCollapse, setBarCollapse, selectedName}: IProps) {
    const [openModal, setOpenModal] = useState(false);

    const navigate = useNavigate()
    const handleLogout = () => {
        setOpenModal(true);
    };


    return (
        <>
            <div id={'sideBarMainDiv'} className="hidden sm:block "/>
            <div
                id={'barCollapse'}
                className={`hidden border border-r-[#E0E3E8] pt-9 sm:block fixed top-[1px] z-[1001] left-0 bottom-0 h-[100%] bg-white transition-all duration-200 ease-in ${barCollapse ? "w-[80px]" : "w-[218px]"} ${className}`}
            >

                <Link id={'fund'} to="" className={`mt-1`}>
                    <div id={'imagesDiv'} className="flex items-center mb-12 ml-4">
                        <img id={'learnSpaceLogo'} src={learnSpaceLogo} className="mr-2" alt="learn-space-logo" />
                        <img id={'learnSpaceLetters'} src={learnSpaceLetters} className={``} alt="learn-space-logo" />
                    </div>
                </Link>
                <div id={'SideNavButtonDiv'} className={`w-full`}>
                    <SideNavButton
                        selectedName={selectedName}
                        barCollapse={barCollapse}
                    />
                </div>
                <SideBarBottomLayer/>
            </div>

        </>
    )
}

export default SideBar;
