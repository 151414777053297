import React from 'react';
import image from "../../assets/images/loan_product_icon.svg";
import ViewLoanDetailsTop, {DetailsProps} from "../reuseables/ViewLoanDetailsTop";
import DisplayDetails from "../reuseables/DisplayDetails";
import CustomButton from "../reuseables/CustomButton";
import {dummyTraineeTopDetail} from "../dummyData/dummyData";



const TraineeDetails = () => {
    const traineeDetails ={
        'Email address': 'vanessaoluchukwu@gmail.com',
        'Phone number': '+2348048950903',
        'Gender': 'Female',
        'Date of birth': '11 March, 1999',
        'Marital status': 'Single',
        'Nationality': 'Nigeria',
        'State of origin': 'Imo',
        'State of residence': 'Lagos',
        'Residential address': '316, Herbert Macaulay Way, Alagomeji, Sabo, Yaba',
        'Program name': 'Product design',
        'Cohort name': 'Luminaries',
    }
    const traineeDetailsKeys = Object.keys(traineeDetails);
    const traineeDetailsValues = Object.values(traineeDetails);


    return (
        <div id={'aeibeu'} className={'bg-[#FAFBFC] w-[53vw] px-5 pt-4'}>
            <h2 id={'qpeibf'} className={'font-semibold text-[18px] text-[#101828] mb-2'}>Trainee details</h2>

            <div id={'beisjb'} className={'overflow-y-auto h-[58vh] pr-2 w-full scrollbar-thin scrollbar-webkit'}>
                <DisplayDetails
                    id={'yttddsxxfxrjvy'}
                    details={dummyTraineeTopDetail}
                    detailsKeys={traineeDetailsKeys}
                    detailsValues={traineeDetailsValues}/>
                <CustomButton
                    id={'poufytrsrsd'}
                    back={'Loan details'}
                    next={'Trainee additional details'}
                    leftButtonName={'Back'}
                    rightButtonName={'Continue'}
                />
            </div>
        </div>
    );
};

export default TraineeDetails;