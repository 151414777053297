import React, {lazy, Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import InstituteSideBar from "../features/dashboard/institute/instituteSideBar/index";
import ViewProgram from "../features/dashboard/programs/ViewProgram";
import Investor from "../features/dashboard/investor/investorDashboard";
import InvestorBiodata from "../features/dashboard/investor/investorBiodata/BioLayout";
import InvestorInvestment from "../features/dashboard/investor/investorInvestment/Index";
import InvestorBankDetails from "../features/dashboard/investor/investorBankDetails";
import ViewTrainingInstitute from "../features/dashboard/institute/ViewTrainingInstitute";
import CohortsInProgram from "../features/dashboard/cohort/cohortsInProgram";
import ViewLoanRequestIndex from "../features/dashboard/loan/loanRequest/viewLoanRequestDetails";
import ViewLoanOfferIndex from "../features/dashboard/loan/loanOffer/allLoanOffer/loanOfferDetailPage";
import LoanProduct from "../features/dashboard/loan/LoanProduct";
import LoanRequestDetails from "../features/dashboard/loan/loanRequest/LoanRequestDetails";
import ViewLoanProductDetail from "../features/dashboard/loan/LoanProduct/ViewLoanProductDetail";
import DisbursalDetails from "../features/dashboard/loan/disburseLoan/DisbursalDetails";
import ViewAllLoanProduct from "../features/dashboard/loan/LoanProduct/ViewAllLoanProduct";
import Loanees from "../features/dashboard/loan/LoanProduct/Loanees";
const SelectedLoan = lazy(()=> import("../features/dashboard/loan/selectLoan/index"))
const CohortTrainees = lazy(()=> import("../features/dashboard/trainee/cohortTrainees"))
const ViewAllLoanLayout = lazy(()=> import("../features/dashboard/loan/loanRequest/viewAllLoanRequest"))
const MidSection = lazy(()=> import("../features/dashboard/institute/ViewAllTrainingInstitute/midSection"))
const FundProductDashboard = lazy(() => import("../features/dashboard/fundProduct/fundProductDashboard"))
const LoanRequest = lazy(() => import("../features/dashboard/trainee/loanRequest"))
const TraineeDetails = lazy(() => import("../features/dashboard/trainee/traineeDetails/TraineeDetails"))
const ChangePassword = lazy(() => import("../features/dashboard/trainee/loanRequest"))
const InviteColleague = lazy(() => import("../features/dashboard/portfolioManager/inviteColleague/index"))
const CohortDetail = lazy(() => import("../features/dashboard/cohort/cohort/CohortDetail"));
const ViewAllLoanProductRequest = lazy(() => import("../features/dashboard/loan/LoanProduct/ViewAllLoanProductRequestDashBoard"))
const CreateLoanProductRequest = lazy(() => import("../features/dashboard/loan/LoanProduct/index"))

function ManagerRoutes() {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route index element={<Navigate to="/fund" replace/>}/>
                    <Route path="/fund" element={<FundProductDashboard/>}/>
                    <Route path="/trainees" element={<LoanRequest/>}/>
                    <Route path="/trainee-details" element={<TraineeDetails/>}/>
                    <Route path="/change-password" element={<ChangePassword/>}/>
                    <Route path="/invite-colleague" element={<InviteColleague/>}/>
                    <Route path="/view-all-loan" element={<ViewAllLoanLayout />} />
                    <Route path="/institute" element={<MidSection />} />
                    <Route path="/loan" element={<SelectedLoan />} />
                    <Route path="institute/view/cohort" element={<CohortDetail />} />
                    <Route path={`/cohort/cohort-trainees`} element={<CohortTrainees/>}/>
                    <Route path="institute/view" element={<InstituteSideBar />}/>
                    <Route path="/view" element={<ViewTrainingInstitute/>}/>
                    <Route path="/program/view" element={<ViewProgram />} />
                    <Route path="/investor" element={<Investor />} />
                    <Route path={`/investor/biodata`} element={<InvestorBiodata/>}/>
                    <Route path={`/investor/investments`} element={<InvestorInvestment/>}/>
                    <Route path={`/investor/bank-details`} element={<InvestorBankDetails/>}/>
                    <Route path={`/program/view/cohorts`} element={<CohortsInProgram/>}/>
                    <Route path={`/loan/view_loan_request`} element={<ViewLoanRequestIndex/>}/>
                    <Route path={`/loan/view_loan_offer`} element={<ViewLoanOfferIndex/>}/>
                    <Route path={`/view-loan-product`} element={<LoanProduct/>}/>
                    <Route path={`/view/loan_request-details`} element={<LoanRequestDetails/>} />
                    <Route path={`/view/loanProductDetail`} element={<ViewLoanProductDetail/>}/>
                    <Route path={'/view/all-loan-product'} element={<ViewAllLoanProduct/>}/>
                    <Route path={'/view/loan_offer-details'} element={<ViewLoanOfferIndex/>}/>
                    <Route path={'/view/loan/disbursal/details'} element={<DisbursalDetails/>}/>
                    <Route path={'/view/loanees'} element={<Loanees/>} />
                </Routes>
            </Suspense>
        </>
    );

      
}

export default ManagerRoutes;
