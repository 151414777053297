import React from "react";

interface ICustomDotProps{
    index: number;
    id: string;
}
interface ILinkDetail {
    title: string;
    link: string;
    id: string;
}
interface CustomLinks {
    linkDetails: ILinkDetail[];
}
const CustomLinks = ({linkDetails}: CustomLinks )=>{
    const CustomDot = ({index, id}:ICustomDotProps)=> {
        return <>
            {! (index === linkDetails.length -1) &&
                <span id={`${id}djjs`} className={'h-1 w-1 bg-[#D0D5DD] border rounded-3xl mt-2 ml-4 mr-4'}></span>
            }
        </>
    }
    return (
        <div id={'linksDiv'} className={'flex text-[#2ba85e] ml-9 pb-3 mt-4'}>
            {linkDetails.map((link, index)=> (
                <>
                    <a id={`${link.id}owawein`}>
                        <span id={`${link.id}pwipnd`} className={'list-none underline'}>{link.title}</span>
                    </a>
                    <CustomDot index={index} id={link.id} />
                </>
            ))}


        </div>
    )
}
export default CustomLinks