import React, {useState} from 'react';
import learnSpaceLogo from '../../assets/images/learnspaceLogo.svg';
import {XIcon} from './icon';
import traineeImage from '../../loan/dummyData/Ellipse 262.png'
import InstituteAvatar from '../../../../assets/image/instituteAvatar.svg'

const notifications = [{
    title: 'Welcome to Learnspace!', date: 'Aug 29, 2024', time: '10:00 AM', isNew: true, buttonText: null,
}, {
    title: 'School of Design, Yaba sent you a loan offer',
    date: 'Aug 29, 2024',
    time: '10:00 AM',
    isNew: true,
    buttonText: 'View loan offer',
},];

const NotificationPopup = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleButtonClick = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const userDetails = {
        "Email address": "vanessaoluchukwu@gmail.com",
        "Phone number": "+2348048950903",
        "Gender": "Female",
        "Date of birth": "11 March, 1999",
        "Marital status": "Single",
        "Nationality": "Nigeria",
        "State of origin": "Imo",
        "State of residence": "Lagos",
        "Residential address": "316, Herbert Macaulay Way, Alagomeji, Sabo, Yaba",
        "Program name": "Product design",
        "Cohort name": "Luminaries"
    };

    return (<main id={'notificationInbox'}
                  className="absolute top-0 -right-24 h-[279px] w-[378px] mt-10 bg-white rounded shadow-custom overflow-y-hidden">
            <header id={'notificationHeader'} className={'px-3 pt-5 pb-3'}>
                <h1 id={'notificationTitle'}
                    className={'text-learnSpaceDarkGray text-base font-medium leading-[120%]'}>Notifications</h1>
            </header>
            {notifications.map((notification, index) => (<section id={`notificationSection${index}`} key={index}
                                                                  className={`flex gap-4 px-3 py-5 ${index < notifications.length - 1 ? 'border-b border-b-learnSpaceGray' : ''}`}>
                    <img id={`notificationLogo${index}`} src={learnSpaceLogo} alt="" className="w-[35px] h-[38px]"/>
                    <div id={`notificationContent${index}`} className={'grid gap-2 w-full'}>
                        <div id={`notificationHeader${index}`} className={'flex justify-between items-center w-full'}>
                            <h1 id={`notificationTitle${index}`}
                                className={'text-learnSpaceDarkGray text-[14px] font-normal leading-[120%] w-[218px]'}>{notification.title}</h1>
                            {notification.isNew && (<div id={`notificationNewBadge${index}`}
                                                         className={'flex justify-center items-center bg-primary-80 h-3.5 w-[1.9375rem] rounded-[18px]'}>
                                    <p id={`notificationNewText${index}`}
                                       className={'text-[10px] text-primary font-medium leading-[120%]'}>NEW</p>
                                </div>)}
                        </div>
                        <p id={`notificationDate${index}`}
                           className={'flex items-center text-[12px] text-[#91959C] gap-[7px] font-normal leading-[120%]'}>
                            {notification.date} <span><div id={`notificationDot${index}`}
                                                           className={'h-1 w-1 rounded-[100%] bg-learnSpaceGray'}></div></span> {notification.time}
                        </p>
                        {notification.buttonText && (
                            <button id={`notificationButton${index}`} onClick={handleButtonClick}
                                    className={`flex justify-center items-center bg-primary h-[33px] w-[113px] rounded`}>
                                <p id={`notificationButtonText${index}`}
                                   className={'text-white text-[14px] not-italic font-medium leading-[120%]'}>
                                    {notification.buttonText}
                                </p>
                            </button>)}
                    </div>
                </section>))}
            {isModalVisible && (<main id={'modalOverlay'}
                                      className="fixed inset-0 flex items-center justify-center bg-learnSpaceSlateGray backdrop-blur">
                    <main id={'modalContent'} className="bg-white h-[81.48vh] w-[80vw] rounded overflow-y-hidden">
                        <header id={'modalHeader'}
                                className={'flex justify-between items-center p-5 h-16 border-b border-b-learnSpaceGray'}>
                            <h2 id={'modalTitle'}
                                className={'text-[20px] text-customColor font-medium leading-[120%]'}>Accept loan
                                offer</h2>
                            <XIcon onClick={closeModal}/>
                        </header>
                        <footer id={'modalFooter'}
                                className={'md:flex grid justify-normal md:justify-between mt-8 mx-5 flex-grow'}>
                            <aside id={'modalAside'}>
                                <article id={'traineeDetails'}
                                         className="h-[2.5625rem] w-[12.875rem] flex items-center py-3 px-5 border-l-[3px] border-l-[#0EAA4F]">
                                    <p id={'traineeDetailsText'}
                                       className={'text-[14px] text-primary font-normal leading-[120%]'}>Trainee
                                        details</p>
                                </article>
                                <article id={'traineeAdditionalDetails'}
                                         className="h-[2.5625rem] w-[12.875rem] flex items-center py-3 px-5 border-l-[3px] border-l-learnSpaceGray">
                                    <p id={'traineeAdditionalDetailsText'}
                                       className={'text-[14px] text-learnSpaceDimGray font-normal leading-[120%]'}>Trainee
                                        additional details</p>
                                </article>
                                <article id={'loanDetails'}
                                         className="h-[2.5625rem] w-[12.875rem] flex items-center py-3 px-5 border-l-[3px] border-l-learnSpaceGray">
                                    <p id={'loanDetailsText'}
                                       className={'text-[14px] text-learnSpaceDimGray font-normal leading-[120%]'}>Loan
                                        details</p>
                                </article>
                            </aside>
                            <nav id={'modalNav'}
                                 className={'flex flex-col rounded-[8px] gap-5 items-start p-5 w-full md:w-[47rem] bg-learnSpaceAthensGray overflow-y-auto h-[calc(81.48vh-4rem)]'}>
                                <h1 id={'traineeDetailsTitle'}
                                    className={'text-[18px] text-customColor font-medium leading-[120%]'}>Trainee
                                    details</h1>
                                <div id={'traineeDetailsContent'}
                                     className={'flex flex-col items-start gap-5 w-full rounded py-4 px-5 bg-learnSpaceWhite'}>
                                    <main id={'traineeDetailsMain'}
                                          className={'md:flex grid items-center justify-normal md:justify-between w-full'}>
                                        <div id={'traineeDetailsImageContainer'} className={'flex items-center gap-3'}>
                                            <img id={'traineeImage'} src={traineeImage} alt="trainee Image"/>
                                            <div id={'traineeDetailsTextContainer'}
                                                 className={'flex flex-col items-start gap-3'}>
                                                <h1 id={'traineeName'}
                                                    className={'text-[16px] text-customColor font-medium leading-[120%]'}>Vanessa
                                                    Chiamaka Oluchukwu</h1>
                                                <div id={'traineeInstitute'} className={'flex items-center gap-2'}>
                                                    <img id={'instituteAvatar'} src={InstituteAvatar}
                                                         alt="Institute Avatar"/>
                                                    <p id={'instituteName'}
                                                       className={'text-[14px] text-customColor font-normal leading-[120%]'}>School
                                                        of Product, Yaba</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id={'creditScoreContainer'}
                                             className={'flex bg-learnSpaceAthensGray items-center py-2 px-3 gap-2 rounded-[32px]'}>
                                            <p id={'creditScoreLabel'}
                                               className={'text-[14px] text-learnSpaceBlack font-normal leading-[120%]'}>Credit
                                                score</p>
                                            <div id={'creditScoreValueContainer'}
                                                 className={'rounded-[6px] border border-primary-520 bg-learnSpaceWhite flex py-[3px] px-1 justify-center items-center gap-1'}>
                                                <div id={'creditScoreValue'}
                                                     className={'flex justify-center items-center px-[12px] w-[26px] h-[15px] bg-primary-80 rounded-[3px]'}>
                                                    <h1 id={'creditScoreText'}
                                                        className={'text-[11px] text-primary-600 font-medium leading-[163.636%]'}>670</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                    {Object.entries(userDetails).map(([key, value], index) => (
                                        <main id={`userDetailMain${index}`} key={index} className={'w-full'}>
                                            <div id={`userDetailContainer${index}`}
                                                 className={'flex justify-between items-center border-b border-b-learnSpacePaleBlue w-full h-[73px]'}>
                                                <h2 id={`userDetailKey${index}`}
                                                    className={'text-[14px] text-learnSpaceDimGray font-normal leading-[120%]'}>{key}</h2>
                                                <h1 id={`userDetailValue${index}`}
                                                    className={'text-[14px] text-customColor font-medium leading-[120%]'}>{value}</h1>
                                            </div>
                                        </main>))}
                                </div>
                            </nav>
                        </footer>
                    </main>
                </main>)}
        </main>);
};

export default NotificationPopup;