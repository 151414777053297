import React from 'react';
import BalanceCard from "./BalanceCard";
import EmptyState from "./EmptyState";

const Overview = () => {
    return (
        <main id={'OverviewTr'} className={'h-full w-full bg-learnSpaceWhite rounded-[8px] px-5 pt-10'}>
            <section id="overviewSection" className={'grid gap-5'}>
                <h1 id="overviewTitle"
                    className={'text-[18px] font-medium leading-[120%] text-[var(--Default-colors-Body,#101828)] '}> Your
                    balances
                </h1>
                <BalanceCard />
            </section>

            <h1 id={"repaymentHt"} className={'text-customColor mt-4 font-ibm-plex-sans text-[18px] font-medium leading-[120%]'}>Repayment history</h1>
            <EmptyState />
        </main>
    );
};

export default Overview;