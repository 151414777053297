import React from "react";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import SideSlider from "../reuseables/SideSlider";
import ProductDetails from "./ProductDetails";
import PartnershipDetail from "./PartnershipDetail";
import LayOut2 from "../../../../components/reusables/layout/LayOut2";
import Button from "@mui/material/Button";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LuUsers } from "react-icons/lu";
import styles from './styles/LoanProductModal.module.css'
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import {loanProductActions} from "../../../../redux/slices/loanProductSlice";

type JsonValueKeys = 'Loan details' | 'Trainee details' | 'Trainee additional details' | 'Loan offer terms'|string;

type JsonValueType = {
    [key in JsonValueKeys]: React.JSX.Element;
};

function ViewLoanProductDetail() {
    const dispatch = useAppDispatch();
    const selected = useAppSelector((state)=> state.page.buttonName)
    const currentDetailsPage = useAppSelector((state)=> state.loanProduct.loanProductDetailsCurrentState)
    const router  = useNavigate()

    const [jsonValue, setJsonValue] = useState<JsonValueType>({
        'Product details': <ProductDetails/>,
        'Partnership details': <PartnershipDetail/>,
    })
    const [slideValue, setSlideValue] = useState(Object.keys(jsonValue))


    useEffect(() => {
        dispatch(pageAction.loanRequestDetailSideBarButton( slideValue[0] as JsonValueKeys))
    }, []);

    const goBack = () => {
        if(currentDetailsPage ==='Product details' ){
            router('/view/all-loan-product')
        }else if (currentDetailsPage === 'Partnership details'){
            dispatch(loanProductActions.setLoanProductDetailsCurrentState('Product details'))
            dispatch(pageAction.loanRequestDetailSideBarButton('Product details'))

        }
    }



    return (
        <>
            <LayOut2>
                <div id={'mainDIv'} className={` grid grid-cols-1  place-items-center h-full  ${styles.wrapper}`}>
                    <div id={'only'} className={`grid rounded bg-white w-[97%]  md:h-[85vh]`}>
                        <div className={`grid gap-3`}>
                            <div className={` h-[7rem] w-[98%] mr-auto ml-auto `}>
                                <Button
                                    onClick={goBack}
                                    id="backToViewAllLoan"
                                    startIcon={<ChevronLeftIcon sx={{color: '#94979D'}}/>}
                                    sx={{
                                        color: '#94979D',
                                        textTransform: 'none',
                                        marginTop: '1rem',
                                        "&:hover": {backgroundColor: 'white'}
                                    }}
                                >Back</Button>
                                <div
                                    className={'h-[3rem] mt-[0.5rem] flex md:flex-row flex-col place-content-between    '}>
                                    <div className={`mt-auto md:mb-auto  mb-6 ${styles.title}`}>Software engineering loan</div>

                                    <div className="">
                                    <Button
                                    
                                        onClick={()=> {router('/view/loanees')}}
                                        variant="outlined"
                                        sx={{
                                            color: '#0D9B48',
                                            backgroundColor: 'white',
                                            borderColor: '#0D9B48',
                                            textTransform: 'none',
                                            '&:hover': {backgroundColor: 'white', borderColor: '#0D9B48'}
                                        }}
                                        className="w-full "
                                        id="viewAllLoanFromLoanAllLoanProduct"
                                        startIcon={<LuUsers color={'#0D9B48'}/>}
                                    >View loanees</Button>
                                    </div>
                                </div>
                            </div>
                            <div  className="">
                            <Divider sx={{marginBottom: '1rem', width: '97%', marginRight: 'auto ', marginLeft: 'auto'}}/>
                            </div>
                        </div>
                        <div className="hidden md:block">
                        <div className={`flex mt-auto mb-auto h-[70vh] w-[97%]  mr-auto ml-auto`}>
                            <div className={`w-[30%] `}>
                                <SideSlider id={'slider'} selected={selected} slideValue={slideValue}
                                            value={Object.keys(jsonValue).length}/>
                            </div>

                            <div id={'currentValueDisplayed'}
                                 data-testid='currentValueDisplayed'
                                 className={'w-[70%] h-[100%]   ml-44 '}>
                                {
                                    jsonValue[selected]
                                }
                            </div>
                            </div>
                        </div>
                        <div id="mobileViewDetails" className="md:hidden block">
                        <div  className="mt-5">
                            <Divider sx={{ width: '97%', marginRight: 'auto ', marginLeft: 'auto'}}/>
                            </div>
                           
                            <div className="mt-10 mb-auto h-[70vh] w-[100%] px-5">
                            <div className={` `}>
                                <SideSlider id={'slider'} selected={selected} slideValue={slideValue}
                                            value={Object.keys(jsonValue).length}/>
                            </div> 
                            <div id={'currentValueDisplayed'}
                                 data-testid='currentValueDisplayed'
                                 className={'w-[70%] h-[100%]  '}>
                                {
                                    jsonValue[selected]
                                }
                            </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </LayOut2>
        </>
    )

}

export default ViewLoanProductDetail;
