import React from 'react';
import styles from './styles/LoanProductModal.module.css'
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import Button from '@mui/material/Button';
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";
import {store} from "../../../../redux/store";
import CircleIcon from '@mui/icons-material/Circle';
import {loanProductActions} from "../../../../redux/slices/loanProductSlice";
const ProductDetails = () => {


    const continues = () => {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Partnership details'))
        store.dispatch(loanProductActions.setLoanProductDetailsCurrentState('Partnership details'))
    }


    const details =  [
        {
            field: 'Loan product name',
            value: 'Software engineering loan'
        },
        {
            field: 'Loan product sponsor',
            value: 'Gbenga Moshood'
        }, {
            field: 'Loan product size',
            value: '₦3,000,000'
        }, {
            field: 'Tenor',
            value: '4 years'
        }, {
            field: 'Loan repayment amount',
            value: '₦8,000,000'
        }, {
            field: 'Loan interest rate ',
            value: '40%'
        }, {
            field: 'Maratorium',
            value: '12 months'
        }, {
            field: 'Total amount disbursed',
            value: '₦30,000,000'
        }, {
            field: 'Interest earned ',
            value: '₦3,000,000'
        }, {
            field: 'Obligor limits',
            value: '70.9%'
        }, {
            field: 'Cost of funds',
            value: '80.3%'
        }

    ]

    return (
        <div id={`Loan`} className={' rounded-md bg-[#FAFBFC] w-[150%] md:w-[53vw] h-[80%]'}>
            <div className={`w-[97%] md:mt-0 mt-10 md:pt-[1rem] md:mb-[1rem] mr-auto ml-auto ${styles.title} `}>Product details</div>
            <div className={`h-[75%]  mr-auto ml-auto ${styles.detailsScrollBar} `}>
                <div className={` md:w-[96%] h-[auto] mt-[1rem] mr-auto ml-auto pt-3 pr-2 pl-2 bg-white rounded-md    `}>
                    {
                        details?.map((detail) => (
                            <DetailsFields field={detail.field} value={detail.value} marginBottom={'4rem'} showTopDivider={false} showBottomDivider={true} />
                        ))
                    }
                    <div className={` md:w-[fit-content] md:flex gap-3  mt-[2rem] pb-[1rem]`}>
                        <div className={`${styles.lineWord}`}>
                            view  loan  terms  and  condition
                            <hr className={`${styles.line}`}/>
                        </div>
                        <div className={`grid place-content-center h-[1rem] `}>
                           <CircleIcon sx={{color:'#D0D5DD', height: '5px', width: '5px', }}/>
                        </div>
                        <div className={`${styles.lineWord}`} >
                            view loan product mandate
                            <hr className={`${styles.line}`}/>
                        </div>
                    </div>
                   
                </div>
                <div className={`md:hidden pr-4 pb-2  mt-[1rem] `}>
                    <Button
                        fullWidth
                        onClick={continues}
                        id={"continueToLoanPartnershipFromProductDetails"}
                        variant={'contained'}
                        sx={{color: 'white', boxShadow: 0,backgroundColor: '#0D9B48', "&:hover": {backgroundColor: '#0D9B48', boxShadow: 0}, textTransform: 'none'}}
                    >Continue</Button>
                </div>
               
                 <div className='hidden md:block'>
                <div className={`flex place-content-end pr-4 pb-2  mt-[1rem] `}>
                    <Button
                        onClick={continues}
                        id={"continueToLoanPartnershipFromProductDetails"}
                        variant={'contained'}
                        sx={{color: 'white', boxShadow: 0,backgroundColor: '#0D9B48', "&:hover": {backgroundColor: '#0D9B48', boxShadow: 0}, textTransform: 'none'}}
                    >Continue</Button>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;