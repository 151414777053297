import React from 'react';

const NotificationBadge = () => {
    return (
        <div
            id={'notificationBadge'}
            className="absolute -top-[4px] -right-[3px] bg-[#0EAA4F] text-white text-xs rounded-[22px] w-[20px] h-3.5 flex flex-col justify-center items-center gap-[10px] p-[var(--spacing-none,0px)_10px]">
            2
        </div>
    );
};

export default NotificationBadge;