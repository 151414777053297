import React, { FC } from 'react';
import { useAppDispatch } from "../../../../redux/hooks/hooks";
import { pageAction } from "../../../../redux/slices/PageToDIsplaySlice";

type buttonProps = {
    next?: string,
    back?: string,
    buttonName?: string,
    customStyle?: string,
    leftButtonName?: string,
    rightButtonName?: string,
    id: string,
    onClick?: () => void
}

const CustomButton: FC<buttonProps> = (
    {
        next,
        back,
        buttonName,
        customStyle,
        id,
        leftButtonName,
        rightButtonName,
        onClick
    }
) => {

    const dispatch = useAppDispatch();
    const onButtonClick = (paramValue: string | undefined) => () => {
        if (paramValue !== undefined && paramValue !== '') {
            dispatch(pageAction.loanRequestDetailSideBarButton(paramValue));
        }
        if (onClick) {
            onClick();
        }
    };

    return <>
        {
            buttonName ?
                <button
                    data-testid={'standAloneButton'}
                    id={`eowfnj${id}`}
                    onClick={onButtonClick(next ? next : back ? back : '')}
                    className={customStyle}>
                    {buttonName}
                </button> :
                <div id={`cowapn${id}`} className={'flex justify-end gap-3 mt-2 mb-2'}>
                    <div className={'justify-self-end'}>
                        <button
                            data-testid={'firstButton'}
                            id={`anidnsd${id}`}
                            onClick={onButtonClick(back)}
                            className={'border rounded border-[#0D9B48] px-8 py-3 bg-[#ffffff] w-28 text-[#0D9B48]'}>
                            {leftButtonName}
                        </button>
                    </div>
                    <button
                        data-testid={'secondButton'}
                        id={`${id}aienhdsbj`}
                        onClick={onButtonClick(next)}
                        className={`bg-[#0D9B48] text-white border rounded w-28`}>
                        {rightButtonName}
                    </button>
                </div>
        }
    </>
}

export default CustomButton;