import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {buttonNames} from "../../utils/roles";
import {PURGE} from "redux-persist";

interface IPage{
    buttonName: string;
}

const initialState: IPage = {
    buttonName: buttonNames.INCOMING_COHORT,
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        loanRequestDetailSideBarButton: (state, action:PayloadAction<string>)=>{
            state.buttonName = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.buttonName= "";
        });
    },
})

export const pageAction = pageSlice.actions;
export default pageSlice.reducer;