import React, {ReactNode, useEffect, useState} from "react";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import styles from "./styles/layout.module.css";


interface IProps {
    children?: ReactNode;
    page?: any;
    customize?: string;
}

function Layout({children, page, customize}: IProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [barCollapse, setBarCollapse] = useState(false);
    const [selectedName, setSelectedName] = useState<string | null>(null);

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
        setBarCollapse(false);
    };

    useEffect(() => {
        if (barCollapse) {
            setSidebarOpen(false);
        }
    }, [barCollapse]);

    useEffect(() => {
        setSelectedName(page);
    }, [page]);


    return (
        <div id={`sideBarMainDiv`}>
            <div id={`TopBarDiv`}>
                <TopBar setMobileOpen={toggleSidebar} setSelectedName={setSelectedName}/>
            </div>
            <div
                id={`sidebarOpenDiv`}
                className={`${styles.wrapper} ${barCollapse ? styles.barCollapse : ""}`}

            >
                <SideBar
                    mobileOpen={sidebarOpen}
                    className={styles.sidebar}
                    barCollapse={barCollapse}
                    setBarCollapse={setBarCollapse}
                    selectedName={selectedName}
                />
                <div id={`children`} className={`min-w-full ${customize}`}>{children}</div>
            </div>
        </div>
    );
}

export default Layout;
