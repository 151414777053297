import SettingsIcon from "./assets/SettingsIcon";
import {LuLogOut} from "react-icons/lu";
import Logout from "../../../features/userManagement/auth/logout/Logout";
import React, {useState} from "react";
import HelpCircleIcon from "./assets/HelpCircleIcon";

const SideBarBottomLayer =()=> {
    const [openModal, setOpenModal] = useState(false);
    const handleLogout = () => {
        setOpenModal(true);
    };
    const values = [
        {
            text : 'Settings',
            clickFunction : handleLogout,
            icon :<SettingsIcon id={"hkfjdf"}/>,
            id: 'kjfshdaf'
        },
        {
            text : 'Help & Support',
            clickFunction : handleLogout,
            icon :<HelpCircleIcon id={"jkhfakjhkds"}/>,
            id: 'hkfajhkd'
        },
        {
            text : 'Logout',
            clickFunction : handleLogout,
            icon :<LuLogOut id={`LuLogOut`}/>,
            id: 'dlkjdkjakfoe'
        },

    ]
    return <div id={'sideBarBottomSection'} className={`absolute bottom-8 `}>
        {
            values.map((value, index) => (
                <div id={`${value.id}`} className={`w-44 mt-6`}>
                    <button id={`onLogoutClicksidebar${value.id}`} className={`flex `}
                            onClick={value.clickFunction}>
                        <div id={`${value.id}jfkgjf`} className={` mt-1 `}>{value.icon}</div>
                        <span id={`logoutSpan${value.id}`}
                              className={`text-[#72757A] ml-2 `}>{value.text}</span>
                    </button>
                </div>

            ))
        }
        <Logout showOpenModal={openModal} setShowOpenModal={setOpenModal}/>
    </div>
}

export default SideBarBottomLayer