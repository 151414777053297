import React, {ReactNode, useEffect, useState} from 'react'
import SideBarr from "./SideBarr";
import styles from "./styles/layout.module.css";

import TopBarr from "./TopBarr";
import {useAppSelector} from "../../../redux/hooks/hooks";
interface IProps {
    children?: ReactNode;
    page?: any;
}


function LayOut2 ({children, page}: IProps) {
    const [barCollapse, setBarCollapse] = useState(false);
    const [selectedName, setSelectedName] = useState<string | null>(null);
    const selected = useAppSelector(state => state.loan.selectedTab);



    const toggleSidebar = () => {
        setBarCollapse(false);
    };


    useEffect(() => {
        setSelectedName(page);
    }, [page]);


    return(
         <div id={'layout'} className={`flex h-screen  `}
         >
             <SideBarr selectedName={selectedName} barCollapse={false}/>
             <div id={'TopNavComponent  '} className={`flex flex-col gap-3 flex-grow `}>
                 <TopBarr selectedName={selected}/>
                 <div id={`children`} className={` fixed  bg-[#f0f2f4] h-[92%] md:h-[90%] w-full md:w-[84%] bottom-0 right-0 `}>
                     {children}
                 </div>
             </div>
         </div>
    )
}


export default LayOut2;