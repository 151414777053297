import {LuBook, LuBriefcase, LuLogOut, LuPanelTop} from "react-icons/lu";
import React, {useState} from "react";
import {buttonNames, names, pathnames, roles} from "../../../utils/roles";
import {useLocation, useNavigate} from "react-router-dom";
import {assignPathNameForCohort, assignPathNameForPrograms, handleTitle, assignPathNameForInvestor} from "../../../utils";
import {useAppSelector} from "../../../redux/hooks/hooks";
import {cohortButtonName, instituteButtonName, loanButtonName, programsCohortButtonName} from "./buttonNames";
import {FiDatabase} from "react-icons/fi";
import {GoPerson} from "react-icons/go";
import CodeSandBox from "../../../features/dashboard/assets/images/CodeSandBox";
import {store} from "../../../redux/store";
import {loanActions} from "../../../redux/slices/loanSlice";
import SettingsIcon from "./assets/SettingsIcon";
import HelpCircleIcon from "./assets/HelpCircleIcon";
import Logout from "../../../features/userManagement/auth/logout/Logout";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

interface IProps {
    selectedName?: string | null;
    barCollapse?: boolean;
}
interface IButtonProps {
    title: string;
    icon: string|React.ReactElement|undefined;
    id: string;
    to: string;
    buttonName: string;
    role: roles[];
    pageName?: undefined;
}
interface IActiveButtonProps {
    title: string;
    to: string;
}

const SideNavButton = ({selectedName, barCollapse}: IProps): any => {
    const [activeButton, setActiveButton] = useState(handleTitle);
    const selected = useAppSelector(state => state.loan.selectedTab)


    const navigate = useNavigate();

    const handleSideButtonClick = (title: string, to: string, buttonName: string) => {
        if(!to) {
            store.dispatch(loanActions.setSelectedTab(title))
            setActiveButton(title);
        }else if(buttonName || to){
            store.dispatch(loanActions.setSelectedTab(title))
            setActiveButton(title);
            navigate(to);

            if (buttonName) {
                cohortButtonName(buttonName);
                // InstituteButtonName(buttonName);
                instituteButtonName(buttonName);
                // cohortButtonName(buttonName);
                loanButtonName(buttonName);
                programsCohortButtonName(buttonName);
            }
        }
    };


    const programName = useAppSelector(state => state.program.programName);
    const instituteName = useAppSelector(state => state.institute.instituteName);



    const isActiveButton = ({title, to}:IActiveButtonProps )=>{
        return activeButton === title || window.location.pathname.startsWith(to)
    }
    const isActive = (title: string) => {
        return activeButton === title;
    }

    const buttonData:IButtonProps[] = [
        {
            title: names.LOANS,
            icon: <LuPanelTop/>,
            id: "loanhfkjd",
            to: "/loan",
            buttonName: names.LOANS,
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN],
        },
        {
            title: names.LOANPRODUCT,
            id: "LoanProductaekjhws",
            icon: <CodeSandBox color={selected === "Loan Product" ? "#0D9B48" : "#72757A"}/>,
            to: "/view/all-loan-product",
            buttonName: buttonNames.LOAN_PRODUCT,
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN]
        },
        {
            title: names.INSTITUTES,
            icon: <LuBook/>,
            id: "institutenajdn",
            to: "/institute",
            buttonName: names.INSTITUTES,
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN],
        },
        {
            title: names.TRAINEES,
            icon: <GoPerson/>,
            id: "traineesakjdhfjk",
            to: "/trainees",
            buttonName: names.TRAINEES,
            role: [roles.PORTFOLIO_MANAGER, roles.INSTITUTE_ADMIN, roles.SUPER_ADMIN],
        },
        {
            title: names.FUNDS,
            icon: <FiDatabase/>,
            id: "fundsaohjhr",
            to: "/fund",
            buttonName: names.FUNDS,
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN]
        },
        {
            title: names.INVESTORS,
            icon: <LuBriefcase/>,
            id: "investorsthggd",
            to: "/investor",
            buttonName: names.INVESTORS,
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN]
        },
    ]
    const [openModal, setOpenModal] = useState(false);
    const handleLogout = () => {
        setOpenModal(true);
    };
    const mobileData = [
        {
            text : 'Settings',
            clickFunction : handleLogout,
            icon :<SettingsOutlinedIcon id={'settingsIcon'}/>,
            id: 'kjfshdaf'
        },
        {
            text : 'Help & Support',
            clickFunction : handleLogout,
            icon :<HelpOutlineOutlinedIcon id={'helpIcon'}/>,
            id: 'hkfajhkd'
        },
        {
            text : 'Logout',
            clickFunction : handleLogout,
            icon :<LuLogOut id={`LuLogOut`}/>,
            id: 'dlkjdkjakfoe'
        },

    ]


    return (
            <div id={'mainDiv'} className="mx-[8px] ">
                {buttonData.map((button, index) => (
                    <button
                        id={`${button.id}barCollapsebutton `}
                        key={button.id}
                        className={
                            `flex items-center space-x-2 w-full py-2 pl-2 my-1  rounded-md  mb-[1rem]
                ${barCollapse ? "justify-center text-2xl" : ""}`
                        }
                        onClick={() => handleSideButtonClick(button.title, button.to, button.buttonName)}
                        style={{
                            backgroundColor: selected === button.title ? "#E7F7ED" : "",
                            color: selected === button.title ? "#0D9B48" : "#72757A",
                            fontWeight: selected === button.title ? "medium" : "medium",
                        }}
                    >
                        {button.icon}
                        {!barCollapse && <span id={`${button.id}span`} className={`text-left`}>{button.title}</span>}
                    </button>
                ))}
                <div className={` md:hidden  `}>
                    {mobileData.map((button, index) => (
                        <button
                            id={`${button.id}barCollapsebutton `}
                            key={button.id}
                            className={
                                `flex items-center space-x-2 w-full py-2 my-1 pl-2 rounded-md mb-[1rem]
                ${barCollapse ? "justify-center text-2xl" : ""}`
                            }
                            onClick={() => handleSideButtonClick(button.text, '', '')}
                            style={{
                                backgroundColor: selected === button.text ? "#E7F7ED" : "",
                                color: selected === button.text ? "#0D9B48" : "#72757A",
                                fontWeight: selected === button.text ? "medium" : "medium",
                            }}
                        >
                            {button.icon}
                            {!barCollapse && <span id={`${button.id}span`} className={`text-left`}>{button.text}</span>}
                        </button>
                    ))}
                </div>
                <Logout showOpenModal={openModal} setShowOpenModal={setOpenModal}/>
            </div>
    );
};

export default SideNavButton;