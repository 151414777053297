import React from 'react';
import EmptyState from "./EmptyState";

const Repayment = () => {
    return (<main id={'repaymentMainContainer'}
                  className={'h-full w-full grid gap-12 bg-learnSpaceWhite rounded-[8px] px-5 pt-10'}>
            <section id={'repaymentBalanceSection'}
                     className={'flex w-full h-[7.3125rem] py-6 px-5 justify-between items-center rounded border border-b-learnSpaceGray'}>
                <div id={'repaymentBalanceDetails'} className={'flex flex-col items-start gap-3.5'}>
                    <h1 id={'repaymentBalanceTitle'}
                        className={'text-[14px] font-normal leading-[120%] text-learnSpaceDarkGray'}>Repayment
                        balance</h1>
                    <h1 id={'repaymentBalanceAmount'}
                        className={'text-[32px] font-medium leading-[120%] text-customColor'}>₦100,000,000,000.00</h1>
                </div>
                <button id={'withdrawButton'}
                        className={'flex justify-center items-center w-[6.4375rem] bg-primary h-[2.5625rem] rounded'}>
                    <p id={'withdrawButtonText'}
                       className={'text-[14px] font-medium leading-[120%] text-learnSpaceWhite'}>Withdraw</p>
                </button>
            </section>
            <EmptyState/>
        </main>);
};

export default Repayment;