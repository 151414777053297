import React from 'react';

interface HomeIconProps {
    colour: string;
}

const HomeIcon = ({colour}: HomeIconProps) => {
    return (
        <svg id={'HomeIcon'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 7.50008L10 1.66675L17.5 7.50008V16.6667C17.5 17.1088 17.3244 17.5327 17.0118 17.8453C16.6993 18.1578 16.2754 18.3334 15.8333 18.3334H4.16667C3.72464 18.3334 3.30072 18.1578 2.98816 17.8453C2.67559 17.5327 2.5 17.1088 2.5 16.6667V7.50008Z"
                stroke={colour} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 18.3333V10H12.5V18.3333" stroke={colour} stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>

    )
}

export default HomeIcon;