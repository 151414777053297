import React from 'react'
import styles from "./loanRequest.module.css";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {store} from "../../../../redux/store";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";




function LoanDetails () {



    const handleBack = () => {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Trainee additional details'))

    }


    return(
        <div className={`rounded-md bg-[#fafbfc]  h-[85%] w-[100%]`}>
            <div className={` w-[95%] grid content-end h-[2.5rem]  ml-[1rem] `}>
                <div className={` mt-auto mb-auto ${styles.loanDetail}`}> Loan details</div>
            </div>
            <div className={`w-[95%] h-[65%] mt-[1rem] mr-auto ml-auto bg-[#FFFFFF] rounded-md`}>
                <div className={`w-[97%] h-[97%]  mr-auto ml-auto `}>
                    <div className={`flex place-content-between mb-[1rem] `}>
                        <div className={`flex gap-3 mt-[10px]`}>
                            <Avatar src={`https://i.pinimg.com/474x/d8/7e/f8/d87ef8cc1f1fb646bb2f7c8fc5b763c0.jpg`}
                                    sx={{width: 45, height: 45, backgoundColor: 'red'}}/>
                            <div className={`grid gap-2`}>
                                <span className={`${styles.traineeName}`}>Vanessa Chiamaka Oluchukwa</span>
                                <div className={`flex gap-2  `}>
                                    <Avatar
                                        src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxGM9vEmIwhmh_n8-8yP4ACNtma2a44rqycA&s`}
                                        sx={{width: 24, height: 24}}/>
                                    <span className={` mt-auto mb-auto ${styles.school}`}>School of Product, Yaba</span>
                                </div>
                            </div>
                        </div>
                        <div className={` mt-[10px] ${styles.creditScore} flex `}>
                            <span className={`${styles.creditScoreText}`}>Create score</span>
                            <div className={`${styles.score}`}>
                                <div className={` pr-1 pl-1 ${styles.scoreText}`}>123</div>
                            </div>
                        </div>
                    </div>
                    <div className={` `}>
                        <div className={`grid gap-5 mb-[1rem]`}>
                            <div className={`flex place-content-between`}>
                                <span className={`${styles.nameOf}`}>Loan amount requested</span>
                                <span className={`${styles.value}`}>Michael Ogbechi</span>
                            </div>
                            <Divider orientation={`horizontal`}/>
                        </div>
                        <div className={`grid gap-5 mb-[1rem]`}>
                            <div className={`flex place-content-between`}>
                                <span className={`${styles.nameOf}`}>Tuition amount</span>
                                <span className={`${styles.value}`}>Michael Ogbechi</span>
                            </div>
                            <Divider orientation={`horizontal`}/>
                        </div>
                        <div className={`grid gap-5 mb-[1rem]`}>
                            <div className={`flex place-content-between`}>
                                <span className={`${styles.nameOf}`}>Initial deposit</span>
                                <span className={`${styles.value}`}>Michael Ogbechi</span>
                            </div>
                        </div>
                        <div className={`w-[fit-content] mt-[2rem]`}>
                            <div className={`${styles.tuition}`}>
                                view tuition breakdown
                                <hr className={`${styles.line}`}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={` flex gap-2 place-content-end mt-[2rem]`}>
                    <Button
                        onClick={handleBack}
                        id="backToLoanRequestTraineeAdditionalDetails"
                        variant='outlined'
                        sx={{
                            boxShadow: 0,
                            width: '3rem',
                            textTransform: 'none',
                            '&:hover': {borderColor: '#0D9B48'},
                            color: '#0D9B48',
                            backgroundColor: 'white',
                            borderColor: '#0D9B48'
                        }}>
                        Back</Button>
                    <Button
                            id={'continue'}
                            variant={'contained'}
                            sx={{textTransform: 'none', backgroundColor: '#0D9B48', color: 'white', borderColor: '#0D9B48',boxShadow:0 , "&:hover": {boxShadow:0 ,backgroundColor: '#0D9B48', border: '#0D9B48'}}}
                    >
                        Make decision
                    </Button>

                </div>
            </div>
        </div>
    )


}


export default LoanDetails