import React from 'react';

interface IProps {
   color : string ,
}
const CodeSandBox = ({color}: IProps) => {
    return (
        <div className={''}>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.25 13.3334V6.66675C19.2497 6.37448 19.1648 6.08742 19.0039 5.83438C18.843 5.58134 18.6118 5.37122 18.3333 5.22508L11.9167 1.89175C11.638 1.74547 11.3218 1.66846 11 1.66846C10.6782 1.66846 10.362 1.74547 10.0833 1.89175L3.66667 5.22508C3.38824 5.37122 3.15698 5.58134 2.99609 5.83438C2.8352 6.08742 2.75033 6.37448 2.75 6.66675V13.3334C2.75033 13.6257 2.8352 13.9127 2.99609 14.1658C3.15698 14.4188 3.38824 14.6289 3.66667 14.7751L10.0833 18.1084C10.362 18.2547 10.6782 18.3317 11 18.3317C11.3218 18.3317 11.638 18.2547 11.9167 18.1084L18.3333 14.7751C18.6118 14.6289 18.843 14.4188 19.0039 14.1658C19.1648 13.9127 19.2497 13.6257 19.25 13.3334Z"
                    stroke={color} stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.25 10L15.125 12.1667V16.4917" stroke={color} stroke-width="1" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M6.875 16.4917V12.1667L2.75 10" stroke={color} stroke-width="1" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M2.9975 5.80005L11 10.0084L19.0025 5.80005" stroke={color} stroke-width="1"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11 18.4V10" stroke={color} stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.875 3.5083L11 5.67497L15.125 3.5083" stroke={color} stroke-width="1"
                      stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    );
};

export default CodeSandBox;