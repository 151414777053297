import React from 'react';

interface XIconProps {
    onClick?: () => void
}

const XIcon = ({onClick}: XIconProps) => {
    return (<svg onClick={onClick} id={'XIcon'} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5.5L5 15.5" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 5.5L15 15.5" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default XIcon;