import React from "react"
import styles from "../../../dashboard/loan/loanRequest/loanRequest.module.css"
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import DetailsFields from "../../../../components/reusables/fields/DetailsFields";
import {store} from "../../../../redux/store";
import {pageAction} from "../../../../redux/slices/PageToDIsplaySlice";


function TraineeAdditionalDetails (){

    const [openModal, setOpenModal] = React.useState(false)


    function openDropDown() {
        setOpenModal(true)
    }
    const continues = () => {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Loan details'))
    }
    const back = () => {
        store.dispatch(pageAction.loanRequestDetailSideBarButton('Trainee details'))
    }

    const details = [
        {
            id: '1',
            field: 'Alternative email address',
            value: 'vanessa@gmail.com'
        },
        {
            id: '2',
            field: 'Alternative phone number',
            value: '+2349039402842'
        },{
            id: '3',
            field: 'Alternative residential address',
            value: '372, Herbert Macaulay Way, Alagomeji, Sabo, Yaba'
        },{
            id: '4',
            field: 'Next of ken name',
            value: 'Michael oluchukwu'
        },{
            id: '5',
            field: 'Next of ken email address',
            value: 'michael@gmail.com'
        },{
            id: '6',
            field: 'Next of ken phone number',
            value: '+2347047382919'
        },{
            id: '7',
            field: 'Next of ken relationship',
            value: 'Brother'
        },

    ]

    return (
        <div id={`Loan`} className={' rounded-md bg-[#FAFBFC] w-[53vw] h-[100%]'}>
            <div className={`w-[96%] pt-[1rem] mb-[1rem] mr-auto ml-auto ${styles.loanDetail} `}>Trainee aditional details</div>
            <div className={` w-[99%] h-[85%]  mr-auto ml-auto ${styles.traineeDetails} `}>
                <div className={` w-[96%] h-[auto] pt-2 grid mr-auto ml-auto gap-3 pr-2 pl-2 bg-white rounded-md    `}>
                    <div className={`flex place-content-between mb-[1rem] mt-[0.5rem]`}>
                        <div className={`flex gap-3 mt-[10px]`}>
                            <Avatar src={`https://i.pinimg.com/474x/d8/7e/f8/d87ef8cc1f1fb646bb2f7c8fc5b763c0.jpg`}
                                    sx={{width: 45, height: 45, backgoundColor: 'red'}}/>
                            <div className={`grid gap-2`}>
                                <span className={`${styles.traineeName}`}>Vanessa Chiamaka Oluchukwa</span>
                                <div className={`flex gap-2  `}>
                                    <Avatar
                                        src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxGM9vEmIwhmh_n8-8yP4ACNtma2a44rqycA&s`}
                                        sx={{width: 24, height: 24}}/>
                                    <span className={` mt-auto mb-auto ${styles.school}`}>School of Product, Yaba</span>
                                </div>
                            </div>
                        </div>
                        <div className={` mt-[10px] ${styles.creditScore} flex `}>
                            <span className={`${styles.creditScoreText}`}>Credit score</span>
                            <div className={`${styles.score}`}>
                                <div className={` pr-1 pl-1 pt-1 pb-1 ${styles.scoreText}`}>123</div>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-[1rem]`}>
                        {
                            details?.map((detail) => (
                                <DetailsFields field={detail.field} value={detail.value} marginBottom={'4rem'}
                                               showTopDivider={false} showBottomDivider={true}/>
                            ))
                        }
                    </div>
                </div>
                <div className={`flex gap-3 place-content-end pr-4 pb-2  mt-[1rem]  `}>
                    <Button
                        onClick={back}
                        id={"continueToTraineeAdditionalDetailsFromTraineeDetails"}
                        variant={'outlined'}
                        sx={{
                            boxShadow: 0,
                            width: '6rem',
                            textTransform: 'none',
                            '&:hover': {borderColor: '#0D9B48',color: '#0D9B48',
                                backgroundColor: 'white', },
                            color: '#0D9B48',
                            backgroundColor: 'white',
                            borderColor: '#0D9B48'
                        }}>
                        Back</Button>
                    <Button
                        onClick={continues}
                        id={"continueToLoanDetailsFromTraineeAdditionalDetails"}
                        variant={'contained'}
                        sx={{
                            color: 'white',
                            boxShadow: 0,
                            backgroundColor: '#0D9B48',
                            "&:hover": {backgroundColor: '#0D9B48', boxShadow: 0},
                            textTransform: 'none'
                        }}
                    >Continue</Button>
                </div>
            </div>
        </div>

    )

}


export default TraineeAdditionalDetails